// Variable settings
$font-primary: InvescoInterstateLight, Helvetica, sans-serif;
$font-primary-bold: InvescoInterstateBold, Helvetica, sans-serif;
$font-secondary: "Swift W01 Light", Helvetica, sans-serif;
$font-secondary-medium: "Swift W01 Medium", Helvetica, sans-serif;
$font-secondary-bold: "Swift W01 Bold", Helvetica, sans-serif;
$font-secondary-heavy: "Swift W01 Heavy", Helvetica, sans-serif;
$font-icons: icomoon;

// Global colours
$color-primary: #000;
$color-link: #0014D2;
$color-01: #ebebeb;
$color-02: #cccccc;
$color-03: #7c7c7c;
$color-04: #333333;
$color-05: #f7f7f7;
$color-white: #ffffff;
$color-link-blue: #000AD2;
$color-navy-blue: #000078;
$color-midnight-blue: #0a1331;

// Pantone colours
$color-021: #fa4526;
$color-108: #f9dd16;
$color-226: #f50091;
$color-253: #af23a5;
$color-266: #6423a0;
$color-299: #009bfa;
$color-300: #1450d2;
$color-327: #008c82;
$color-354: #00b760;
$color-376: #8cc800;
$color-485: #d81e05;
$color-871: #b2a175;
$color-2617: #560c70;
$color-2748: #001a7a;
$color-3262: #00c1b5;
$color-light-gray: #f6f6f6;

$font-size: 15px;
$line-height: 20px;

$dialog-width: 300px;

// Responsive breakpoints
$media-min-width-mobile: 560px;
$media-max-width-mobile: 719px;
$media-min-width-tablet: 720px;
$media-max-width-tablet: 979px;
$media-min-width-desktop: 980px;

$media-xs-min: 567px;
$media-sm-min: $media-xs-min + 1;
$media-sm-max: 767px;
$media-md-min: $media-sm-max + 1;
$media-md-max: 1024px;
$media-lg-min: $media-md-max + 1;
$media-lg-max: 1280px;

$media-aem-tablet-max: 1247px;
$media-aem-desktop-min: $media-aem-tablet-max + 1;

//Grid rows base spacing. Larger spacing is calculated on this value.
$column-width: 80px;
$row-spacing: 20px;

// Salesforce LiveAgent
$la-popup-border-radius: .5em;
$la-popup-avatar-width: 100px;
$la-popup-avatar-padding: 3px;
$la-popup-avatar-radius: 50%;

// AEM
$light-cyan: #cffaff;
$gray-90: #e5e5e5;

// Logo
$twitter-default: "../../joint-state/images/twitter-x-default.svg";
$twitter-hover: "../../joint-state/images/twitter-x-hover.svg";
