body.grid {
	.container-grid {
		background-color: #f9f9f9;
        padding: 10px 0;
	}
    .row-grid { 
		margin-bottom: 20px;
	}
    .row-grid .columns {
        background-color: #eee;
        padding: 10px 0;
        text-align: center;
    }
	.row-grid .columns .row .columns {
		background-color: #ccc;
	}
}
