.prices-page #expandallfirst, .prices-page #expandalllast
{
	float: right;
    color: #0014D2;
    cursor: pointer;
    padding-bottom: 20px;
}

.prices-page 
{
	.detailLink
	{
		float:right;
		margin-bottom:10px;
	}
	
	.data-hide
	{
	display:none;
	}
}