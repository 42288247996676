* {
    font-family: 'graphik-regular', Arial, sans-serif;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
}

#wrapper-main {
    padding-top: 40px;

    .panel-section {
        .panel-heading {
            @include font-graphik-h3;
        }
 
        h4 {
            @include font-graphik-h4;
        }
    }
}

.js-no-cookie {
    #wrapper-main {
        @media screen and (max-width: $media-aem-tablet-max) {
            padding-top: 0 !important;
        }
    }
}

.wrapper-cover {
    @media screen and (max-width: $media-aem-tablet-max) {
        margin-top: 0;
    }
}

header {
    @media screen and (max-width: $media-aem-tablet-max) {
        margin-top: 0;
    }
    
    h1 {
        @include editor-h1;
    }

    p {
        @include font-graphik-regular;
    }
}

table {
    th,
    td,
    a {
        @include font-graphik-regular;
    }

    caption {
        font-family: 'graphik-regular', Arial, sans-serif;
    }
}

.container {
    h2 {
        @include editor-h2;
    }

    h3 {
        @include font-graphik-h3;
    }

    h4 {
        @include font-graphik-h4;
    }

    .forms .textboxanddropdown,
    .forms .forms-textbox input,
    .forms-textbox .forms input,
    .forms .forms-dropdown select,
    .forms-dropdown .forms select,
    .forms-dropdown label,
    .forms-dropdown select option,
    .searchbox input,
    p {
        @include font-graphik-regular;
    }

    strong,
    b {
        @include font-graphik-medium;
    }

    .nav-space {
        @media only screen and (max-width: $media-min-width-desktop) {
            margin-bottom: 100px;
        }
    }

    .navsub {
        h3 {
            font-size: 15px;
        }
    }
}

.accordion { 
    .accordion-content,
    .author {
        h3 {
            @include font-graphik-regular(16px, 500);
            line-height: 20px;
        }

        h4 {
            @include font-graphik-h4;
        }

        p {
            @include font-graphik-regular;
        }

        strong,
        b {
            @include font-graphik-medium;
        }
    }
    
    & > h2 {
        @include editor-h2;
    }

    .accordion-icon,
    .accordion-heading::after,
    & > h2::after,
    & > h3::after,
    & > h4::after,
    .accordion-expanded > h2::after,
    .accordion-expanded > h3::after {
        border-radius: 100%;
        font-size: 0.75em;
    }

    & > h6::after,
    .accordion-expanded > h6::after {
        font-size: 1em;
    }
}

.article-meta {
    div {
        @include font-graphik-regular;
    }
}

.filters {
    h3,
    ul,
    li {
        @include font-graphik-regular;
    }
}

.nav-button span {
    @include font-graphik-regular(12px);
    line-height: 18px;
}

table.fundshub td:first-child a,
.fundshub table td:first-child a,
table th {
    @include font-graphik-medium;
}

.panel-warning-jumplink,
.panel-warning-small {
    p {
        @include font-graphik-regular;
    }
}

.button,
.button-primary,
.button-primary-inactive,
.button-secondary,
.button-secondary-inactive,
.button-black,
.button-black-inactive,
.button-icon,
.load-more-button {    
    @include font-graphik-medium;
}

.stickynav {
    z-index: 11;
}

.row {
    p {
        @include font-graphik-regular;
    }

    strong,
    b {
        @include font-graphik-medium;
    }
}

.profile-copy {
    a {
        @include font-graphik-medium(15px);
    }
}

.quote .credit .name {
    @include font-graphik-medium(12px);
}

.wrapper-footer {
    position: relative;
    z-index: 12;
}

.prices-nav-sub h3 {
    @include font-graphik-medium;
}

.fund-detail-page {
    .accordion-content {
        #fundslibrary-root {
            header {
                @include font-graphik-medium(20px);
            }

            h3 {
                @include font-graphik-h3;
                font-weight: 400;
            }

            input[type=text],
            label {
                @include font-graphik-regular;
            }
        }
    }
}

.historic-prices-page {
    .forms-dropdown {
        select {
            &.inline {
                @media screen and (max-width: $media-max-width-mobile) {
                    width: 30%;
                }
            }
        }
    }
}

.special-table-header {
    a {
        font-size: 20px;
    }
}

.fund-detail-page,
.trust-detail-page {
    .header-product {
        h1,
        p {
            margin-bottom: 15px;
    
            @media only screen and (min-width: $media-min-width-desktop) {
                width: 100%;
            }
        }
    
        .brand {
            margin-bottom: 8px;
        }
    
        .nav-buttons {
            @media only screen and (min-width: $media-min-width-desktop) {
                text-align: right;
                float: none;
            }
        }
    }

    .accordion-content {
        padding-top: 10px;
    }
}

.stickynav {
    &.sticky-default {
        position: static;

        @media only screen and (max-width: $media-min-width-desktop) {
            position: fixed;
        }
    }

    &.sticky-fixed {
        position: fixed;
    }
    
    ul {
        li {
            a {
                @include font-graphik-regular;
    
                &.active {
                    @include font-graphik-medium;
                }
            }
        }
    }
}

.navigation-mega {
    .navigation-mega-grid {
        padding: 32px 0;

        .navsub {
            ul {
                &>li {
                    margin-bottom: 16px;
                    
                    @media only screen and (max-width: $media-min-width-desktop) {
                        margin-bottom: 24px;
                    }

                    @media screen and (max-width: $media-max-width-mobile) {
                        margin-bottom: 16px;
                    }
                }
            }

            h3 {
                @include font-graphik-medium;
                min-height: auto;
                padding-bottom: 16px;
                    
                @media only screen and (max-width: $media-min-width-desktop) {
                    padding-bottom: 24px;
                }

                @media screen and (max-width: $media-max-width-mobile) {
                    padding-bottom: 16px;
                }
            }
        }
    }
}