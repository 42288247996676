.lockBody {
	overflow:hidden;

	&.editMode {
		overflow: auto;
	}
}

.iframe-modal {
    display:none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 1000; // ensures modal is above sticky sidebar
    overflow: auto;

    @media only screen and (max-width: $media-min-width-tablet){
        overflow-y: scroll;
        overflow-x: hidden;
    }

    &.editMode {
        z-index: 100000; //Ensure modal displays over mgl editbars
    }
}

.iframe-modal-close {
    position: absolute;
    right: 5px;
    top: 10px;
    font-size: 26px;
    margin-bottom: 20px;
    color: $color-primary;
    width: 50px;
    height: 50px;
    font-size: 40px;
    cursor: pointer;
}

.iframe-modal-close-icon {
    display: block;
    margin: 0 auto;
}

.iframe-modal-Form {
    transition: opacity .6s;
    transition: height .5s;
    margin-top: 16px;
    
    @media only screen and (max-width: $media-min-width-tablet){
       width: 92%
    }
}


.form-hidden-element {
    opacity: 0;
    visibility: hidden;
}

.iframe-form-wrap {
    position: relative;
    max-width: 800px;
    margin-top: 7vh;
    margin-left: 50%;
    transform: translateX(-50%);
    background-color: $color-white;
    padding: 40px 20px 20px;

    @media only screen and (max-width: $media-min-width-tablet){
        width: 80vw;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.form-loading-gif {
    width: 100%;
    transition: opacity .6s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    visibility: visiable;
}