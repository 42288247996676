.hide-mobile {
    display: block;
    @media only screen and (max-width: $media-min-width-mobile){
        display: none;
    }
}

.hide-desktop-down {
    @media only screen and (max-width: $media-min-width-desktop){
        display: none;
    }
}

.hide-desktop {
    display: block;
    @media only screen and (min-width: $media-min-width-mobile){
        display: none;
    }
}