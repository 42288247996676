.filters-panel-open
{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.filters-button-hidden.filters-button.nav-button, .filtered-data-hide, 	.filters + .filters
{
	display:none;
}

.filters
{
	.filter-selected::before, &-collapsed::after, &-expanded::after, &-bookmark h3::before
	{
		font-family:$font-icons;
	}
	.filter-selected::before
	{
		@include icon-tick-before;
	}

	&-collapsed::after
	{
		@include icon-down-before;
	}

	&-expanded::after
	{
		@include icon-up-before;
	}

	&-bookmark h3::before
	{
		@include icon-bookmark-before;
	}
	li
	{
		list-style-image:none;
		list-style-type:none;
		&:before
		{
			content:"";
		}
	}
	h3, li
	{
		cursor:pointer;
		letter-spacing:0;
	}
	&-collapsed + ul
	{
		display:none;
	}
}

@media only screen and (max-width: $media-min-width-desktop) {
	.filters
	{
		input[type="checkbox"]
		{
			display:none;
		}
		width:320px;
		right:-320px;
		height:auto;
		min-height:100%;
		height:100%;
		overflow:auto;
		z-index:1000;
		top:0px;
		background-color:$color-04;
		position:fixed;
		@include slideinslideout;

		&-open
		{
			right:0px;
		}
		&-close
		{
			position:absolute;
			top:10px;
			right:10px;
			z-index:1;
		}
		&-button
		{
			margin-top:10px;
			margin-left:10px;
			float:right;
		}

		&-collapsed::after, &-expanded::after
		{
			color:$color-01;
			position:absolute;
			right:10px;
			top:5px;
			font-size:25px;
			font-weight:normal;
		}

		&-bookmark
		{
			h3
			{
				font-size:25px;
			}
		}

		&-share-class-clear-select-all
		{
			margin-top:40px;
			text-align:center;
			a
			{
				color:$color-01;
			}
			.filters-share-class-clear-all, .filters-share-class-select-all
			{

			}
		}

		&-group
		{
			position:relative;
			&:not(.filters-group:first-child)
			{
				padding:10px 0px 10px 0px;
			}
			.filters-clear-all,
			{
				text-align: center;
			    background-color: #f1f1f1;
			    border-radius: 10px;
			    display: block;
			    padding-bottom:5px;
			    margin: 115px 10px 20px;
				a
				{
					color:$color-04;
					display:inline-block;
					width:100%;
					height:100%;
					&:hover
					{
						text-decoration:none;
					}
				}
			}
			~ .filters-group h3.filters-clear-all
			{
				display:none;
			}
			ul
			{
				font-size: 15px;
				color: $color-03;
				margin:0px;
				overflow:auto;
				padding : 0px;
				li
				{
					height : 30px;
					line-height : 30px;
					margin:10px 0px 20px 0px;
					position:relative;
					a
					{
						color: $color-03;
						display:block;
						line-height:20px;
						padding-left:50px;
						padding-top:5px;
						&:hover
						{
							text-decoration:none;
						}
						&:before
						{
						   border: 1px solid #a6a6a6;
						   border-radius: 15px;
						   content: "";
						   display: block;
						   float: left;
						   height: 28px;
						   margin-left: 10px;
						   width: 28px;
						   position:absolute;
						   left:0px;
						   top:0px;
						}
					}
				}
			}
		}
		h3
		{
			color:$color-01;
			font-size:15px;
			font-weight:bold;
			padding-left:10px;
			border-top:1px solid $color-01;
			line-height:30px;
			padding-bottom:10px;
			margin-bottom:0px;
			font-family:InvescoInterstateLight,Helvetica,sans-serif;
		}
		.filter-selected::before
		{
			background-color:$color-01;
			text-align:center;
			line-height:23px;
			color:$color-01;
		}
	}
}
@media only screen and (min-width: $media-min-width-desktop)
{
	.filters
	{
		&-button.icon-filter.nav-button, &-close.icon-right.nav-button
		{
			display:none;
		}
		&-bookmark h3::before
		{
			color:$color-link;
		}
		&-close.nav-button
		{
			display:none;
		}
		&-group
		{
			.filters-clear-all a
			{
				color:$color-link;
			}
			ul
			{
				padding:10px 0;
				margin:0;
		    	position:relative;
		    	overflow:hidden;
				&:first-child
				{
					border-top:1px solid black;
				}
				li
				{
					margin:0px;
					padding-top:5px;
					padding-bottom:5px;
					width:100%;
					position:relative;
					white-space:nowrap;
					a
					{
						color: $color-04;
						display:block;
						padding-left:30px;
						&:before
						{
							content:"";
							display:inline-block;
							width:15px;
							height:15px;
							margin-right:10px;
							border:1px solid #a6a6a6;
							border-radius:0px;
							margin-right:15px;
							margin-bottom:10px;
							position:absolute;
							top:7px;
							left:0px;
						}
						&:hover:before
						{
							border-color:$color-link;
						}
					}
					&:last-child
					{
						padding-bottom:20px;
					}
				}

			}
			&.filters-single-select {
				ul li a {
					padding-left: 0;

					&:before {
						display: none;
					}
				}
			}
			&-hover.filters-group-gradient
			{
				ul
				{
			    	&:after
			    	{
			    		width:0px;
			    	}
			    }
			}
			&-gradient
			{
				ul
				{
			    	&:after
			    	{
						content: "";
					    position: absolute;
					    width: 70px;
					    height: 100%;
					    top: 0px;
					    right: 0px;
					    background-image: -webkit-linear-gradient(right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
					    background-image: -moz-linear-gradient(right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
					    background-image: -ms-linear-gradient(right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
					    background-image: -o-linear-gradient(right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
					    background-image: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
			    	}
				}
			}
			&-hover
	    	{
	    		margin-bottom:-1px;
	    		z-index:1000;
	    		position:relative;
	    		background-color:white;
	    		h3
	    		{
	    			border-bottom:1px solid black;
	    		}
	    		ul
	    		{
		    		border-bottom:1px solid black;

		    		padding-top:10px;
		    		ul:first-child
		    		{
		    			border-bottom:1px solid black;
		    		}
		    		li
		    		{
		    			white-space:nowrap;
		    		}
		    		&:after
		    		{
		    			display:none;
		    		}
	    		}
	    	}
		}
		&-collapsed::after
		{
			color: $color-link;
		}
		&-expanded::after
		{
			color: $color-04;
		}
		&-expanded
		{
			border-bottom:1px solid black;
		}
		&-collapsed::after, &-expanded::after
		{
			position:absolute;
			top:10px;
			right:0px;
			font-size:15px;
			font-weight:normal;
		}
		h3
		{
			line-height:20px;
			padding-top: 10px;
			padding-bottom: 10px;
			border-top:1px solid black;
			margin-bottom:0px;
			font-family:InvescoInterstateLight,Helvetica,sans-serif;
			position:relative;
			padding-right:15px;
		}
		ul, h3
		{
			font-size: 15px;
			color: black;
		}
		.filter-selected::before
		{
			color:black;
			background-color:white;
			line-height:15px;
		}

	}
}
