@media print {
	#wrapper-cookie, #wrapper-navtop, .row-content .d2:first-child, .row-header .nav-button, .navmain-mobile-scroll, .navmain, .filters
	{
		display:none!important; 
	}

	.row-navmain
	{
		top:0px !important;
		position:static !important;
	}

	.container {
    	width: 80% !important;
    	margin: 0 auto !important;
	}

	.logo-print
	{
		width:115px !important;
		height:100px !important;
		margin-top:40px !important;
		margin-left:-10px !important;
		display:block !important;
		&.logo-perpetual
		{
			width:100px !important;
		}
	}
	
	.home-page, .home
	{
		.wrapper-cover
		{
			margin-top:50px !important;
			min-height:120px !important;
			padding-top:40px !important;
			padding-bottom:40px !important;
			margin-bottom:0px !important;
			.welcome-text
			{
				display:block !important;
				width:550px;
				margin-left:80px !important;
				*
				{
					color:black !important;
				}
			}
		}

	}
	.row-header header
	{
		margin-top:50px;
	}
	.navmain-mobile-scroll 
	{
		background-color:white !important;
		position:static !important;
		.logo-mobile
		{
			margin-left:10% !important;
			background: transparent url("../images/invesco_stacked_blue.png") no-repeat !important;
			background-size: 115px 100px !important;
		}	
	}
}