.team-group {
	margin-top:40px;
}

.person-group {
	overflow:hidden;
	margin-bottom: 20px;
	&-letter {
		border-top: 2px solid $color-primary;
		display: block;
		position: relative;
		font-size: 30px;
		clear: both;
	    line-height:40px;
	    font-family:InvescoInterstateBold;
	    margin-bottom: 20px;
	}
}