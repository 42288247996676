.author
{
    width: 240px;
    margin-left:10px;
    color:black;
    margin-bottom:20px;
    img
    {
        max-width:68px;
        height:68px;
        border-radius: 100%;
        float:left;
        max-width: 36%;
    }
    p
    {
        margin:0px;

    }
    &-detail
    {
        float:left;
        margin-left:20px;
        margin-top:5px;
        max-width:62%;
        a
        {
            border-top:1px dotted $color-03;
            margin-top:5px;
            padding-top:5px;
            padding-bottom:5px;
            display:block;
        }
    }
    &:after
    {
        content:"";
        display:table;
        clear:both;
    }
}
