.panel {
    padding: 10px;
    margin-bottom: 20px;
    position: relative;
    clear:both;
    &-info {
        background-color: $color-01;
        .panel-close {
            float:right;
            margin-left:10px;
            cursor: pointer;
        }
        @media screen and (max-width: $media-min-width-mobile) {
        	.button-primary {
        		display: block;
        		width: auto;
        	}
        }
    }
    &-thin-section,
    &-section {
        border-top: $color-04 2px solid;
        padding-left: 0;
        padding-right: 0;
        clear:both;
        h2
        {
            a
            {
                cursor:pointer;
                color:black;
                &:hover
                {
                    color:$color-link;
                }
            }
        }
    }
    &-thin-section{
       border-top-width: 1px;
    }
    &-numbered-active {
        color: white;
        background-color: $color-link;
        h2, a {
            color: white;
        }
    }
    &-numbered-inactive {
        color: $color-03;
        background-color: $color-01;
        h2, a {
            color: $color-03;
        }
    }
    &-first{
       padding-left: 0;
    }
    &-last{
       padding-right: 0;
    }
    &-warning {
        background-color: $color-01;
            h2:before,
            h3:before {
            @extend .icon-warning2;
            font-family: $font-icons;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1; -webkit-font-smoothing: antialiased;
            margin-right:4px;
        }
        &-small
        {
            @extend .panel-warning-jumplink;
            h2
            {
                display:none;
            }
            p {
                font-family: $font-primary;
                margin-bottom:0px;
                &:before {
                    @extend .icon-warning2;
                    font-family: $font-icons;
                    color: $color-primary;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1; -webkit-font-smoothing: antialiased;
                    margin-right:4px;
                }
            }
        }

        &-jumplink {
            background-color: $color-01;
            padding: 5px 12px 5px 10px;
            margin-top: 20px;
            @include icon-warning-before;

            p {
                width: 100%;
                font-family: $font-primary;
                margin-bottom: 0;

                &:before {
                    @extend .icon-warning2;
                    font-family: $font-icons;
                    color: $color-primary;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1; -webkit-font-smoothing: antialiased;
                    margin-right:4px;
                }
            }
        }
    }
    &-help {
        background-color: $color-01;
            h2:before {
            @extend .icon-help2;
            font-family: $font-icons;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1; -webkit-font-smoothing: antialiased;
            margin-right:4px;
        }
    }
    &-compare {
        display: -ms-flexbox;
        display:flex;
        -ms-flex-wrap: wrap;
        flex-wrap:wrap;
        -ms-flex-pack: justify;
        justify-content:space-between;
        margin-bottom:5px;
        h3 {
            margin-bottom: 0;
        }
        h4
        {
            padding-top:20px;
            margin-bottom:20px;
        }
        &-row
        {
            -ms-flex: 0 0 48%;
            flex-basis:48%;
            *
            {
                color:white;
                padding-left:20px;
                padding-right:20px;
                word-break: break-word;
            }
            &:last-child
            {
                padding-bottom:10px;
            }
        }
    }

    &-type {
        &-image {
            &.trust {
                min-height: 585px;
                margin-bottom: 30px;
                h2 {
                    min-height: 150px
                }

                @media screen and (max-width: $media-max-width-mobile) {
                    min-height: auto;
                    h2 {
                        min-height: auto;
                    }
                }
            }

            @media only screen and (max-width: $media-max-width-tablet) {
                margin-bottom: 40px;
            }

            &-half {
                img {
                    display: inline-block;
                    width: 48%;
                    float: left;
                }
                .header {
                    float: right;
                    display: inline-block;
                    width: 48%;
                }
                p {
                    clear: both;
                }
            }
        }
        &-01 {
            background-color: #ebebeb;
            padding: 10px;
            max-width: 220px;
            margin-bottom: 25px;

            .header {
                font-size: 50px;
            }
            .content {
                margin-bottom: 10px;
                min-height: 40px;
            }
            .unit {
                font-size: 40px;
            }
        }
        &-02 {
            margin-bottom: 25px;
            .header {
                font-size: 55px;
            }
            .unit {
                font-size: 38px;
            }
            .content {
                margin: 6px auto ;
                font-size: 14px;
            }
            .disclaimer {
                color: $color-03;
                font-size: 13px;
                line-height: 14px;
            }
        }
        &-03 {
            margin-bottom: 25px;
            .header {
                font-size: 90px;
            }
            .unit {
                font-size: 45px;
            }
            .content {
                p {
                    margin: 2px auto 12px;
                    font-size: 20px;
                    line-height: 25px;
                }
            }
            .disclaimer {
                color: $color-03;
                font-size: 13px;
                line-height: 14px;
            }
        }
        &-04 {
            margin-bottom: 25px;
            position: relative;

            .header {
                font-size: 90px;
            }

            .content {
                font-size: 20px;
            }

            &.slanted {
                .header {
                    margin-left: 45px;
                }

                .accent {
                    width: 7px;
                    height: 80px;
                    transform: skew(-25deg);
                    display: inline-block;
                    margin-left: 20px;
                    position: absolute;
                    top: 12px;
                }
            }

            &.straight {
                .header {
                    margin-left: 20px;
                }
                .accent {
                    width: 7px;
                    height: 80px;
                    display: inline-block;
                    position: absolute;
                    top: 12px;
                }
            }
        }
        &-05 {
            position: relative;
            margin-bottom: 10px;
            .benefits {
                background:#ebebeb;
                min-height: 55px;
                padding:10px;
                width:100%;
                margin-bottom:0px;
                    p {
                    margin: 0px;
                    padding: 0px;
                    }
                }
            h3 {
                margin-left: 0px;
                margin-bottom: 0px;
            }
            .accent {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                height: auto;
                min-height: 70px;
                top: -10px;
                border-left-style:solid;
                border-left-width: 7px;
                padding:10px;
                margin-bottom:10px;
                box-sizing: border-box;
            }
        }
        &-06 {
            .number {
                font-size: 75px;
                color: $color-300;
            }
            h3 {
                border-top: 2px solid;
                border-color: $color-04;
                margin: 0 auto;
            }
            h4 {
                margin: 0 auto 20px;
                font-family: $font-primary;
                line-height: 38px;
            }
            p {
                color: $color-04;
            }
        }
        &-07
        {
            text-align:center;
            h3
            {
                margin-top:10px;
            }
            p
            {
                text-align:left;
                color:#333;
            }
            &-img-container
            {
                min-height:100px;
                line-height:100px;
                img
                {
                    vertical-align:middle;
                }
            }
        }
    }
}
.panel.panel-section
{
    padding-top:0px;
}

@media only screen and (min-width: $media-min-width-desktop) {
    .panel {
        &-thin-section,
        &-section {
             img
             {
                max-width: 100%;
                display:block;
                margin:auto;
                display:inline-block;
                padding-top: 5px;
             }
             &-content
             {
                 width:460px;
                 float:right;
                 min-height:130px;
             }
        }
    }
}
