.navigation-utility {
    @include font-graphik-regular;
    border-bottom: 1px solid $gray-90;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1001;

    @media screen and (max-width: $media-aem-tablet-max) {
        display: none;
    }

    ul{
        padding: 0;
        margin: 0;
    }

    .navigation-utility__wrap {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: $color-white;
    }

    .navigation-utility__container {
        width: 100%;
        height: 39px;
        max-width: 1184px;
        margin: auto;
        display: flex;
        justify-content: flex-end;
    }

    &.navigation-utility--edit {
        position: relative;
        
        .navigation-utility__container {
            height: auto;
        }
    }

    .navigation-utility__text {
        padding: 9px 0;
    }

    .navigation-utility__list {
        display: flex;
        margin-right: 32px;

        &.navigation-utility__list--isEdit {
            display: block;
            max-width: 350px;
        }
    }

    li, 
    li.navigation-utility__item {
        list-style: none;
        padding: 9px 12px;
        position: relative;
        margin-bottom: 0;
        transition: all ease-in-out 0.3s;
        cursor: pointer;

        a {
            color: $color-primary;
            @include font-graphik-regular;

            &:hover {
                text-decoration: none;
            }
        }

        &:not(:first-child) {
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                border-left: 1px solid $gray-90;
                height: 50%;
            }
        }

        &.navigation-utility__item--dropdown {
            padding-right: 30px;

            &::before {
                content: "";
                position: absolute;
                top: 47%;
                right: 15px;
                transform: translateY(-50%) rotate(135deg);
                border-top: 1px solid $color-primary;
                border-right: 1px solid $color-primary;
                height: 5px;
                width: 5px;
                transition: all ease-in-out 0.3s;
            }
        }

        &.navigation-utility__item--active {
            background: $light-cyan;

            &::before {
                top: 52%;
                transform: translateY(-50%) rotate(-45deg);
            }

            &:hover {
                text-decoration: none;
            }
        }

        .navigation-utility__submenu {
            display: none;

            &.navigation-utility__submenu--isEdit {
                display: block;

                &:hover {
                    text-decoration: none;
                }

                .navigation-utility__userRole,
                .navigation-utility__dropdownLinkHover {
                    display: none !important;
                }
            }
        }
    }

    .navigation-utility__toggleMenu {
        position: relative;
        padding-top: 60px;

        &::after {
            content: "";
            border-top: 1px solid #e5e5e5;
            width: 100%;
            position: absolute;
            top: 0;
        }

        .navigation-utility__submenu {
            max-width: 1120px;
            height: 444px;
            margin: auto;
            position: relative;

            &.navigation-utility__submenu--login {
                height: auto;
                max-width: 1140px;
            }
            
            &.js-toggle {
                display: none;
            }
        }

        .navigation-utility__submenuHeader {
            @include font-graphik-medium(14px);
            margin-bottom: 40px;
            padding-bottom: 0;
        }

        .navigation-utility__submenuItem {
            list-style: none;
            padding: 10px 0;
            width: 350px;
            margin-bottom: 20px;
            font-size: 18px;
            transition: all ease-in-out 0.3s;
            position: static;

            &:hover {
                text-decoration: underline;
                color: $color-link;
            }

            &:after {
                display: none;
            }

            a {
                font-size: 18px;
                position: relative;
                color: $color-primary;
            }

            &.navigation-utility__submenuItemUserRole {
                a {
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: initial;
                        margin-left: 5px;
                        transform: scale(0.85) translateY(-50%) rotate(46deg);
                        border-top: 2px solid $color-primary;
                        border-right: 2px solid $color-primary;
                        border-left: none;
                        height: 10px;
                        width: 10px;
                        transition: all ease-in-out 0.3s;
                    }
                }
            }

            &.navigation-utility__submenuItem--active,
            &:hover {
                a {
                    color: $color-link;

                    &::after {
                        border-color: $color-link;
                    }
                }
            }

            &.navigation-utility__submenuItem--active {
                a {
                    padding-left: 30px;
                }

                &.navigation-utility__submenuItemUserRole {
                    a {
                        &::before {
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 0;
                            margin-left: 10px;
                            transform: translateY(-50%) rotate(225deg);
                            border-top: 2px solid $color-link;
                            border-right: 2px solid $color-link;
                            border-left: none;
                            height: 10px;
                            width: 10px;
                        }

                        &::after {
                            display: none;
                        }
                    }
                }
            }

            .navigation-utility__userRole,
            .navigation-utility__dropdownLinkHover {
                position: absolute;
                width: 350px;
                font-size: 1rem;
                top: 0;
                right: 122px;
                transition: all ease-in-out 0.3s;
                display: none;

                &.navigation-utility__userRole--active {
                    animation: slideRight ease-in-out 0.3s;
                }

                &.navigation-utility__userRole--hide {
                    animation: slideRightFade ease-in-out 0.3s;
                }

                &:hover {
                    text-decoration: none;
                }

                ul {
                    li {
                        padding: 0;

                        &::after {
                            display: none;
                        }

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                a {
                    padding-left: 0;

                    &::before,
                    &::after {
                        display: none;
                    }
                }

                .navigation-utility__userRoleMobileBack {
                    display: none;
                }

                .navigation-utility__userRoleTitle {
                    @include font-graphik-medium(24px);
                    margin-top: 0;
                    margin-bottom: 25px;
                }

                .navigation-utility__userRoleBtn {
                    @include font-graphik-regular(16px);
                    border-radius: 0;
                    padding: 16px 32px;
                    background: $color-link;
                    color: $color-white;
                    cursor: pointer;

                    &:disabled {
                        opacity: 0.3;
                    }
                }

                .navigation-utility__userRoleSubtitle {
                    margin-bottom: 24px;
                    color: $color-primary;

                    a,
                    p {
                        @include font-graphik-regular(16px);
                    }

                    strong {
                        @include font-graphik-medium(16px);
                    }
                }

                .navigation-utility__userRoleConfirmBtn {
                    margin-top: 20px;
                }

                .navigation-utility__userRoleConfirm {
                    margin-bottom: 15px;
                    font-size: 0.8rem;
                    display: none;
                    position: relative;
                    
                    .navigation-utility__userRoleCheck {
                        position: relative;
                        vertical-align: middle;
                        cursor: pointer;
                        display: flex;

                        .navigation-utility__userRoleCheckConfirm {
                            @include font-graphik-regular(12px);
                            width: calc(100% - 15px);
                            margin-left: 10px;

                            ul {
                                padding-left: 40px;
                                
                                li {
                                    list-style: disc;
                                }
                            }

                            p, a {
                                font-size: 12px;
                            }
                        }
                        
                        span {
                            border: 1px solid $gray-90;
                            height: 15px;
                            width: 15px;
                            float: left;
                            background: transparent;
                            position: relative;
                            margin-top: 5px;
                        }
                
                        input[type="checkbox"] {
                            position: absolute;
                            opacity: 0;
                            height: 0;
                            width: 0;
                
                            &:checked {
                                &~ span {
                                    border-color: $color-link;
                                    background: $color-link;
                
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        top: 40%;
                                        left: 50%;
                                        transform: translate(-50%, -50%) rotate(45deg);
                                        height: 8px;
                                        width: 4px;
                                        border-right: 1px solid $color-white;
                                        border-bottom: 1px solid $color-white;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.navigation-utility__submenuItem--linkHover {
                padding: 2px 0 10px;

                &:not(:first-child) {
                    padding-top: 12px;
                }

                a {
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: initial;
                        margin-left: 10px;
                        transform: scale(0.85) translateY(-50%) rotate(46deg);
                        border-top: 2px solid $color-primary;
                        border-right: 2px solid $color-primary;
                        border-left: none;
                        height: 10px;
                        width: 10px;
                        transition: all ease-in-out 0.3s;
                    }
                }

                &:hover {
                    a {
                        &::after {
                            border-color: $color-link;
                        }
                    }
                }
            }
            
        }

        .navigation-utility__dropdownLinkHoverTitle {
            @include font-graphik-medium(24px);            
        }
    }

    @keyframes slideRight {
        from {
            right: 222px;
            opacity: 0;
        }
        
        to {
            right: 122px;
            opacity: 1;
        }
    }

    @keyframes slideRightFade {
        from {
            right: 122px;
            opacity: 1;
        }
        
        to {
            right: 0;
            opacity: 0;
        }
    }
}
