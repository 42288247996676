.wrapper-cover
{
	margin:auto;
	margin-top:120px;
	position:relative;
	margin-bottom:40px;
	video
	{
		display:none;
	}
	.welcome-text
	{
		display:none;
		*
		{
			color: $color-link;
		}
		a
		{
			text-decoration:underline;
		}
	}
}

.wrapper-cover
{
	background: url("../images/rebrand-banner-01.jpg") no-repeat center center;
	background-size: cover;
	height:270px;
}

@media only screen and (min-width: $media-min-width-tablet) {
	.wrapper-cover
	{
		background: url("../images/rebrand-banner-01.jpg") no-repeat center center;
		background-size: cover;
		height:340px;
	}
}


@media only screen and (min-width: $media-min-width-desktop) {
	.wrapper-cover
	{
		margin-top:10px;
		background: url("../images/rebrand-banner-01.jpg") no-repeat 0px center;
		background-size: cover;
		width:100%;
		max-width:1280px;
		height:420px;
		&-video
		{
			background:none;
			margin-bottom:120px;
			video
			{
				display:block;
			}
		}
		&.edit-mode {
			margin-top: 150px !important;
		}
	}
	#wrapper-main {
		background-image: none;
	}
	
	.home,
	.home-page {
		#wrapper-main {
			background:none;
		}
	}
	.welcome-text {
		position: absolute;
		display: block !important;
		top: 60px;
		left: 180px;
		max-width: 340px;
	}
}
