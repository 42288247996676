.tracking-error-range-bar
{
	&-data, &-graph
	{
		display:inline-block;
	}
	&-data
	{
		p
		{
			margin-bottom:0px;
		}
		.current
		{
			color:$color-link;
		}
	}
	&-graph
	{
		float: right;
		width: 80px;
		height: 30px;
		background-color: #dedede;
		position: relative;
		margin-bottom: 20px;

		@media screen and (max-width: $media-max-width-tablet) {
			float: none;
			display: block;
			margin-top: 10px;
		}

		span
		{
			position:absolute;
			&.zero, &.ten
			{
				color:#7c7c7c;
				top:30px;
			}
			&.zero
			{
				left:0px;
			}
			&.ten
			{
				right:0px;
			}
			&.low-to-high
			{
				background-color:#091E78;
				height:30px;
				top:0px;
			}
			&.current-line
			{
				width:1px;
				background-color: $color-link;
				height:30px;
				top:0px;
				&:after
				{
					content: '';
					display: block;
					width: 7px;
					height: 7px;
					border-radius: 7px;
					background-color: $color-link;
					position: absolute;
					top: 12px;
					left: -3px;
				}
			}
		}

	}
}

.columns.d10
{
	.tracking-error-range-bar
	{
		&-graph
		{
			float: none;
			display: block;
			margin-top: 10px;
		}
	}
}
