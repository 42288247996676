.bio
{
	.source
	{
		margin-bottom:10px;
		padding-top:0px;
	}
	img
	{
		max-width:100%;
		margin-bottom:20px;
	}
}