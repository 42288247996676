.tabbed-content {
	margin: 10px auto 25px;
	padding: 0;
	transition: all 400ms ease-in-out;

	ul {
		padding: 0;
	}

	li {
		list-style: none;
	}
	&-nav {
		margin: 0;
		background-color: $color-01;
		margin: 5px auto;
		padding: 16px 10px;
		@include clearfix;
		color: $color-primary;
		cursor: pointer;
		position: relative;

		h4 {
			display: inline-block;
			float: left;
			width: 10%;
		}
		p {
			display: inline-block;
			float: left;
			width: 80%;
			margin: 3px auto 0 auto;
		}
		.icon-right {
			position: absolute;
			top: 16px;
			right: 10px;
		}

		&.active {
			background-color: $color-226;
			color: #fff;
			font-family: $font-primary-bold;

			h4 {
				color: #fff;
			}
		}
	}
	&-copy {
		margin: 20px 0 30px;
		display: none;

		&-desktop {
			display: none;
		}

	}
}

@media screen and (min-width: $media-min-width-desktop) {
	.tabbed-content {
		&-copy {
			&-desktop {
				display: block;
			}

		}
	}
}
