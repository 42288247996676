.strategy-detail-page {
    dl.inline dt {
        width: 47%;
    }
    dl.inline dd {
        width: 51%;
    }
    .panel-section ul { 
    	padding: 0;  
    	li {
			.icon-pdf-document {
	    		width: 7%;
	    		vertical-align: top;
	    		display: inline-block;
	    	}
	    	a{
	    		width: 90%;
	    		display: inline-block;
	    	}
    	}
    }
}  