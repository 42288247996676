#custom_fc_button {
	bottom: 20px;
	position: fixed;
	right: 0;
}

#imageicon {
	height: 169px;
	width: 52px;
}


.products-hub-page,
.fund-detail-page,
.product-range-hub-page,
.product-range-page {
	#custom_fc_button {
		bottom: 300px;
	}
}  
