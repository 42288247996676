@media only screen and (max-width: $media-min-width-desktop) {
    .navsub
    {
        word-wrap:break-word;
    }
}
.navsub 
{   
    h3 
    {
        font-family: $font-primary;
        border-bottom: 1px solid $color-04;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: $font-size;
        margin: 0;
        display: block;
        border-top: 2px solid $color-04;
        a:link,
        a:active,
        a:visited,
        a:hover
        {
           color: #000;

           [class*="icon-"]{
               position: absolute;
               top: 6px;
               right: 0;
           }
        }
    }

    ul 
    {
        margin-top: 10px;
        margin-left: 0px;
        margin-bottom: 10px;
        padding:0px;
        li 
        {
            list-style: none;
            list-style-type:none;
            list-style-image:none;
            position:relative;
            margin-bottom:10px;
            a
            {
                overflow:hidden;
                display:inline-block;
                width:100%;
                &:hover
                {
                    cursor:pointer;
                }
            }
        }
    }

    #ot-sdk-btn.ot-sdk-show-settings
    {
        color: $color-link;
        font-size: inherit;
        text-decoration: none;
        border: none;
        padding: 0;
        line-height: inherit;
        -moz-transition: none;
        -o-transition: none;
        -webkit-transition: none;
        transition: none;
        
        &:hover
        {
            color: $color-link;
            text-decoration: underline;
            background: none;
        }
    }
}

@media only screen and (min-width: $media-min-width-desktop){
    .navsub
    {
        word-wrap:normal;
        &-hover.navsub-gradient
        {
            &:after
            {
                width: 0px;
            }
        }
        &-hover
        {
            z-index:1000;
            position:relative;
            background-color:white;
        }
        &-gradient
        {
            &:after
            {
                content: "";
                position: absolute;
                width: 70px;
                height: 100%;
                top: 0px;
                right: 0px;
                background-image: -webkit-linear-gradient(right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
                background-image: -moz-linear-gradient(right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
                background-image: -ms-linear-gradient(right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
                background-image: -o-linear-gradient(right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
                background-image: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
            }
        }
    }
}