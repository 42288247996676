.responsive-table
{
    color: $color-primary;

    &.narrow {
        width: 77%;

        @media screen and (max-width: $media-max-width-tablet) {
            width: 100%;
        }
    }

    &:hover {
        text-decoration: none;
    }

    sup {
        color: $color-link;
    }

    &-highlight {
        color: $color-link;
    }

    &-caption {
        color: $color-2748;
    }

    &-header {
        display: table;
        width: 100%;

        &.narrow {
            width: 77%;
        }

        @media screen and (max-width: $media-max-width-tablet) {
            display: none;
        }

        h3 {
            width: 23%;
            display: table-cell;
            vertical-align: bottom;
            margin: 20px auto;

            &:last-of-type {
                width: 28%;
                white-space: nowrap;
            }
        }
    }

    &-row {
        position: relative;
        display: table-row;

        &:hover {
            background-color: $color-01;
        }
    }
    &-cell {
        vertical-align: top;
        width: 23%;
        padding: 5px 10px 10px;
        display: table-cell;
        border-top: 1px solid #ccc;
        box-sizing: border-box;
        margin-bottom: 0;
        min-height: 160px;

        @media screen and (max-width: $media-max-width-tablet) {
            width: 100%;
            min-height: 0;
            border: none;
            display: block;
        }

        &:first-of-type {
            background-color: $color-01;

            @media screen and (max-width: $media-max-width-tablet) {
                border-top: 1px solid $color-02;
            }

            @media screen and (max-width: $media-max-width-mobile) {
                p {
                    max-width: 80%;
                }
            }
        }
        &:last-of-type {
            width: 8%;
        }

        .icon-right {
            box-sizing: border-box;
            width: 32px;
            height: 32px;
            border: 1px solid $color-02;
            border-radius: 16px;
            background-color: $color-white;
			text-align: center;
			line-height: 32px;
            float: right;
            color: $color-link;

            @media screen and (max-width: $media-max-width-tablet) {
                position: absolute;
                right: 8px;
                top: 8px;
            }
        }
    }
}
