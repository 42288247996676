.next-step {
	display: inline-block;
	margin-right: calc(20px - 3px);
	margin-bottom: 20px;
	vertical-align: top;

	a {
		display: inherit;
		width: 140px;
		height: 190px;
		background-color: #333;
		text-align: center;
		vertical-align: top;
		&:hover {
			text-decoration: none;
		}
	}

	&-icon {
		color: $color-white;
		display: block;
		font-size: 48px;
		line-height: 30px;
		padding-top: 40px;
		&:hover {
			text-decoration: none;
		}
	}
	&-text {
		padding: 0 10px;
		color: $color-white;
		font-family: 'InvescoInterstateBold';
		margin-top: 40px;
		&:hover {
			text-decoration: none;
		}
	}
}

@media only screen and (max-width: $media-min-width-mobile) {

	.next-step {
		margin-right: calc(10px - 3px);
		margin-bottom: 10px;
	}

}
