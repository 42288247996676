.load-more {
	position: relative;
	margin-bottom:20px;
	&:before {
		content:"";
		background:url('../images/load-more-line.png');
		display: block;
		position: absolute;
		width: 100%;
		height: 50px;
		z-index: -1;
	}
	&-button {
		@extend .button;
		
		margin: 0 auto;
		display: block;
		width: 140px;
	}
}