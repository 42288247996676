.teaser {
	border-top: 2px solid $color-04;
	padding-top: 2px;
	clear: both;
	margin-bottom: 40px;
	
		h4:after {
			content: "\e615";
			font-family: ip_ui_icons;
			color: #0014D2;
			float: right;
			cursor: pointer;
			font-size: 15px;
		}
		h4 {
			margin-top: 0px; 
			a {
				color:$color-primary;
			}
		}

		&-img-container {
			max-width:300px;
			width:300px;
			height:180px;
			border:1px solid $color-02;
			text-align:center;
			box-sizing: border-box;
			margin-bottom: 10px;
			margin-top: 40px;
				img {
					max-width:300px;
					max-height:180px;
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					}
				}
		&-summary {
 			width: 100%;
 			display: block;
			float: none;
			clear: both;
			min-height: 130px;
		}
 		&-clear {
			 clear:both;
		}
}

.global-search-page {
	.teaser {
		&-summary {
			min-height: auto;
		}
	}
}
 
/* Tablet */
@media only screen and (min-width: $media-min-width-tablet) and (max-width: $media-max-width-tablet) {

.teaser {
	padding-top:10px;
		&-img-container {
			float:left;
			margin-top: 0px;
			img {
				margin-bottom:10px;
				max-width:240px;
			}
		}
		&-img-container~.teaser-summary
		{
			float:right;
			width:300px;
		}
		&-img-container~h4
		{
			float:right;
			width: 300px;		
		}
		h4:after {
			display:none;
		}
		h4 {
			text-align:left;
		}
	 	&-summary {
			min-height: 130px;
			clear:none;
				p {
					margin-top:40px;
				}
		}
	}
}

/* Desktop */
@media only screen and (min-width: $media-min-width-desktop) {

.teaser {
	padding-top:10px;
		h4:after {
			display:none;
		}
		&-img-container {
			float:left;
			margin-top: 0px;
		}
		&-img-container~.teaser-summary
		{
			float:right;
			width: 400px;
		}
		&-img-container~h4
		{
			float:right;
			width:400px;		
		}
		h4 {

			text-align:left;
		}
		&-summary {

			min-height: 130px;
			clear:none;
			p {
				margin-top:40px;
			}
		}
	}
}
