.breadcrumbs
{
	display:none;
}

@media only screen and (min-width: $media-min-width-desktop)
{
	.breadcrumbs {
		margin-bottom:10px;
		display:block;

		span
		{
			&:first-child:before
			{
				margin-left: 0;
				margin-right: 0;
				content: "";
			}
			a {
				display: inline-block;
				text-decoration: none;

				&.current {
					text-decoration: none;
					cursor: default;
					color: $color-primary;
				}
				&:hover
				{
					text-decoration: underline;
				}
			}
		}
	}
}
