@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin icon-down-before {
    content: "\e639";
}

@mixin icon-up-before {
  content: "\e67a";
}

@mixin icon-right-before {
  content: "\e667";
}

@mixin icon-user-before {
  content: "\e67d";
}

@mixin icon-tick-before {
  content: "\e676";
}

@mixin icon-bookmark-before {
  content: "\e62f";
}

@mixin icon-warning-before {
    content: "\e684";
}

@mixin icon-video-content-before {
    content: "\e67e";
}

@mixin icon-event-before {
    content: "\e63e";
}

@mixin icon-document-before {
  content: "\e638";
}

@mixin icon-audio-content-before {
  content: "\e62c";
}

@mixin icon-product-before {
  content: "\e665";
}

@mixin icon-warning2-before {
  content: "\e685";
}

@mixin icon-desktop-before {
  content: "\e637";
}

@mixin slidedownslideup{
	-webkit-transition: max-height 0.5s ease;
	-moz-transition: max-height 0.5s ease;
	-o-transition: max-height 0.5s ease;
	-ms-transition: max-height 0.5s ease;
	transition: max-height 0.5s ease;
	max-height:0px;		
	overflow-y:hidden;	
}

@mixin slideinslideout{
	-ms-transition: right 0.2s ease 0s;
	-o-transition: right 0.2s ease 0s;
	-webkit-transition: right 0.2s ease 0s;
	-moz-transition: right 0.2s ease 0s;
	transition: right 0.2s ease 0s;
}

@mixin slideinslideoutleft{
	-ms-transition: left 0.3s ease 0s;
	-o-transition: left 0.3s ease 0s;
	-webkit-transition: left 0.3s ease 0s;
	-moz-transition: left 0.3s ease 0s;
	transition: left 0.3s ease 0s;
}

@mixin smoothbordercolorchange{
	-ms-transition: border 0.5s ease 0s;
	-o-transition: border 0.5s ease 0s;
	-webkit-transition: border 0.5s ease 0s;
	-moz-transition: border 0.5s ease 0s;
	transition: border 0.5s ease 0s;
}

@mixin fadeinfadeout{
	-ms-transition: background 0.5s ease 0s;
	-o-transition: background 0.5s ease 0s;
	-webkit-transition: background 0.5s ease 0s;
	-moz-transition: background 0.5s ease 0s;
	transition: background 0.5s ease 0s;	
}

@mixin expandwidth{
	-ms-transition: width 0.4s ease 0s;
	-o-transition: width 0.4s ease 0s;
	-webkit-transition: width 0.4s ease 0s;
	-moz-transition: width 0.4s ease 0s;
	transition: width 0.4s ease 0s;	
}

@mixin backgroundposition
{
	transition:background-position 0.4s ease;
	-ms-transition:background-position 0.4s ease;
	-o-transition:background-position 0.4s ease;
	-webkit-transition:background-position 0.4s ease;
	-moz-transition:background-position 0.4s ease;
}

@mixin transitional{
	transition: all 0.2s;
	-ms-transition: all 0.2s;
	-o-transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;	
}

@mixin backgroundcolor{
	transition: background-color 0.1s, color 0.1s;
	-ms-transition: background-color 0.1s, color 0.1s;
	-o-transition: background-color 0.1s, color 0.1s;
	-webkit-transition: background-color 0.1s, color 0.1s;
	-moz-transition: background-color 0.1s, color 0.1s;	
}
