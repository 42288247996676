@font-face {
	font-family: 'icomoon';
	src: url('fonts/icomoon.eot');
	src: url('fonts/icomoon.eot#iefix') format('embedded-opentype'),
		 url('fonts/icomoon.woff2') format('woff2'),
		 url('fonts/icomoon.ttf') format('truetype'),
		 url('fonts/icomoon.woff') format('woff'),
		 url('fonts/icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

.icon,
[class^="icon-"],
[class*=" icon-"] {
	&:not(.status-indicator):not(.editorIcon) {
		&:before {
			/* use !important to prevent issues with browser extensions that change fonts */
			font-family: 'icomoon' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			/* Better Font Rendering =========== */
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
	}
}

.icon-x2:before { font-size: 2em; }
.icon-x3:before { font-size: 3em; }
.icon-x4:before { font-size: 4em; }

.icon-accessible:before {
  content: "\e900";
}
.icon-active:before {
  content: "\e902";
}
.icon-alpha:before {
  content: "\e903";
}
.icon-asset-location:before {
  content: "\e904";
}
.icon-flexible:before {
  content: "\e905";
}
.icon-focus:before {
  content: "\e906";
}
.icon-highly-diversified:before {
  content: "\e907";
}
.icon-icon-video:before {
  content: "\e908";
}
.icon-insight-ideas:before {
  content: "\e909";
}
.icon-long_term:before {
  content: "\e90a";
}
.icon-market-points:before {
  content: "\e90b";
}
.icon-process:before {
  content: "\e90c";
}
.icon-product-bar-up:before {
  content: "\e90d";
}
.icon-product-bar-down:before {
  content: "\e90e";
}
.icon-risk-management:before {
  content: "\e90f";
}
.icon-risk:before {
  content: "\e912";
}
.icon-target:before {
  content: "\e913";
}
.icon-team:before {
  content: "\e914";
}
.icon-valuation:before {
  content: "\e922";
}
.icon-weather-Copy:before {
  content: "\e923";
}
.icon-facebook:before {
  content: "\e924";
}
.icon-instagram:before {
  content: "\e91f";
}
.icon-linkedin:before {
  content: "\e605";
}
.icon-linkedin2:before {
  content: "\e606";
}
.icon-twitter:before {
  content: "\e609";
}
.icon-add:before {
  content: "\e628";
}
.icon-add-comment:before {
  content: "\e626";
}
.icon-add-folder:before {
  content: "\e627";
}
.icon-add2:before {
  content: "\e629";
}
.icon-archive:before {
  content: "\e62a";
}
.icon-asset-allocation:before {
  content: "\e62b";
}
.icon-audio-content:before {
  content: "\e62c";
}
.icon-award:before {
  content: "\e62d";
}
.icon-award2:before {
  content: "\e901";
}
.icon-award3:before {
  content: "\e91a";
}
.icon-blog:before {
  content: "\e62e";
}
.icon-bookmark:before {
  content: "\e62f";
}
.icon-bullet:before {
  content: "\e630";
}
.icon-chapter:before {
  content: "\e631";
}
.icon-close:before {
  content: "\e632";
}
.icon-close2:before {
  content: "\e633";
}
.icon-content-indicator-active:before {
  content: "\e634";
}
.icon-content-indicator-static:before {
  content: "\e635";
}
.icon-desktop:before {
  content: "\e637";
}
.icon-document:before {
  content: "\e638";
}
.icon-down:before {
  content: "\e639";
}
.icon-download:before {
  content: "\e63a";
}
.icon-edit:before {
  content: "\e63b";
}
.icon-education:before {
  content: "\e91d";
}
.icon-equals:before {
  content: "\e63c";
}
.icon-event:before {
  content: "\e63e";
}
.icon-event-meeting:before {
  content: "\e63d";
}
.icon-favourite-active:before {
  content: "\e641";
}
.icon-favourite-static:before {
  content: "\e642";
}
.icon-filter:before {
  content: "\e643";
}
.icon-folder:before {
  content: "\e644";
}
.icon-fullscreen:before {
  content: "\e645";
}
.icon-gallery:before {
  content: "\e91e";
}
.icon-help2:before {
  content: "\e647";
}
.icon-historic-prices:before {
  content: "\e648";
}
.icon-home:before {
  content: "\e649";
}
.icon-image-content:before {
  content: "\e64a";
}
.icon-information:before {
  content: "\e64b";
}
.icon-insights:before {
  content: "\e91b";
}
.icon-left:before {
  content: "\e64c";
}
.icon-link:before, .icon-external:before {
  content: "\e64d";
}
.icon-link2:before {
  content: "\e64e";
}
.icon-list-view:before {
  content: "\e64f";
}
.icon-live-person:before {
  content: "\e650";
}
.icon-location:before {
  content: "\e651";
}
.icon-locked:before {
  content: "\e652";
}
.icon-mail:before {
  content: "\e653";
}
.icon-menu:before {
  content: "\e654";
}
.icon-meta:before {
  content: "\e614";
}
.icon-meta-active:before {
  content: "\e616";
}
.icon-minus:before {
  content: "\e655";
}
.icon-minus2:before {
  content: "\e656";
}
.icon-network:before {
  content: "\e657";
}
.icon-new-window:before, .icon-blank:before {
  content: "\e658";
}
.icon-next:before {
  content: "\e659";
}
.icon-next2:before {
  content: "\e65a";
}
.icon-page-menu:before {
  content: "\e617";
}
.icon-panel-view:before {
  content: "\e65c";
}
.icon-pause:before {
  content: "\e65d";
}
.icon-pdf-document:before, .icon-pdf:before {
  content: "\e65e";
}
.icon-play:before {
  content: "\e65f";
}
.icon-presentation:before {
  content: "\e660";
}
.icon-previous:before {
  content: "\e661";
}
.icon-previous2:before {
  content: "\e662";
}
.icon-prices:before {
  content: "\e663";
}
.icon-print:before {
  content: "\e664";
}
.icon-product:before {
  content: "\e665";
}
.icon-refresh:before {
  content: "\e666";
}
.icon-right:before {
  content: "\e667";
}
.icon-rss:before {
  content: "\e668";
}
.icon-search:before {
  content: "\e669";
}
.icon-selector-down:before {
  content: "\e691";
}
.icon-selector-up:before {
  content: "\e692";
}
.icon-settings:before {
  content: "\e66a";
}
.icon-share:before {
  content: "\e66b";
}
.icon-shopping-basket:before {
  content: "\e66c";
}
.icon-shopping-cart:before {
  content: "\e66d";
}
.icon-smartphone:before {
  content: "\e66e";
}
.icon-stop:before {
  content: "\e66f";
}
.icon-swatch:before {
  content: "\e670";
}
.icon-swatch2:before {
  content: "\e671";
}
.icon-tablet:before {
  content: "\e673";
}
.icon-tag:before {
  content: "\e674";
}
.icon-tags:before {
  content: "\e675";
}
.icon-tick:before {
  content: "\e676";
}
.icon-time-stamp:before {
  content: "\e677";
}
.icon-tracker:before {
  content: "\e678";
}
.icon-un-locked:before {
  content: "\e679";
}
.icon-up:before {
  content: "\e67a";
}
.icon-upload:before {
  content: "\e67b";
}
.icon-user:before {
  content: "\e67d";
}
.icon-user-folder:before {
  content: "\e67c";
}
.icon-video-content:before {
  content: "\e67e";
}
.icon-volume:before {
  content: "\e67f";
}
.icon-volume2:before {
  content: "\e680";
}
.icon-volume3:before {
  content: "\e681";
}
.icon-volume4:before {
  content: "\e682";
}
.icon-volume5:before {
  content: "\e683";
}
.icon-warning:before {
  content: "\e684";
}
.icon-warning2:before {
  content: "\e685";
}
.icon-word-document:before, .icon-word:before, .icon-doc:before {
  content: "\e686";
}
.icon-xl-document:before, .icon-spreadsheet:before, .icon-xl:before {
  content: "\e687";
}
.icon-id:before {
  content: "\e910";
}
.icon-information2:before {
  content: "\e911";
}
.icon-secure_wifi:before {
  content: "\e915";
}
.icon-spot:before {
  content: "\e916";
}
.icon-secure2:before {
  content: "\e917";
}
.icon-secure-web:before {
  content: "\e918";
}
