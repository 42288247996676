.article-related
{
	color:$color-03;
	font-family:$font-primary;
	margin-bottom:15px;
	position:relative;
	max-width:320px;
	border-top: 1px solid $color-02;
	padding-top: 8px;

	&-image
		{
			display:block;
			img
			{
				max-width: 100%;
				height: auto;
			}
		}

	div
	{
		&:not(:first-of-type)
		{
			padding-bottom:5px;
		}
		&:not(:nth-of-type(2))
		{
			padding-top:5px;
			padding-bottom:5px;
		}
		&:not(:nth-of-type(-n+2))
		{
			border-top:1px dotted $color-03;
		}
	}
	&-title {
		font-size: 20px;
		line-height: 25px;
		color:black;
		padding:3px 20px 15px 0px;
		margin-bottom:10px;
		display: block;

		&:after {
			@include icon-right-before;
			font-family:$font-icons;
			color:$color-link;
			position:absolute;
			top:8px;
			right:0px;
			cursor:pointer;
			font-size:15px;
		}
		
		&:hover{
			color:$color-link;
		}
	}
	&-bookmark{
		top: -24px;
		right: 0;
	}
	&-small{
	   h3{
		   font-size: 20px;
		   &:after{
			   content: " ";
		   }
	   }
	}
}

@media only screen and (min-width: $media-min-width-tablet)
{
	.article-related
	{

		&-image
		{
			text-align:center;
			position: relative;
			img
			{
				max-height:170px;
			}
		}
	}
}
