.promo
{
	position:relative;
	margin:30px 0px;
	display:block;
	&-content
	{
		padding:5px 5px 10px 50px;
		font-size:$font-size;
		line-height:$line-height;
		color:$color-03;
		min-height:70px;
		margin-bottom:15px;
		&-icon
		{
			&:after
			{
				position:absolute;
				width:35px;
				color:white;
				height:35px;
				line-height:30px;
				font-size:20px;
				text-align:center;
				font-family: $font-icons;
			}
			&:before
			{
				position:absolute;
				top:35px;
				left:0px;
				content:"";
				width: 0;
				height: 0;
				border-top: 35px solid transparent;
				border-bottom: 35px solid transparent;
				margin-top:-35px;
			}
		}
		a
		{
			text-decoration:none;
			padding-top:2px;
			margin-top: 0;
		}
		&-label~&-meta::before
		{
			content:" | ";
		}
		&-label
		{

		}
		&-description
		{
			margin-top: 10px;
			padding-right: 20px;
			color: $color-primary;
		}
		&-readMore
		{
			padding-top: 15px;

			a
			{
				text-decoration:none;
			}
		}
	}
	&-video-event
	{
		@extend .promo-content-icon;
		&:after
		{
			@include icon-video-content-before;
			background-color:#f50091;
		}
		&:before
		{
			border-left: 35px solid #f50091;
		}
	}

	&-event
	{
		@extend .promo-content-icon;
		&:after
		{
			@include icon-event-before;
			background-color:#6423a0;
		}
		&:before
		{
			border-left: 35px solid #6423a0;
		}
	}
	&-article
	{
		@extend .promo-content-icon;
		&:after
		{
			@include icon-document-before;
			background-color:#6423a0;
		}
		&:before
		{
			border-left: 35px solid #6423a0;
		}
	}
	&-audio-event
	{
		@extend .promo-content-icon;
		&:after
		{
			background-color:#af23a5;
			@include icon-audio-content-before;
		}
		&:before
		{
			border-left: 35px solid #af23a5;
		}
	}
	&-product
	{
		@extend .promo-content-icon;
		&:after
		{
			background-color:#0014D2;
			@include icon-product-before;
		}
		&:before
		{
			border-left: 35px solid #0014D2;
		}
	}
	&-warning
	{
		@extend .promo-content-icon;
		&:after
		{
			background-color:#0014D2;
			@include icon-warning2-before;
		}
		&:before
		{
			border-left: 35px solid #0014D2;
		}
	}
	&-microsite
	{
		@extend .promo-content-icon;
		&:after
		{
			background-color:#0014D2;
			@include icon-desktop-before;
		}
		&:before
		{
			border-left: 35px solid #0014D2;
		}
	}
	&-product,&-microsite
	{
		&~.promo-content div span.promo-content-meta::before
		{
			content:"";
		}
	}
}

@media only screen and (min-width: $media-min-width-desktop)
{
	.promo
	{
		&-with-description
		{
			h4
			{
				width:40%;
				display:inline-block;
			}
			.promo-content-description
			{
				display:inline-block;
				color:$color-04;
				float:right;
				padding-top:4px;
				width:55%;
			}
		}
		&-product,&-microsite
		{
			&~.promo-content div span
			{
				display:block;
			}
			&~.promo-content div span.promo-content-meta::before
			{
				content:"";
			}
		}
	}
}
