.product {
	display:block;
	border-top: 2px solid $color-04;
	padding-top: 10px;
	clear: both;
		&-title {
			display:block;
			width: 100%;
			}
		&-summary {
			display:block;
			width: 100%;
			}
		&-clear {
			clear:both;
		}
		span {
			float: right;
			color: $color-link;
			}
}

/* Tablet */
@media only screen and (min-width: $media-min-width-tablet) and (max-width: $media-max-width-tablet) {

	.product 
	{
		&-title 
		{
			display:inline-block;
			vertical-align:top;
			width: 320px
		}
		&-summary 
		{
			display:inline-block;
			vertical-align:top;
			width: 240px;

		}
	}

}

/* Desktop */
@media only screen and (min-width: $media-min-width-desktop) { 

	.product 
	{
		&-title 
		{
			display:inline-block;
			vertical-align:top;
			width: 400px
		}
		&-summary 
		{
			display:inline-block;
			vertical-align:top;
			width: 320px;
		}
	}

}