.primary-footer__social {
    h3 {
        font-size: 12px;
    }

    .primary-footer__social-items {
        overflow: hidden;
        margin: 0;

        &.mode--edit {
            .primary-footer__social-item {
                float: none;
            }
        }

        .primary-footer__social-item {
            float: left;
            margin-right: 23px;
            
            a {
                height: 36px;
                width: 36px;
                display: block;
                position: relative;
                text-decoration: none;
                border-radius: 50%;
                background-color: $color-white;
                color: $color-navy-blue;
                font-size: 16px;
                transition: color .3s, background-color .3s;
                border: 1px solid $color-white;

                &.no-border {
                    border: none;
                }

                &:hover {
                    background-color: $color-navy-blue;
                    color: $color-white;

                    i {
                        &.primary-footer__social-twitter {
                            background-image: url($twitter-hover);
                            
                        }
                    }
                }

                &[target="_blank"]:before {
                    display: none;
                }

                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    &.primary-footer__social-twitter {
                        width: 100%;
                        height: 100%;
                        background-image: url($twitter-default);
                        background-size: cover;
                    }
                }
            }
        }
    }
}