.audience-secondary
{
	position:relative;
	&-content
	{
		padding:5px 5px 10px 50px;
		font-size:$font-size;
		line-height:$line-height;
		color:$color-03;
		min-height:70px;
		word-wrap:break-word;
		margin-bottom:15px;
		&-icon
		{
			&:after
			{
				position:absolute;
				width:35px;
				color:white;
				height:35px;
				line-height:30px;
				font-size:25px;
				text-align:center;
				font-family: $font-icons;
			}
			&:before
			{
				position:absolute;
				top:35px;
				left:0px;
				content:"";
				width: 0;
				height: 0;
				border-top: 35px solid transparent;
				border-bottom: 35px solid transparent;
				margin-top:-35px;
			}
		}
		h4
		{
			padding-top:2px;
			padding-bottom:10px;
		}
		&-description
		{
			display:block;
			margin-bottom:20px;
		}
	}
	.dialog-wrap
	{
		padding-bottom:20px;
		a
		{
			cursor:pointer;
		}
	}
	&-investor
	{
		@extend .audience-secondary-content-icon;
		&:after
		{
			@include icon-user-before;
			background-color:#00b760;
		}
		&:before
		{
			border-left: 35px solid #00b760;
		}
	}
	&-adviser
	{
		@extend .audience-secondary-content-icon;
		&:after
		{
			background-color:#8cc800;
			@include icon-user-before;
		}
		&:before
		{
			border-left: 35px solid #8cc800;
		}
	}
	&-institutional
	{
		@extend .audience-secondary-content-icon;
		&:after
		{
			background-color:#008c82;
			@include icon-user-before;
		}
		&:before
		{
			border-left: 35px solid #008c82;
		}
	}
}



.audience {
	display: block;
	color: #ffffff;
	margin-top: 0px;
	margin-bottom: 20px;
	height: 300px;
	vertical-align: top;
	padding-top: 30px;
	cursor: pointer;
	&:hover {
		text-decoration: none;
	}
	&-icon {
		margin: 0 auto;
		text-align: center;
		border-radius: 100px;
		width: 100px;
		height: 100px;
		background: url(../images/icon-bg.png);
		span {
			font-size: 50px;
			line-height: 2;
		}
	}
	&-icon-black {
		color: #000000;
		margin-right: 2px;
		margin-bottom: 50px;
	}
	&-content {
		padding: 30px;
		text-align: left;
		h2 {
			letter-spacing: 0px;
			color: $color-white;
		}
	}
	&-investor {
		background: $color-300;
		/* For browsers that do not support gradients */
		background: -webkit-linear-gradient(45deg, $color-300, $color-2748);
		/* For Safari 5.1 to 6.0 */
		background: -o-linear-gradient(45deg, $color-300, $color-2748);
		/* For Opera 11.1 to 12.0 */
		background: -moz-linear-gradient(45deg, $color-300, $color-2748);
		/* For Firefox 3.6 to 15 */
		background: linear-gradient(45deg, $color-300, $color-2748);
		/* Standard syntax (must be last) */
	}
	&-adviser {
		background: $color-300;
		/* For browsers that do not support gradients */
		background: -webkit-linear-gradient(45deg, $color-300, $color-299);
		/* For Safari 5.1 to 6.0 */
		background: -o-linear-gradient(45deg, $color-300, $color-299);
		/* For Opera 11.1 to 12.0 */
		background: -moz-linear-gradient(45deg, $color-300, $color-299);
		/* For Firefox 3.6 to 15 */
		background: linear-gradient(45deg, $color-300, $color-299);
		/* Standard syntax (must be last) */
	}
	&-institutional {
		background: $color-3262;
		/* For browsers that do not support gradients */
		background: -webkit-linear-gradient(45deg, $color-3262, $color-3262);
		/* For Safari 5.1 to 6.0 */
		background: -o-linear-gradient(45deg, $color-3262, $color-3262);
		/* For Opera 11.1 to 12.0 */
		background: -moz-linear-gradient(45deg, $color-3262, $color-3262);
		/* For Firefox 3.6 to 15 */
		background: linear-gradient(45deg, $color-3262, $color-3262);
		/* Standard syntax (must be last) */
	}
}

@media only screen and (min-width: $media-min-width-mobile) and (max-width: $media-max-width-tablet) {
	.audience {
		height: auto;
		min-height: 170px;
		padding-top: 10px;
		h2 {
			text-align: left;
			font-size: 20px;
			line-height: 35px;
		}
		&-icon {
			margin: none;
			margin-left: 20px;
			float: left;
		}
		&-content {
			margin-left: 140px;
			text-align: left;
			padding: 0px;
			padding-right:20px;
			padding-left: 0;
		}
	}
}

@media only screen and (min-width: $media-min-width-desktop) {
	.audience {
		min-height: 400px;
		height: auto;
		padding-top: 40px;
		&-content {
			h2 {
				font-family: $font-primary-bold;
				text-align: left;
				font-size: 20px;
				line-height: 35px;
				margin: 0 auto 20px;
				max-width: 150px;
			}
		}
	}
}
