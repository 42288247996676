dl
{
	width:100%;
	.definition-group
	{
		margin-bottom:40px;
		&:first-child
		{
			display:none;
		}
		dt
		{
			h4
			{
				font-family: $font-primary-bold;
				font-size: $font-size;
				line-height: $line-height;
				margin-bottom: 0px;
				letter-spacing: 0px;
			}
			p
			{
				font-family: $font-primary;
			}
		}
	}
}

@media only screen and (min-width: $media-min-width-desktop)
{
	dl
	{
		display:table;
		border-collapse:collapse;
		.definition-group
		{
            &:not(:last-child)
            {
                border-bottom:1px solid #cecece;
            }
			&, &:first-child {
				display: -ms-flexbox;
				display: flex;
            }
            &:first-child
			{
				dt, dd
				{
					padding-bottom:20px;
					h3
					{
						font-family: $font-primary;
					    font-size: 20px;
					    line-height: 25px;
					    font-weight: 400;
				    }
				}
			}
			dt
			{
                -ms-flex: 0 0 30%;
                flex: 0 0 30%;
				padding-top:10px;
			}
			dd
			{
                -ms-flex: 1 1 20%;
                flex: 1 1 20%;
				padding-left:30px;
				padding-bottom:10px;
				vertical-align:top;
				padding-top:10px;
			}
		}
	}
}
