#wrapper-cookie {
    .cookie-identifier.fixed {
        display: block;
        position: fixed;
        z-index: 999;
        width: 100%;
        background: $color-primary;
        color: $color-white;

        .cookie-flex {
            display: flex;
            padding: 24px 0;
            justify-content: space-between;

            @media screen and (max-width: $media-aem-tablet-max) {
                padding: 24px 48px;
            }

            @media screen and (max-width: $media-sm-max) {
                padding: 24px 20px;
            }
        }

        .cookie-message {
            > * {
                color: $color-white;
                font-family: 'graphik-regular', Arial, sans-serif;
                font-size: 16px;
                margin: 0;

                @media screen and (max-width: $media-sm-max) {
                    font-size: 14px;
                }
            }

            a {
                color: $color-white;
                text-decoration: underline;
            }
        }

        #dismiss-cookie {
            flex: 0 1 auto;
            align-self: flex-start;
            padding: 0;
            width: auto;

            .cookie-close {
                display: flex;
                padding: 10px 0;

                &:before,
                &:after {
                    content: '';
                    box-sizing: content-box;
                    display: inline-block;
                    width: 23.26px;
                    height: 1.5px;
                    border-radius: 2px;
                    background-color: #fff;
                }
                &:before {
                    transform: translateX(27px) rotate(45deg);
                }
    
                &:after {
                    transform: translateX(4px) rotate(-45deg);
                }
            }
        }
    }
}