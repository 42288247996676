.error-page {

    h2, h3, p {
        font-family: 'graphik-regular', Arial, sans-serif;
        line-height: 36.8px;
        margin-bottom: 20px;
        letter-spacing: normal;
    }

    .error-content {
        margin: 50px 0 80px;
        box-sizing: border-box;
        
        @media only screen and (max-width: $media-aem-tablet-max) {
            padding: 0 48px;
        }
    
        @media only screen and (max-width: $media-sm-max) {
            padding: 0 20px;
        }

        @media only screen and (min-width: $media-md-min) {
            display: flex;
            justify-content: space-between;
        }

        .error-title {
            flex: 0 0 40%;
            box-sizing: border-box;

            @media only screen and (min-width: $media-md-min) {
                padding-right: 32px;
            }

            @media only screen and (min-width: $media-aem-desktop-min) {
                padding-right: 32px;
            }

            h2 {
                font-size: 40px;
                font-family: 'InvescoEditor', Arial, sans-serif;
                line-height: 52px;
            }
        }

        .error-description {
            flex: 1 0 60%;

            h3 {
                font-size: 32px;
                margin-bottom: 38px;
            }

            p {
                font-size: 16px;
                line-height: 18.4px;
                font-weight: 100;
            }

            a {
                text-decoration: underline;
            }
        }
    }
}