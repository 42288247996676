header {
	margin-top:100px;
	min-height: 120px;
	padding-top: 40px;
	padding-bottom: 0px;	
	.nav-buttons {
		margin-bottom: 20px;
		.nav-button
		{
			margin-left:0px;
			margin-right:10px;
		}
	}

	h1 {
		color: $color-primary;
		font-size: 30px;
		overflow: visible;
		white-space: normal;
		text-overflow: clip;
	}
}

@media screen and (min-width: $media-min-width-tablet) {
	.header-product {
		.brand {
			font-size: 25px;
			line-height: 25px;
		}
	}

	header {
		h1 {
			font-size: 45px;
		}
	}
}

@media only screen and (min-width: $media-min-width-desktop) {
	header {
		margin-top:0px;
		.nav-buttons {
			float:right;
			.nav-button
			{
				margin-right:0px;
				margin-left:10px;
				&:last-child
				{
					margin-right:2px;
				}
			}
		}
		h1
		{
			margin-bottom:10px;
		}
		h1,p {
			width: 70%;
		}
		h3 {
			margin:0;
		}
		&.header-article {
			h1 {
				width: 100%;
			}
			margin-bottom: 20px;
		}
	}
}
