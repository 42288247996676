$_icon-disc-icon-maxwidth: 110px;

.icon-disc {
    display:inline-block;
    width: $_icon-disc-icon-maxwidth;
    text-align: center;

    h1, h2, h3, h4 {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &-heading {
    font-family: $font-primary-bold;
    font-size: 18px;
    line-height: 24px;
    
    }
    p {
	margin: 1em 0;
    }
    &-heading + p {
	margin-top: 0;
    }
    &-descriptor {
        min-height: 130px;
        border-left: 1px solid $color-04;
        text-align: left;

        p {
            padding: 0 8px;
            color: $color-2748;
        }
    }
    &-icon, &-secondary-icon {
        height: $_icon-disc-icon-maxwidth;
        width: $_icon-disc-icon-maxwidth;
        border-radius: 50%;
        margin-right: 20px;
        span {
            color: $color-white;
            font-size:48px;
            line-height:$_icon-disc-icon-maxwidth;
            height: $_icon-disc-icon-maxwidth;
        }
        span:before {
            width:$_icon-disc-icon-maxwidth;
            display:inline-block;
        }
        img{
            width: 100%;
            padding: 5px 0;
        }
        &-text{
            color: $color-white;
            line-height:$_icon-disc-icon-maxwidth;
            height: $_icon-disc-icon-maxwidth;
            font-size: 15px;
        }
    }
    &-secondary
    {
        float:left;
        width:25%;
        &-icon-description
        {
            p:first-child
            {
                margin-bottom:0px;
            }
            padding-right:15px;
        }
        &-icon
        {
            text-align:center;
            margin-bottom:20px;
            width:60px;
            height:60px;
            span
            {
                font-size: 30px;
                line-height:60px;
                &:before
                {
                    width:65px;
                }
            }
        }
    }
}

.icon-disc-row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: start;
	justify-content: flex-start;

	.icon-disc {
		width: auto;
		text-align: initial;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		margin-bottom: 20px;
		padding: 0 10px;
		box-sizing: border-box;
	}

	.icon-disc-icon {
		margin: 0;
		text-align: center;
		width: 100%;
		max-width: $_icon-disc-icon-maxwidth;
		height: 100%;
		max-height: $_icon-disc-icon-maxwidth;
	}

	@media only screen and (min-width: $media-min-width-mobile) {
		.icon-disc {
			-ms-flex: 0 0 calc(100% / 3);
			flex-basis: calc(100% / 3);
		}
	}

	@media only screen and (min-width: $media-min-width-desktop) {
		.icon-disc {
			-ms-flex: 0 0 20%;
			flex-basis: 20%;
		}
	}
}

@media only screen and (max-width: $media-max-width-mobile)
{
	.icon-disc {
		&-secondary {
			width:100%;
			margin-bottom:30px;
			float:none;
			vertical-align:top;
		}
	}
}
