.careers-page
{
	.content-image
	{
		overflow:initial;
	}
	.careers-banner
	{
		background-color:$color-link;
		padding:20px;
		margin-bottom:40px;
		h2
		{
			color:white;
			font-family: $font-primary;
			margin-bottom:20px;
		}
		.button-secondary
		{
			margin-bottom:0px;
		}
	}
	.same-line
	{
		&:first-child
		{
			margin-top:30px;
		}
		margin-top:10px;
		div, p
		{
			display:inline-block;
			vertical-align:top;
		}
		div
		{
			width:15%; 
			margin-right:10px;
		}
		p
		{
			width:75%;
		}
	}
	#margin-top, #margin-top1, #margin-top2
	{
		margin-top:30px;
	}
	#font-primary h3
	{ 
		font-family:$font-primary;
	}
	#health-and-wellbeing, #getting-more, #achieving-financial-goals
	{
		margin-bottom:40px;
	}
	.mobile-hide
	{
		display:none;
	}
	#health-and-wellbeing, #getting-more, #achieving-financial-goals, #henley, #henley2
	{
		ul
		{
			margin:0px;
			padding:0px;
			margin-top:30px;
		 	li
		 	{
		 		@extend .icon-tick;
		 		padding-bottom:15px;
		 		border-bottom: 1px solid $color-02;
		 		margin-bottom:20px;
		 		&:before
		 		{
			 		border: 1px solid black;
			 		border-radius:10px;
			 		font-size:15px;
			 		padding:2px;
			 		font-family:$font-icons;
			 		margin-right:10px;
		 		}
		 		list-style-type:none;
		 		list-style-image:none;
		 	}
		}
	}
	#health-and-wellbeing h2
	{
		@extend .color-font-226;
	}
	#getting-more h2
	{
		@extend .color-font-253;
	}
	#achieving-financial-goals h2
	{
		@extend .color-font-266;
	}
}

@media only screen and (min-width: $media-min-width-tablet)
{
	.careers-page
	{
		.careers-banner
		{
			padding:0px;
			padding-top:20px;
			padding-bottom:20px;
			.button-secondary
			{
				margin-right:20px;
				margin-top:10px;
			    float: right;		
			}
			h2
			{ 
				margin-bottom:0px;
				padding-left:20px;
			}
		}		
	}
}

@media only screen and (min-width: $media-min-width-desktop)
{
	.careers-page
	{
		.mobile-hide
		{
			display:block;
		}
		#health-and-wellbeing h2, #getting-more h2, #achieving-financial-goals h2
		{
			min-height:70px;
		}
		#health-and-wellbeing, #getting-more, #achieving-financial-goals
		{
			margin-bottom:0px;
		}
	}
}
