.figure-white {
    background-color: $color-white;
}

.figure-scrollable {
    @media only screen and (max-width: $media-min-width-mobile) {
        overflow: auto;
        img {
            max-width: none;
        }
    }
}