@keyframes listAnim {
    from {
        margin-left: -30px;
        opacity: 0;
    }
    to {
        margin-left: 0;
        opacity: 1;
    }
}

@media screen and (max-width: $media-aem-tablet-max) {
    .js-nav-active {
        overflow: hidden;
    
        .navigation-primary {

            &.navigation-blue,
            &.navigation-navyBlue,
            &.navigation-midnightBlue {
                background: $color-white;
            }

            .main-logo {
                display: none;
            }

            .inverted-logo {
                display: block;
            }
    
            .navigation-main {
                display: flex;
                overflow: auto;
            }

            .navigation-main-items {
                .navigation-main-item {
                    &.is-selected {
                        > .navigation-mega {
                            display: block;
                        }
        
                        a {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
    
            .navigation-burger {
                .burger-menu {
                    &.menu-open {
                        display: none;
                    }
        
                    &.menu-close {
                        display: block;
                        right: -2px;
                    }
                }
            }

            .navigation-search-mobile {
                .search-mobile-button {
                    background: url("../../joint-state/images/search--blue.svg") no-repeat;
                }
            }
        }
    }
}

.navigation-padding {
    padding-top: 103px;

    @media screen and (max-width: $media-aem-tablet-max) {
        padding-top: 79px;
    }

    @media screen and (max-width: $media-sm-max) {
        padding-top: 74px;
    }

    &.is-edit {
        padding-top: 0;
    }
}

.js-no-cookie {
    .navigation-primary {
        @media screen and (max-width: $media-aem-tablet-max) {
            top: 0 !important;
        }
    }
}

.navigation-primary {
    width: 100%;
    position: fixed;
    top: 40px;
    z-index: 1001;
    transition: background .3s linear;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.10);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.10);

    h3,
    a,
    p {
        font-family: 'graphik-regular', Arial, sans-serif;
        font-size: 16px;
    }

    &.navigation-white {
        background: $color-white;

        .navigation-main-items {
            .navigation-main-item {
                .navigation-link {
                    a,
                    .navigation-label {
                        color: $color-primary;
                    }
                }

                .navLink__arrow {
                    &::before {
                        border-color: $color-primary;
                    }
                }
            }
        }

        .navigation-burger {
            .burger-menu {
                &:before,
                &:after,
                hr {
                    border-color: $color-link-blue;
                }
            }
        }
    }

    &.navigation-blue {
        background: $color-link-blue;

        .navigation-header {
            input:-webkit-autofill {
                box-shadow: 0 0 0 1000px $color-link-blue inset;
            }
        }
    }

    &.navigation-navyBlue {
        background: $color-navy-blue;

        .navigation-header {
            input:-webkit-autofill {
                box-shadow: 0 0 0 1000px $color-navy-blue inset;
            }
        }
    }

    &.navigation-midnightBlue {
        background: $color-midnight-blue;

        .navigation-header {
            input:-webkit-autofill {
                box-shadow: 0 0 0 1000px $color-midnight-blue inset;
            }
        }
    }    

    &.navigation-blue,
    &.navigation-navyBlue,
    &.navigation-midnightBlue {
        .navigation-header {
            .navigation-header__searchBtn {
                background-image: url("../../joint-state/images/search--light.svg");
                    
                @media screen and (max-width: $media-aem-tablet-max) {
                    background-image: url("../../joint-state/images/search--black.svg");
                }
            }

            input:-webkit-autofill {
                -webkit-text-fill-color: $color-white;
                transition: all ease-in-out 0.3s;

                @media screen and (max-width: $media-aem-tablet-max) {                
                    -webkit-text-fill-color: $color-primary;
                    box-shadow: 0 0 0 1000px $color-white inset;
                }
            }
        }
    }

    &.is-edit {
        position: initial;

        .navigation-container {
            display: block;
        }

        .navigation-main {
            display: block;

            .navigation-main-items {
                > li {
                    float: none;
                }
            }
        }
    }

    .main-logo {
        display: block;
    }

    .inverted-logo {
        display: none;
    }

    .main-logo,
    .inverted-logo {
        height: 28px;
        min-height: inherit;
        width: auto;
    }

    &.is-active {
        background: $color-white;

        .main-logo {
            display: none;
        }

        .inverted-logo {
            display: block;
        }

        .navigation-main-items {
            .navigation-main-item {
                .navigation-link {
                    a,
                    .navigation-label {
                        color: $color-primary;
                    }

                    .navLink__arrow {
                        &::before {
                            border-color: $color-primary;
                        }
                    }
                }
            }
        }

        .navigation-burger {
            .burger-menu {
                &:before,
                &:after,
                hr {
                    border-color: $color-link-blue;
                }
            }
        }

        .navigation-mega {
            @media screen and (max-width: $media-aem-tablet-max) {
                background-color: $color-white;
            }
        }
    }

    &.is-active-desktop {
        @media screen and (min-width: $media-aem-desktop-min) {
            background-color: $color-white;

            .navigation-main-items {
                .navigation-main-item {
                    .navigation-link {
                        a,
                        .navigation-label {
                            color: $color-primary;
                        }

                        .navLink__arrow {
                            &::before {
                                border-color: $color-primary;
                            }
                        }
                    }
                }
            }

            .main-logo {
                display: none;
            }

            .inverted-logo {
                display: block;
            }
        }
    }

    &.navigation-white,
    &.is-active,
    &.is-active-desktop {
        @media screen and (min-width: $media-aem-desktop-min) {

            .menu-style-1 {
                .navigation-mega {
                    top: 103px;
                }

                .navigation-main-items {
                    .navigation-main-item {

                        &.navigation-main-item-parent {
                            .navigation-link::before {
                                content: "";
                                position: absolute;
                                top: 47%;
                                right: 3px;
                                transform: translateY(-50%) rotate(135deg);
                                border-top: 1px solid #000;
                                border-right: 1px solid #000;
                                height: 5px;
                                width: 5px;
                                transition: all ease-in-out 0.3s;
                            }
                            &:hover {
                                .navigation-link {
                                    a.navLink__anchor,
                                    p.navigation-label {
                                        &::after {
                                            width: calc(100% - 16px);
                                        }
                                    }
                                }
                            }
                        }
                        &:hover {
                            .navigation-link {
                                &::before {
                                    top: 55%;
                                    right: 3px;
                                    transform: translateY(-50%) rotate(-45deg);
                                    border-color: #0014D2;
                                }
                                a.navLink__anchor,
                                p.navigation-label {
                                    color: #0014D2;
                                    text-decoration: none;
                                    &::after {
                                        display: block;
                                        position: absolute;
                                        content: "";
                                        border: none;
                                        width: calc(100% - 32px);
                                        height: 3px;
                                        background-color: #0014D2;
                                        bottom: -23px;
                                        left: 16px;
                                        transform: none;
                                    }
                                }
                            }
                        }

                    }
                    
                }
            }
        }
    }
    
    .navigation-search-mobile {
        display: none;

        .search-mobile-button {
            background: url("../../joint-state/images/search--light.svg") no-repeat;
            width: 20px;
            height: 20px;
            position: absolute;
            right: 65px;
            margin-top: 4px;

            @media screen and (min-width: 768px) {
                right: 95px;
                margin-top: 0;
            }
        }
        
        .search-mobile-area {
            display: none;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #fff;
            height: 100vh;
            z-index: 9;
            padding: 20px 15px;
            box-sizing: border-box;

            &.js-mob-search-active {
                display: block;
            }
            .navigation-header {
                padding-right: 10px;
                width: calc(100% - 100px);
                display: inline-block;
                vertical-align: middle;

                .navigation-header__searchBtn {
                    left: 10px;
                    top: 25px;
                }
        
                .navigation-header__search {
                    padding: 12px 10px 12px 40px;
                }
            }

            .search-mobile-close {
                color: #000;
                font-size: 14px;
            }
    
        }

        @media screen and (max-width: $media-aem-tablet-max) {
            display: block;
        }
  
    }

    &.navigation-white,
    &.is-active,
    &.is-active-desktop {
        .navigation-search-mobile {
            .search-mobile-button {
                background: url("../../joint-state/images/search--blue.svg") no-repeat;
                width: 20px;
                height: 20px;
            }
        }
    }

    .navigation-primary-relative {
        position: relative;
    }

    .navigation-container {
        display: flex;
        align-items: center;
        padding-top: 11px;
    }

    .navigation-logo-burger {
        display: flex;

        @media screen and (max-width: $media-aem-tablet-max) {
            justify-content: space-between;
            width: 100%;
            padding: 17px 48px 23px;
        }

        @media screen and (max-width: $media-sm-max) {
            padding: 12px 20px 23px;
        }
    }

    .navigation-logo {
        margin-right: 11px;
        margin-left: 0;

        @media screen and (min-width: $media-aem-desktop-min) {
            margin-bottom: 5px;
        }
    }

    .navigation-burger {
        display: none;

        .burger-menu {
            position: relative;
            width: 29px;
            height: 20px;
            background: transparent;

            &:before,
            &:after {
                position: absolute;
                width: 100%;
                content: '';
                left: 0;
            }

            &:before,
            &:after,
            hr {
                border-top: 2px solid $color-white;
            }

            &:before {
                top: 1px;
            }

            &:after {
                bottom: 1px;
            }

            &.menu-close {
                display: none;
                width: 26px;

                &:before,
                &:after,
                hr {
                    border-color: $color-link-blue;
                }

                hr {
                    display: none;
                }
    
                &:before {
                    top: auto;
                    transform: rotate(45deg);
                }
    
                &:after {
                    bottom: auto;
                    transform: rotate(-45deg);
                }
            }
        }

        @media screen and (max-width: $media-aem-tablet-max) {
            display: block;
        }

        @media screen and (max-width: $media-sm-max) {
            padding-top: 4px;
        }
    }

    .navigation-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;

        @media screen and (max-width: $media-aem-tablet-max) {
            display: none;
            flex-direction: column;
            justify-content: flex-start;
            position: absolute;
            top: 79px;
            width: 100%;
            height: 100vh;
            padding: 34px 12px 50px;
            background: $color-white;
            box-sizing: border-box;
            overflow: hidden;
        }

        @media screen and (max-width: $media-sm-max) {
            top: 74px;
        }
    }

    .navigation-main-items {
        padding: 19px 0 0;
        margin: 0;
        list-style: none;

        @media screen and (max-width: $media-aem-tablet-max) {
            width: calc(83.33333% - 44px);
            order: 2;
            padding: 58px 0 40px;
        }
        
        @media screen and (max-width: $media-sm-max) {
            width: calc(100% - 76px);
            padding: 32px 0 26px;
        }

        &:after {
            content: "";
            clear: both;
            display: table;
        }

        .navigation-main-item {
            list-style-image: none;
            float: left;
            list-style: none;
            margin-bottom: 0;
            padding-bottom: 23px;

            @media screen and (max-width: $media-aem-tablet-max) {
                display: none;
                padding-bottom: 0;

                &.list-active {
                    display: block;
                    float: none;
                    animation: listAnim ease-in-out 0.3s;
                }
            }

            i {
                display: none;
            }

            .navigation-link {
                cursor: pointer;
                position: relative;
                
                a,
                .navigation-label {
                    display: block;
                    padding: 14px 16px;
                    color: $color-white;
                    font-family: 'graphik-regular', Arial, sans-serif;
                    margin-bottom: 0;
                    line-height: normal;
                    font-weight: 500;

                    @media screen and (max-width: $media-aem-tablet-max) {
                        display: inline-block;
                        color: $color-primary;
                        font-size: 24px;
                        padding: 20px 0 21px;
                        font-family: 'graphik-medium', Arial, sans-serif;
                        width: 100%;
                    }

                    @media screen and (max-width: $media-sm-max) {
                        font-size: 20px;
                        padding: 16px 0;
                        line-height: 28px;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                .navigation-label {
                    cursor: default;
                }

                .navLink__arrow {
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 100%;
                    width: 15px;
                    display: none;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%) rotate(135deg);
                        border-top: 2px solid $color-white;
                        border-right: 2px solid $color-white;
                        height: 5px;
                        width: 5px;
                        transition: all ease-in-out 0.3s;

                        @media screen and (max-width: $media-aem-tablet-max) {
                            transform: scale(0.85) translateY(-50%) rotate(45deg);
                            border-top: 2px solid $color-primary;
                            border-right: 2px solid $color-primary;
                            height: 10px;
                            width: 10px;
                            left: 35%;
                            top: 50%;
                        }
                    }

                    @media screen and (max-width: $media-aem-tablet-max) {
                        height: 44px;
                        width: 44px;
                        background: $color-light-gray;
                    }
                }
            }

            &:hover {
                .navLink__arrow {    
                    &::before {
                        top: 54%;
                        transform: translateY(-50%) rotate(-45deg);

                        @media screen and (max-width: $media-aem-tablet-max) {
                            top: 50%;
                            transform: scale(0.85) translateY(-50%) rotate(45deg);
                        }
                    }
                }

                .navigation-link {
                    & > a {
                        text-decoration: underline;

                        @media screen and (max-width: $media-aem-tablet-max) {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        
        .navigation-main-item-parent {
            i {
                @media screen and (max-width: $media-aem-tablet-max) {
                    display: inline-block;
                    font-size: 30px;
                    position: relative;
                    top: 2px;
                    margin-left: 10px;
                }
            }

            .navigation-link {
                a,
                .navigation-label {
                    &:after {
                        display: none;
                        position: absolute;
                        left: 50%;
                        bottom: -24px;
                        transform: translateX(-50%);
                        height: 0;
                        width: 0;
                        border-style: solid;
                        border-color: transparent transparent $color-light-gray transparent;
                        border-width: 12px;
                        pointer-events: none;
                        z-index: 2;
                        content: '';
    
                        @media screen and (max-width: $media-aem-tablet-max) {
                            display: none;
                        }
                    }
                }

                .navLink__arrow {
                    @media screen and (max-width: $media-aem-tablet-max) {
                        display: block;
                    }
                }
            }

            @media screen and (min-width: $media-aem-desktop-min) {
                &:hover {
                    > .navigation-mega {
                        display: block;
                    }

                    .navigation-link {
                        a,
                        .navigation-label {
                            &:after {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

