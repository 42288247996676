body.components {
	pre {
		clear: both;
		margin:20px 0;
		background-color: $color-01;
	    white-space: pre;  
	    white-space: -moz-pre;  
	    white-space: -pre;    
	    white-space: -o-pre;   
	    word-wrap: nowrap;  
	    -moz-tab-size: 4;
	    -o-tab-size: 4;
	    -webkit-tab-size:4;
	    tab-size: 4;
	    padding:10px;
		overflow:auto; 
	    display:block;
	    color:black;
		font-family: "Courier New", Courier, monospace;
	}
}

.nav-button-demo
{
	&-grey
	{
		background-color:$color-04;
	}
	&-blue
	{
		background-color:$color-link;
	}	
	margin-bottom:10px;
	.nav-button
	{
		padding:10px;
	}
}