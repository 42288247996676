.literature-section {
	&.panel {
		margin-bottom: 0;
	}
	&.panel-thin-section {
		border-top: none;
	}
}

.literature-section-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	&.has-link {
		min-height: 80px;
	}
}

.literature-section-header-title {
	padding-left: 12px;
}