.section-header {
    border-top: 12px solid $color-primary;
    margin: 60px auto;
    padding-top: 10px;

    &-title {
        float: left;
        width: 37%;

        h2 {
            font-size: 45px;
            line-height: 45px;
            margin-top: 0;
        }

        @media screen and (max-width: $media-max-width-mobile) {
            width: 100%;
            float: none;
        }
    }
    &-text {
        float: right;
        width: 60%;
        @media screen and (max-width: $media-max-width-mobile) {
            width: 100%;
            float: none;
        }
    }
}
