.row-navmain
{
	.logo-print
	{
		display: none;
	}
}
#navmain-search{
	&:focus{
		background-color: rgba(255,255,255,0.15);
	}
}
.navmain //cant xtend icons in media query
{
	&-sub
	{
		&-expanded
		{
			&:after
			{
				content:"";
			}
			&:before
			{
				border:none !important;
			}
		}
		&-collapsed
		{
			&:after
			{
				@include icon-down-before;
			}
		}
	}
	ul {
		padding: 0;
		margin: 0;
		li {
			list-style-image: none;
			&:last-child
			{
				margin-bottom:0px;
			}
			&.searchbox .icon-search
			{
				top:10px;
			}
		}
	}
}
@media only screen and (max-width: $media-min-width-desktop) {
	.row-navmain
	{
		height:0px;
		.logo-desktop
		{
			display: none;
		}
	}
	.navmain-panel-open
	{
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.navmain
	{
		&-searchbox
		{
			display:none;
		}
		&-greyout
		{
			opacity:0.25;
			li
			{
				cursor:initial !important;
			}
			a
			{
				cursor:initial;
				&:hover
				{
					text-decoration:none;
				}
			}
		}
		@include slideinslideout;
			width:320px;
			right:-320px;
			height:auto;
			min-height:100%;
			height:100%;
			overflow-y:auto;
			overflow-x:hidden;
			-webkit-overflow-scrolling:auto;
			-moz-overflow-scrolling:auto;
			z-index:1000;
			top:0px;
			background-color:$color-04;
			position:fixed;
		&-open
		{
			right:0px;
		}
		ul
		{
			font-size: 15px;
			color: $color-03;
			padding-left:10px;
			padding-right:10px;
			li
			{
				cursor:pointer;
				list-style-type:none;
				margin-bottom:0px;
				a
				{
					color:white;
					display:inline-block;
					padding-top:20px;
					padding-bottom:20px;
					width:290px;
					padding-left:10px;
				}
				&.navmain-sub
				{
					position:relative;
					padding-bottom:0px;
					&:after
					{
						color:white;
						position:absolute;
						right:10px;
						top:17px;
						font-family:$font-icons;
						padding-top:4px;
					}
					&:before
					{
						position:absolute;
						content:"";
						width:25px;
						height:25px;
						border: 1px solid white;
						border-radius: 25px;
						top:17px;
						right:4px;
					}
					&>a
					{
						&~ul
						{
							display:none;
						}
					}
					ul
					{
						li
						{
							border-top:none;
							a
							{
								padding-left:0px;
							}
						}
					}
					&-audience
					{
						border-top:2px solid white;
						border-bottom:1px solid white;
						&>a
						{
							background-color:black;
							text-decoration:none;
						}
					}
				}
			}
		}
		&-mobile-buttons
		{
			width: 320px;
			height: auto;
			overflow:hidden;
			margin-bottom:15px;
			&>a.nav-button, .search-container
			{
				float:right;
				margin-top:15px;
				margin-bottom:15px;
			}
			.nav-button
			{
				margin-left:5px;
			}
			.search-container
			{
				&-expanded
				{
					@include expandwidth;
					width:240px !important;
					&~.nav-button, .nav-button span
					{
						display:none;
					}
					input
					{
						background-color:$color-03 !important;
					}
				}
				position:relative;
				width:50px;

				margin-left:10px;
				.nav-mobile-search
				{
					width:100%;
					height:50px;
					padding-left:10px;
					margin-top:7px;
					border-radius:35px;
					color:white;
					border:none;
					font: 400 15px/20px InvescoInterstateLight,Verdana,Arial;
					background-color:transparent;
					outline:none;
					-webkit-appearance:textfield;
				}
				.nav-button
				{
					position:absolute;
					margin-left:0px;
					right:0px;
					top:0px;
				}
			}

		}
	}
}

@media only screen and (min-width: $media-min-width-desktop) {
	.nav-background {
		background: #0014D2;
		height: 135px;
		width: 100%;
		left: 0;
		position: absolute;
	}

	.row-navmain {
		height: 120px;

		.icon,
		[class^="icon-"],
		[class*=" icon-"] {
			&:not(.status-indicator):not(.editorIcon) {
				&:before {
					line-height: 0.6;
				}
			}
		}

		.logo {
			margin-top: 20px;
		}
	}
	.row-navmain
	{
		height: auto;

		.logo-desktop
		{
			display: block;
			width:220px;
			height:80px;
			background: transparent url("../images/invesco_singleline_white.png") no-repeat center;
			background-size: 200px auto;
			margin-top: 10px;
		}
		&.inverted-nav
		{
			.logo-desktop
			{
				background: transparent url("../images/invesco_singleline_cyan.png") no-repeat center;
				background-size: 200px auto;
			}
		}

		&.relative-nav {
			position: relative;
			z-index: 1;
		}

		.navmain-searchbox {
			position: absolute;
			right: 0;
			top: 30px;
			z-index: 1;

			input {
				transition: all ease-in-out 0.3s;
				float: right;
				position: relative;
				z-index: 2;
				padding-right: 45px;

				&:focus {
					width: 300px;
				}
			}

			.autocomplete-results-list {
				z-index: 1;
			}

			a {
				color: #fff;
				padding: 10px 0;
				display: block;
				width: 100%;
			}

			.searchResults {
				a {
					padding: 10px;
				}
			}
		}
	}

	.relative-container-nav {
		position: relative;
	}

	.blue-themed-nav {
		.navmain-searchbox {
			input {
				background: rgba(255, 255, 255, 0.5);
				border: none;
				color: #fff;

				&::placeholder {
					color: #fff;
				}
			}

			.icon-search {
				color: #fff;
			}
		}
	}

	.navmain
	{
		&-mobile-buttons, &-button.icon-menu.nav-button
		{
			display:none;
		}

		ul
		{
			display:table;
			width:100%;
			border-spacing:2px;
			margin:0px;
			padding:0px;
			li.navmain-navitem
			{
				display:table-cell;
				vertical-align: top;
				position:relative;
				min-width: 85px;
				&:before
				{
					content:"";
				}
				&.active
				{
					border-width: 2px;
				}
                > a {
                    border-top: 1px solid white;
                }
                a
                {
					color:white;
					padding:10px;
					display:block;
				}
				&.navmain-sub
				{
					&-expanded, &-collapsed
					{
						&:after
						{
							content:"" !important;
						}
					}
					ul
					{
						display:none;
					}
					&:hover
					{
						background-color:black;
						ul
						{
							background-color:black;
							position:absolute;
							width:180px;
							min-width:100%;
							display:block;
							z-index:1000;
							li
							{
								&:before
								{
									content:"";
								}
								width:100%;
								display:block;
								border-top:none;
								border-bottom:1px solid white;
								margin-bottom:0px;

								&:first-child{
									border-top:1px solid white;
								}
								a
								{
									@include backgroundcolor;
									&:hover
									{
										@include backgroundcolor;
										color:black;
										background-color: $color-01;
									}
								}
							}
						}
					}
				}
				&.navmain-searchbox
				{
					@include expandwidth;
					border-top:0px;
					width:150px;
					::-webkit-input-placeholder {
						color:white;
					}
					:-moz-placeholder {
					   color: white;
					}
					::-moz-placeholder {
					   color:white;
					}
					:-ms-input-placeholder {
					   color:white;
					}
					:placeholder-shown {
					  color:white;
					}
					input
					{
						background-color:rgba(255, 255, 255, 0.15);
						color:white;
						border:none;
						outline:none;
						position:absolute;
						z-index:45;
						width:100%;
						-webkit-appearance:textfield;
						top:0px;
						right:0px;
						min-width:0px;
					}
					label
					{
						color:white;
						right:6px;
						z-index: 100;
						cursor:pointer;
					}
					&-expanded
					{
						@include expandwidth;
						width:300px;
					}
				}
			}
			.navmain-sub-audience
			{
				display:none;
			}
		}

	}

	.inverted-nav {
		.navmain {
            ul li.navmain-navitem > a {
				border-color: #000;
				color: #000;
			}
			ul li.navmain-navitem.navmain-searchbox {
				input {
					border: 1px solid #ebebeb;
					color:black;
				}
				input.autocomplete-active, input.autocomplete-active ~ label
				{
					color:white;
				}
				label {
					color: #000;
				}

				::-webkit-input-placeholder {
					color: #000;
				}
				:-moz-placeholder {
				   color: #000;
				}
				::-moz-placeholder {
				   color: #000;
				}
				:-ms-input-placeholder {
				   color: #000;
				}
				:placeholder-shown {
				  color: #000;
				}
			}
			ul li.navmain-navitem.navmain-sub:hover a {
				color: #fff;
			}
		}
	}
}

@media only screen and (min-width: $media-min-width-desktop) {
  .perpetual-theme {
	.navmain {
	  .logo-desktop
	  {
		background: transparent url("../images/ip_stacked_white.png") no-repeat;
		background-size: 100px 100px;
	  }
	}
  }
}
