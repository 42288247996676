.la {
    font-size: 1rem;
}

.chat-icon {
    pointer-events: none;
}

.chat-container {
    position: fixed;
    bottom: 0;
    right: 20px;
    width: $dialog-width;
    padding: 1.25em;
    background-color: #fff;
    border: 1px solid $color-2748;
    border-bottom: 0;
    border-radius: 1em 1em 0 0;
    box-shadow: 0 0 1.25em 0 rgba($color-primary, .25);
    z-index: 101;
}

.chat-container-close {
    position: absolute;
    top: .5em;
    right: .5em;
    cursor: pointer;
}

.chat-trigger {
    font-size: 1em;
    position: fixed;
    bottom: 1.25em;
    right: 20px;
    width: 4em;
    height: 4em;
    background-color: $color-2748;
    border-width: 2px;
    border-radius: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    box-shadow: 0 .25em .5em 0 rgba($color-primary, .5);
    cursor: pointer;
    z-index: 100;

    .chat-icon {
        color: #ffffff;
    }
}

// MAIN NAV
.livechat {
    text-align: right;

    button {
        display: inline-block !important;
        margin: 0;
    }
}

// PRO-ACTIVE POP-UP
.la-popup {
    width: 60vw;
    background: $color-white;
    border-radius: $la-popup-border-radius;
    box-shadow: 0 .25em .5em 0 rgba($color-primary, .5);

    > * {
        box-sizing: border-box;
    }

    @media only screen and (min-width: $media-min-width-desktop) {
        width: 30vw;
    }
}
.la-popup__avatar {
    position: absolute;
    top: -(calc($la-popup-avatar-width / 2) + $la-popup-avatar-padding);
    left: calc(50% - (#{$la-popup-avatar-width} / 2) - #{$la-popup-avatar-padding});
    padding: $la-popup-avatar-padding;
    border-radius: $la-popup-avatar-radius;
    background-image: linear-gradient(-45deg, $color-299, $color-226);

    img {
        display: block;
        width: $la-popup-avatar-width;
        border-radius: $la-popup-avatar-radius;
    }
}
.la-popup__close {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: $color-link;
    background-color: transparent;
    border: none;
    position: absolute;
    top: .75em;
    right: .75em;
    font-size: 1.25rem;
    cursor: pointer;
}
.la-popup__close-icon {
    display: block;
    width: 100%;
}
.la-popup__header {
    font-size: 1.25rem;
    width: 100%;
    padding: 1rem;
    padding-top: calc($la-popup-avatar-width / 2) + $la-popup-avatar-padding + 15px;
    border-radius: $la-popup-border-radius $la-popup-border-radius 0 0;
    background-color: $color-01;
    text-align: center;
}
.la-popup__heading {
    font-size: 1.25rem;
    line-height: 1.25;
    font-family: $font-primary-bold;
}
.la-popup__sub-heading {
    font-family: $font-primary;
    display: block;
}
.la-popup__content {
    padding: 2rem;

    .button {
        margin: 0;
        width: 100%;
    }
}
