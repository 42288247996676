.navtabs
{
	border-bottom: 2px solid black;
	padding-bottom:3px;
	&-active
	{
		border-top: 3px solid #0014D2;
    	min-width: 60px;
    	position: absolute;	
	}
	&-title-container
	{
		overflow-x:auto;
		white-space:nowrap;
		&::-webkit-scrollbar 
		{
			display:none;
		}
		-ms-overflow-style: none;
		ul
		{
			margin:0px;
			padding:0px;
			> li
			{
				list-style-image:none;
				list-style-type:none;
				display:inline-block;
				margin-right:10px;
				margin-left:10px;
		    	a
		    	{
				    color: #7c7c7c;	    	
		    	}			
			}
		}
	}
	&-content
	{
		width:100%;
		float:right;
		padding-top:15px;
		display:none;	
	}
}