.article
{
	border-top:2px solid black;
	position:relative;
	margin-bottom:40px;

	h2 {
		padding: 4px 15px 30px 0;

		a::after
		{
			font-family: $font-icons;
			@include icon-right-before;
			color:$color-link;
			cursor:pointer;
			font-size:15px;
			position: absolute;
			top: .25em;
			right: 0;
		}
	}
	&-meta
	{
		padding-left:25%;
		color:$color-03;
		margin-bottom:20px;
		display:none;
		div
		{
			padding-bottom:5px;
			padding-top:5px;
			margin-right:10px;
			&:not(:first-of-type)
			{
				border-top:1px dotted $color-03;
			}
		}
		& + img
		{
			display:none;
		}
	}
	&-summary
	{
		display:none;
	}
	&-expander
	{
	    background-color: white;
	    border: 1px solid #a6a6a6;
	    border-radius: 20px;
	    cursor: pointer;
	    display: block;
	    font-size: 15px;
	    height: 20px;
	    margin: 0 auto -10px;
	    text-align: center;
	    width: 60px;
	    clear:both;
		&::after
		{
			font-family: $font-icons;
			@include icon-down-before;
			color: $color-link;
		}
		&.expanded
		{
			&::after
			{
				@include icon-up-before;
				color: $color-04;
			}
		}
	}
	&-feature {
		border-top:2px solid black;
		margin-bottom:40px;
		&-meta {
			color: $color-03;
			margin-bottom: 40px;
			div {
				display: inline;
				&:not(:last-child):after{
					content:"|";
					padding-right: 2px;
					padding-left: 6px;
				}

			}
		}
		img {
			width: 100%;
		}
	}
}

@media only screen and (min-width: $media-min-width-tablet)
{
	.article
	{
		border-bottom:none;
		overflow:hidden;
		display:block;
		min-height:auto;

		h2
		{
			width:350px;
			float:left;
			padding-right: 0;
		}

		h2 a::after, &:after
		{
			display:none;
		}
		&-summary
		{
			width:220px;
			color:$color-04;
			display:block;
		}
		&-meta
		{
			display:block;
			padding-top:10px;
			padding-left:0px;
			float:right;
			margin-bottom:0;
			width:230px;
			img
			{
				display:block;
	   			max-width: 100%;
	  			height: auto;
	  			max-height:170px;
			}
			& + img
			{
				padding-top:30px;
				max-width:100%;
				height:auto;
				display:block;
			}
		}
		&-expander {
			display: none;
		}
	}
}

@media only screen and (min-width: $media-min-width-desktop)
{
	.article
	{
		h2
		{
			width:520px;
		}
		&-summary
		{
			width:520px;
		}
	}
}
