.home--legacy {
    #wrapper {
        position: relative;
    }

    #wrapper-main {      
        padding-top: 0px;
    }

    .filters-group {
        .filters-clear-all {
            a {
                @media only screen and (max-width: $media-min-width-desktop) {
                    height: auto;
                }
            }
        }
    }

    .header-state {
        display: none;
        min-height: 20px;

        .icon-left {
            font-size: 35px;
            line-height: 25px;
            float: left;
        }
        
        a {
            &.navtop-text {
                pointer-events: none;

                &:hover {
                    text-decoration: none;
                }
            }

            &.navtop-button {
                display: inline-block;
                min-width: 130px;
            }
        }

        @media only screen and (min-width: $media-min-width-desktop) {
            display: block;
            position: relative;
            padding: 20px 0px;
    
            a {
                color: $color-primary;
            }
        }
    }

    .row-header {
        margin-top: 120px;

        @media only screen and (min-width: $media-min-width-desktop) {
            margin-top: 0px;
        }
    }

    header {
        min-height: 0px;

        h1,
        p {
            &:not(.subheading) {
                width: 100%;
            }
        }
        
        @media only screen and (min-width: $media-min-width-desktop) {
            min-height: 198px;
        }
    }
    
    .row-navmain {
        .navmain-mobile-scroll {
            @media only screen and (max-width: $media-max-width-tablet) {
                width: 620px;
            }

            @media only screen and (max-width: $media-min-width-tablet) {
                width: 460px;
            }

            @media only screen and (max-width: $media-min-width-mobile) {
                width: 320px;
            }
        }
    }

    .container {
        .row {
            .columns {
                &.side-area {
                    &:not(.d12) {
                        @media only screen and (min-width: $media-min-width-desktop) {
                            float: right;
                        }
                    }
                }
            }
        }
    }

    .accordion-content {
        .accordion {
            margin-left: 10px;
        }
    }

    &.literature-page {
        .literature-item {
            a {
                color: $color-primary;
            }
        }
    }

    .navmain {
        ul {
            li {
                &.navmain-navitem {
                    &.active {
                        @media only screen and (min-width: $media-min-width-desktop) {
                            border-top: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }

    &.documents-home-page {
        .navmain {
            ul {
                li {
                    &.navmain-navitem {
                        @media only screen and (min-width: $media-min-width-desktop) {
                            border-color: $color-link;
                        }

                        &.active {
                            @media only screen and (min-width: $media-min-width-desktop) {
                                border-color: $color-link;
                                border-top: 1px solid;
                            }
                        }

                        
                        a {
                            @media only screen and (min-width: $media-min-width-desktop) {
                                color: $color-link;
                                border-color: $color-link;
                            }
                        }
                    }
                }
            }
        }
    }
}
