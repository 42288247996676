.feedItem {
	border-bottom: 2px solid $color-primary;
	max-width: 400px;
	margin: 20px 0 20px 160px;
	padding-bottom: 16px;
}

.feedItemPreview {
	border-bottom: 2px solid $color-primary;
	margin-top: 40px;
	max-width: 600px;
}