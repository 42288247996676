.pagination {
	border-top: 2px solid $color-01;
	border-bottom: 2px solid $color-01;
	position: relative;
	a {
		color: $color-primary;
	}
	a:hover {
		color: $color-link;
		text-decoration: none;
	}
	.icon-left {
		position: absolute;
		left: 0px;
		top: 18px;
	}
	.icon-right {
		position: absolute;
		right: 0px;
		top: 18px;
	}
	&-previous {
		width:49%;
		padding-top:10px;
		display: inline-block;
		box-sizing: border-box;
		min-height:70px;
		vertical-align: top;
		border-right: 2px solid $color-01;
		span {
			font-size:20px;
			font-family: $font-primary-bold;
			position: absolute;
			top: 15px;
		    left: 25px;
		}
		p {

			margin-left: 50px;
			margin-top: 5px;
		}
	}
	&-next {
		min-height:70px;
		padding-top:10px;
		width:49%;
		display: inline-block;
		text-align:right;
		box-sizing: border-box;
	    vertical-align: top;
		span {
			font-size:20px;
			font-family: $font-primary-bold;
			position: absolute;
			top: 15px;
		    right: 25px;
		}
		p {

			margin-top: -3px;
			margin-right: 50px;
			margin-top: 5px;
		}
	}
}
.all-articles {
	margin-top: 20px;
	text-align: center;
	font-family: $font-primary-bold;
	span {
		display:inline;
	}
	p {
		display: inline;
	}
}