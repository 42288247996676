.uk-contact-us-page {
    .googleMap
    {
        width:100%;
        height:380px;
        padding-top:20px;
        padding-bottom:20px;
        border:none;
    }

    #iframeForm {
        min-height: 560px;
        transition: opacity .6s;
        transition: height .5s;
    }


    .form-hidden-element {
        opacity: 0;
    }

    .iframe-form-wrap {
        position: relative;
    }

    .form-loading-gif {
        width: 100%;
        transition: opacity .6s;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}


