.iframe-form-wrap {
    position: relative;
    
    #iframeForm {
        min-height: 560px;
        transition: opacity .6s;
        transition: height .5s;
    }
    .form-hidden-element {
        opacity: 0;
    }
            
    .form-loading-gif {
        width: 100%;
        transition: opacity .6s;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}


.contact-iframe-form {
	h2 {
		font-size: 45px;
	    position: relative;
	    display: block;
		padding: 20px 20px 20px 0;
		line-height: 1;
		color: $color-primary;
	}
	
	p:first-of-type {
		margin-top: 20px;		
	}
	
	.contact-item {
		margin-bottom: 10px;
		span {
			font-size: 25px;
			margin-right: 10px;
		}
		p {
			margin: 0 auto;
		    display: inline-block;
		    vertical-align: super;
		    line-height: 36px;
		}
	}
}

