.focus-fund {
    border-top: $color-04 2px solid;
	padding-top: 4px;
	form {
		width: 50%;
		float: right;
	}
	table {
		margin-top: 40px;
		tr {
			border-bottom:$color-02 1px solid;
		}
	}
}