.accordion-group{
    .container {
        width: 100%;
    }
}
.LPMcontainer{
    bottom: 60px !important;
    position: fixed !important;
    top: inherit !important;
}

@media print {
	#designstudio-button,
	.LPMcontainer{
		display: none !important;
	}
}

div.mgnlEditorBar.area ~ div.mgnlPlaceholder, div.mgnlEditorBar.area ~ * div.mgnlPlaceholder {
    pointer-events: none;
}