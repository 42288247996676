.profile {
	margin: 10px 0;
	min-height: 105px;
	&-image {
		@include clearfix;
		border-right: 1px solid $color-02;
		display: inline-block;
		height: 80px;
		padding-right: 24px;
		max-width: 74px;

		img {
			border-radius: 100%;
			width: 100%;
			margin: 3px;
		}

		&.white {
			background: url(../images/profile-borders/white@1x.png);
			background-repeat: no-repeat;
			background-size: contain;
		}
		&.pink {
			background: url(../images/profile-borders/pink@1x.png);
			background-repeat: no-repeat;
			background-size: contain;
		}
		&.blue {
			background: url(../images/profile-borders/blue@1x.png);
			background-repeat: no-repeat;
			background-size: contain;
		}
		&.dark-blue {
			background: url(../images/profile-borders/dark_blue@1x.png);
			background-repeat: no-repeat;
			background-size: contain;
		}
		&.purple {
			background: url(../images/profile-borders/purple@1x.png);
			background-repeat: no-repeat;
			background-size: contain;
		}

		&.pink-blue {
			background: url(../images/profile-borders/pink_blue@1x.png);
			background-repeat: no-repeat;
			background-size: contain;
		}
		&.purple-blue {
			background: url(../images/profile-borders/purple_blue@1x.png);
			background-repeat: no-repeat;
			background-size: contain;
		}
		&.green-blue {
			background: url(../images/profile-borders/green_blue@1x.png);
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	&-copy {
		display: inline-block;
		font-size: $font-size;
		font-weight: normal;
		vertical-align: top;
		padding: 6px 0 0 10px;
		max-width: 60%;

		a {
			font-family: InvescoInterstateBold;
			color: $color-link;
		}

		p {
			margin: 0;
		}
	}

	&-full {
		img {
			width: 100%;
		}

		h5 {
			margin-bottom: 0;
		}

		&-link {
			margin-top: 30px;
			display: block;
		}
	}
}

.profile-horizontal {
	.profile-horizontal-image {
		border-right: 1px solid $color-02;
		padding-right: 24px;
		width: 10%;
		min-width: 80px;
		display: inline-block;

		img {
			height: 80px;
			width: 80px;
			border-radius: 100%;
			margin: 3px;
		}
	}

	.profile-copy {
		width: 20%;
		color: $color-04;
		font-weight: 300;
		
		@media only screen and (max-width: $media-max-width-mobile) {
			width: 50%;
		}
	}

	.profile-description {
		padding: 6px 0 0 10px;
		display: inline-block;
		width: 60%;
		font-size: 15px;
		font-weight: 300;
		color: $color-04;
		vertical-align: top;
		
		@media only screen and (max-width: $media-max-width-mobile) {
			margin-top: 8px;
			width: 100%;
		}
	}
	
}

