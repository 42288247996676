.media {
    .hidden {
        opacity: 0;
        visibility: hidden;
    }

    &-audio{
    	border-top: none;
    	padding-bottom: 20px;
    }

    &.media-multiplayer {
        .media-multiplayer-wrap {
            margin-bottom: 0;

            @media screen and (max-width: $media-min-width-tablet) {
                margin-bottom: 12px;
            }
        }

        .switch {
            margin-top: -10px;

            @media screen and (max-width: $media-min-width-tablet) {
                white-space: normal;
                overflow: hidden;
                margin-top: 0;
            }

            &-wrap {
                position: relative;

            }

            &-nav {
                background-color: #f7f7f7;
                color: $color-link;
                bottom: 0;
                cursor: pointer;
                position: absolute;
                top: 10px;
                width: 40px;
                height: 70px;
                opacity: 0.8;
                z-index: 10;
                -webkit-transition: opacity 0.7s ease-in-out;
                -moz-transition: opacity 0.7s ease-in-out;
                -o-transition: opacity 0.7s ease-in-out;
                -ms-transition: opacity 0.7s ease-in-out;
                transition: opacity 0.7s ease-in-out;

                span {
                    font-size: 2em;
                }

                .icon {
                    &-left,
                    &-right {
                        &:before {
                            position: absolute;
                            top: 30%;
                        }
                    }
                    &-left {
                        &:before {
                            left: 0;
                        }
                    }
                    &-right {
                        &:before {
                            right: 0;
                        }
                    }
                }

                @media screen and (max-width: $media-min-width-tablet) {
                    display: none !important;
                }
            }

            &-text {
                width: 160px;
                white-space: normal;
                display: inline-block;
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 15px;
                padding-bottom: 15px;
                background-color: $color-01;
                color: $color-primary;
                font-family: InvescoInterstateBold;
                margin-right: 2px;
                margin: 10px 0 0 auto;
                -webkit-transition: margin 0.5s ease, background-color 0.5s ease;
                -moz-transition: margin 0.5s ease, background-color 0.5s ease;
                -o-transition: margin 0.5s ease, background-color 0.5s ease;
                -ms-transition: margin 0.5s ease, background-color 0.5s ease;
                transition: margin 0.5s ease, background-color 0.5s ease;
                vertical-align: top;

                &:hover {
                    background-color: #ccc;
                }

                @media screen and (max-width: $media-min-width-tablet) {
                    width: 50%;
                    float: left;
                    padding: 15px 0 0;
                    background-color: #fff;
                    margin: 0;
                }

                &-wrap {
                    width: 85%;
                }
            }

            &-image {
                width: 50%;
                display: none;
                position: relative;

                @media screen and (max-width: $media-min-width-tablet) {
                    display: inline-block;
                    img {
                        width: 100%;
                    }
                }

                .play {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);  // Firefox 16+, IE 10+, Opera
                    -ms-transform: translate(-50%, -50%);  // IE 9
                    -webkit-transform: translate(-50%, -50%);  // Chrome, Opera 15+, Safari 3.1+

                    .icon-video-content {
                        font-size: 80px;
                        color: $color-01;
                        @media screen and (max-width: $media-min-width-mobile) {
                            font-size: 52px;
                            top: 6px;
                        }
                    }
                }
            }

            a {
                cursor: pointer;
                text-decoration: none;
                display: inline-block;
                margin-left: -2px;

                @media screen and (max-width: $media-min-width-tablet) {
                    margin: 16px auto;
                    display: inline-block;
                    width: 100%;
                    background: #fff;
                    padding: 0;
                    transition: none;
                }

                &.active {
                    .switch-text {
                        background-color: $color-link;
                        color: #fff;
                        position: relative;

                        &:before {
                            border-bottom: 12px solid $color-link;
                            border-left: 12px solid transparent;
                            border-right: 12px solid transparent;
                            border-top-color: inherit;
                            content: "";
                            position: absolute;
                            left: 74px;
                            top: -10px;
                        }

                        @media screen and (max-width: $media-min-width-tablet) {
                            margin: 0;
                            padding: 15px 0 0;
                            background: #fff;
                            color: $color-primary;
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        .overflowed.switch-wrap {
            overflow: hidden;
            margin-top: -10px;
            a {
                margin-top: 10px;
                vertical-align: top;
            }
            .switch {
                margin-bottom: -20px;
                padding-bottom: 10px;
                overflow-x: scroll;
                overflow-y: hidden;
                white-space: nowrap;

                &-nav-right {
                    display: block;
                    right: 0;
                }
            }
        }
    }
    &-lightbox {
        &:hover
        {
            cursor:pointer;
        }
        .copy {
            padding: 20px 10px;
            background: $color-04;
            color: #fff;
        }

        .media-lightbox-image {
            display: block;
            position: relative;

            img {
                width: 100%;
                display: block;
            }

            &-icon {
                position: relative;
				&:hover {
					cursor: pointer;
				}
                &.circle {
                    transform: translate(-50%, -50%);  // Firefox 16+, IE 10+, Opera
                    -ms-transform: translate(-50%, -50%);  // IE 9
                    -webkit-transform: translate(-50%, -50%);  // Chrome, Opera 15+, Safari 3.1+
                    background: $color-04;
                    border-radius: 100%;
                    //height: 60px;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    width: 30%;
                    padding-top: 30%;
                }

                .icon-video-content {
                    position: absolute;
                    color: #fff;
                    font-size: 40px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);  // Firefox 16+, IE 10+, Opera
                    -ms-transform: translate(-50%, -50%);  // IE 9
                    -webkit-transform: translate(-50%, -50%);  // Chrome, Opera 15+, Safari 3.1+

                    @media screen and (max-width: $media-min-width-mobile) {
                        font-size: 40px;
                    }
                }
            }
        }
        &.landscape {
            @include clearfix;

            .media-lightbox-wrap {
                position: relative;
                .media-lightbox-image {
                    width: 60%;
                    @media screen and (max-width: $media-min-width-mobile) {
                        width: 100%;
                    }
                }
            }

            .copy {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 60%;
                padding-right:10px;

                @media screen and (max-width: $media-min-width-mobile) {
                    position: initial;
                    display: block;

                }
            }
        }
        &.lightbox {
            display: none;

            .media-lightbox-popup {
                width: 100%;
                z-index: 100;
                &:before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,.8);
                    overflow: hidden;
                    position: fixed;
                    left:0;
                    top:0;
                    z-index: 101;
                }
                
                &-video {
                    position: fixed;
                    left:0;
                    right:0;
                    top: 10%;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    border: 0;
                    z-index: 105;
                    height: 80vh;
                    width:100%;
                    max-width: 1080px;
                    max-height: 80%;
                    @media screen and (max-width: $media-min-width-mobile) {
                        top: 20%;
                    }

                    .close {
                        background: $color-primary;
                        float: right;
                        margin-bottom: 2px;
                        color: #fff;
                        padding: 8px;

                        .icon-close {
                            cursor: pointer;
                            font-size: 40px;
                            line-height: .6em;

                            &:before {
                                top: 5px;
                            }
                        }
                    }

                    .flexmedia-16-9 {
                        margin-bottom: 0
                    }
                }
            }
        }
    }
}
