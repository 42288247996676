//Desktop Logo Overrides
@media only screen and (min-width: $media-min-width-desktop) {
	.perpetual {
		.logo-desktop {
			background: transparent url("../images/ip_stacked_white.png") no-repeat;
			background-size: 100px 100px;
		}
		.row-navmain {
			&.inverted-nav {
				height: 130px;
				.logo-desktop {
					background: transparent url("../images/ip_stacked_blue.png") no-repeat;
					background-size: 100px 100px;
				}
			}
		}
	}
}
//Mobile Logo Overrides
.home, .home-page
{
	&.perpetual
	{
	   .navmain-mobile-scroll .logo-mobile
		{
			background: transparent url("../images/ip_stacked_blue.png") no-repeat;
			background-size: 100px 100px;
			background-position: 0px 5px;
		}
	}
}

.perpetual
{
	.navmain-mobile-scroll
	{
		.logo-mobile
		{
			background: transparent url("../images/ip_stacked_white.png") no-repeat;
			background-size: 100px 100px;
			background-position: 0px 5px;
		}
	}
}

//Audience Splash Page Overrides
.audience-splash-page {
        &.perpetual {
          .logo-desktop {
              background: transparent url("../images/ip_stacked_blue.png") no-repeat;
              background-size: 120px 120px;
              width:120px;
              height:120px;
          }
      }
}