.quote {

	.credit {
		border-left: 2px solid $color-primary;
		font-size: 12px;
		margin-top: 16px;
		max-width: 170px;
		padding-left: 8px;
		text-align: left;

		.name {
			font-family: $font-primary-bold;
			font-weight: bold;
			display: block;
		}
	}

	&-context {
		padding: 30px 0;
		position: relative;
		text-align: center;
		h4 {
			margin-bottom: 0px;
		}
		&:before {
			background: $color-primary;
			content: "";
			height: 2px;
			left: 50%;
			position: absolute;
			top: 0;
			transform: translateX(-50%);
			width: 40px;
		}
		&:after {
			background: $color-primary;
			bottom: 0;
			content: "";
			height: 2px;
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			width: 40px;
		}
	}

	&-profile {
		text-align: center;

		&-ei {
			position: relative;
			@include clearfix;
			margin: 25px 0;

			.image-wrap {
				float: left;
				width: 20%;
				@media screen and (max-width: $media-max-width-mobile) {
					width: 25%;
				}
				@media screen and (max-width: $media-min-width-mobile) {
					float: none;
					width: 100%;
				}
			}
			.content-wrap {
				float: left;
				width: 80%;
				@media screen and (max-width: $media-max-width-mobile) {
					width: 75%;
				}
				@media screen and (max-width: $media-min-width-mobile) {
					float: none;
					width: 100%;
				}
			}

			img {
				border-radius: 100%;
				margin-left: 40px;
				position: relative;
				z-index: 1;
				@media screen and (max-width: $media-min-width-mobile) {
					margin-bottom: 25px;
				}
			}

			.accent {
				border-radius: 100%;
				height: 70px;
				position: absolute;
				top: 0;
				width: 70px;

				&-line {
					height: 2px;
					position: absolute;
					left: 6px;
					top: 70px;
					transform: rotate(45deg);
					width: 34px;
				}
				&-line:nth-of-type(2) {
					left: 70%;
				}
			}

			&.stacked {
				img {
					margin-bottom: 25px;
				}
				.image-wrap,
				.content-wrap {
					float: none;
					width: 100%;
				}
			}
		}
	}

}
