.steps-process {
	min-height:80px;
	.step {
		border-right: 1px solid $color-02;
		border-bottom: 5px solid $color-link;
		float:left;
		margin-bottom: 20px;
		box-sizing: border-box;
		padding-right:10px;
		color: $color-link;
		position: relative;
		width:70%;
		height:60px;
		a {
			color: inherit;
		}
		span {
			font-size:40px;
			display:inline-block;
			margin-left: 10px;
		}
		.label {
			position:absolute;
		    top: 0px;
		    left: 45px;
		    padding-top: 4px;
		    padding-right: 20px;
		}
	}
	.step:last-child {
		border-right: 0px;
	}
	.complete {
		width: 15%;
		color: $color-primary;
		border-bottom: 5px solid $color-primary;
		p {
			visibility:hidden;
		}
	}
	.inactive {
		width: 15%;
		color: $color-02;
		border-bottom: 5px solid $color-02;
		p {
			visibility:hidden;
		}
	}
	p {
	    display: inline-block;
	    vertical-align: top;
	}
}

@media only screen and (min-width: $media-min-width-tablet)
{
	.steps-process {
	.step {
		border-right: 1px solid $color-02;
		border-bottom: 5px solid $color-link;
		float:left;
		margin-bottom: 20px;
		box-sizing: border-box;
		padding-right:10px;
		color: $color-link;
		position: relative;
		width: 180px;
		a {
			color: inherit;
		}
		span {
			font-size:40px;
			display:inline-block;
			margin-left: 10px;
		}
		.label {
			position:absolute;
		    top: 0px;
		    left: 45px;
		    padding-top: 4px;
		    padding-right: 20px;
		}
	}
	.step:last-child {
		border-right: 0px;
	}
	.complete {
		color: $color-primary;
		border-bottom: 5px solid $color-primary;
			p {
				visibility: visible;
			}
	}
	.inactive {
		color: $color-02;
		border-bottom: 5px solid $color-02;
			p {
				visibility: visible;
			}
	}
	p {
	    display: inline-block;
	    vertical-align: top;
	}
}
}