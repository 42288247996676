.basket {
    vertical-align:top;
    display:inline-block;
    min-width: 170px;
    margin-bottom: 20px;
    position: absolute;
    right: 0;

    &-wrap {
        position: relative;
        height: 60px
    }
    span.icon-shopping-basket {
        font-size:35px;
        width: 40px;
        float: left;
    }

    p {
        margin:0px;
        font-family:InvescoInterstateLight;
        span {
            font-family: InvescoInterstateBold;
        }
    }
}

.literature-basket-page {
    .forms-error-global {
        display: none;
        margin: 15px 0 25px;
    }
}

.literature {
    &-header {
        float:right;
        div {
            display: inline-block;
            font-size: 12px;
            width: 40px;
            color: $color-03;
            vertical-align: bottom;
            height: 32px;
            padding-bottom: 18px;
        }

        &-download {
            padding-right: 25px;
        }

        &-add-basket {
            padding-left: 10px;
            border-left: 1px solid $color-02;
        }
    }
    &-item {
        padding-top: 0px;
        margin-bottom: 20px;
        min-height:60px;
        border-top:1px solid #cccccc;
        position: relative;
        clear: both;
        p {
            padding-top:10px;
            max-width: 65%;
            word-wrap: break-word;
        }
        .meta-items {
            height: 60px;
            position: absolute;
            top:0px;
            right:0px;
            .add-container {
                display:inline-block;
                width:40px;
                padding-left:10px;
                height:60px;
                border-left:1px solid #cccccc;
            }
            .download-container{
                display:inline-block;
                width:40px;
                height:60px;
                a {
                    &:hover {
                        text-decoration: none !important;
                    }
                }
            }
            span.icon-download {
                color:#0014D2;
                background:#ffffff;
                margin-top:10px;
                border: 1px solid #cccccc;
                border-radius: 30px;
                height: 30px;
                width: 30px;
                line-height: 30px;
                display: inline-block;
                color:#0014D2;
            }
            span.icon-download:hover {
                background:#0014D2;
                color:white;
                border: 1px solid #0014D2;
            }
            span.icon-download:before {
                margin-left: 8px;
            }
            span.icon-add {
                color:#0014D2;
                background:#ffffff;
                margin-top:10px;
                border: 1px solid #cccccc;
                border-radius: 30px;
                height: 30px;
                width: 30px;
                line-height: 30px;
                display: inline-block;
                color:#0014D2;
            }
            span.icon-add:hover,span.icon-add:active {
                background:#0014D2;
                border-color: #0014D2;
                color:white;
            }
            span.icon-add:before {
                margin-left: 7px;
                margin-top:-2px;
            }
            span.icon-minus:hover, span.icon-minus.active  {
                background:#0014D2;
                border-color: #0014D2;
                color:white
            }
            span.icon-download:before {
                margin-left: 8px;
            }
            span.icon-minus {
                color:#0014D2;
                background:#ffffff;
                margin-top:10px;
                border: 1px solid #cccccc;
                border-radius: 30px;
                height: 30px;
                width: 30px;
                line-height: 30px;
                display: inline-block;
                color:#0014D2;
            }
            span.icon-minus:before {
                margin-left: 7px;
                margin-top:-2px;
            }
        }
    }
}

#literatureOrderPostForm {
    input[name="postCode"] {
        max-width: 142px;
    }
}

.basket-literature {
    &-header {
        position: relative;
        height: 60px;
        div {
            font-size: 12px;
            color: $color-03;
        }
        &-post {
            position:absolute;
            right:196px;
            top: 10px;
            width: 36px;
        }
        &-quantity {
            position:absolute;
            right:137px;
            top: 10px;
            width: 50px;
        }
        &-download {
            position:absolute;
            right:60px;
            top: 10px;
            border-left: 1px solid $color-02;
            height: 50px;
            width: 56px;
            padding-left: 11px;
        }
        &-add-container {
            width: 40px;
            padding-left: 10px;
            height: 50px;
            border-left: 1px solid $color-02;
            position: absolute;
            right: 0;
            top: 10px;
        }
    }
    &-item {
        padding-top: 0px;
        margin-bottom: 20px;
        min-height:60px;
        border-top:1px solid #cccccc;
        position: relative;
        clear: both;

        p {
            padding-top:10px;
            max-width: 50%;
            word-wrap: break-word;
        }
        .meta-items {
            height: 60px;
            position: absolute;
            top:0px;
            right:0px;
            .forms-checkbox-on input, .forms-checkbox-off input {
                position: absolute;
            }
            label {
                display: inline;
                text-align:center;
                padding-left: 0px;
                margin-left: 0px;
                font-size:13px;
            }
            .post {
                position:absolute;
                right:196px;
                top: 10px;
                width: 38px;

            }
            .quantity {
                padding: 10px 0;
                &-container {
                    position:absolute;
                    right:127px;
                    width: 50px;
                    border-right: 1px solid $color-02;
                    padding-right: 10px;
                }
                input {
                    height:27px;
                    margin-bottom:4px;
                }
            }
            .download {
                border-left:1px solid #cccccc;
                padding: 10px 0 0 8px;
                position:absolute;
                right:60px;
                top: 0;
                width: 58px;
                height: 50px;
                &.no-border {
                    border-width: 0;
                }
            }
            .add-container {
                display:inline-block;
                width:40px;
                padding-left:10px;
                height:60px;
                border-left:1px solid #cccccc;
            }
            .download-container{
                display:inline-block;
                width:40px;
                height:60px;
                display:none;
            }
            span.icon-download {
                color:#0014D2;
                background:#ffffff;
                margin-top:10px;border: 1px solid #cccccc;
                border-radius: 30px;
                height: 30px;
                width: 30px;
                line-height: 30px;
                display: inline-block;
                color:#0014D2;
            }
            span.icon-minus:hover {
                background:#0014D2;
                border:0px;
                color:white
            }
            span.icon-download:before {
                margin-left: 8px;
            }
            span.icon-minus {
                color:#0014D2;
                background:#ffffff;
                margin-top:10px;
                border: 1px solid #cccccc;
                border-radius: 30px;
                height: 30px;
                width: 30px;
                line-height: 30px;
                display: inline-block;
                color:#0014D2;
            }
            span.icon-minus:before {
                margin-left: 7px;
                margin-top:-2px;
            }
        }
    }
}

@media only screen and (min-width: $media-min-width-desktop) {
    .basket-literature {
        &-item {
            padding-top: 0px;
            margin-bottom: 20px;
            min-height:60px;
            border-top:1px solid #cccccc;
            position: relative;
            clear: both;

            p {
                padding-top:10px;
                max-width: 65%;
                word-wrap: break-word;
            }
            .meta-items {
                height: 60px;
                position: absolute;
                top:0px;
                right:0px;
                label {
                    display: inline;
                    text-align:center;
                    padding-left: 0px;
                    margin-left: 0px;
                    font-size:13px;
                }
                .post {
                    position:absolute;
                    right:196px;
                    top: 10px;
                }
                .quantity {
                    input {
                        height:27px;
                        margin-bottom:4px;
                    }
                }
                .download {
                    position:absolute;
                    right:60px;
                    top: 0;
                }
                .add-container {
                    display:inline-block;
                    width:40px;
                    padding-left:10px;
                    height:60px;
                    border-left:1px solid #cccccc;
                }
                .download-container{
                    display:inline-block;
                    width:40px;
                    height:60px;
                    display:none;
                }
                span.icon-download {
                    color:#0014D2;
                    background:#ffffff;
                    margin-top:10px;border: 1px solid #cccccc;
                    border-radius: 30px;
                    height: 30px;
                    width: 30px;
                    line-height: 30px;
                    display: inline-block;
                    color:#0014D2;
                }
                span.icon-minus:hover {
                    background:#0014D2;
                    border:0px;
                    color:white
                }
                span.icon-download:before {
                    margin-left: 8px;
                }
                span.icon-minus {
                    color:#0014D2;
                    background:#ffffff;
                    margin-top:10px;
                    border: 1px solid #cccccc;
                    border-radius: 30px;
                    height: 30px;
                    width: 30px;
                    line-height: 30px;
                    display: inline-block;
                    color:#0014D2;
                }
                span.icon-minus:before {
                    margin-left: 7px;
                    margin-top:-2px;
                }
            }
        }
    }
}

@media only screen and (max-width: $media-min-width-mobile)
{

    .basket-literature-header {
        width: 420px;
    }
    .basket-literature-item {
        width: 460px;
        .meta-items {
            width: 51px;
            margin-right: 40px;
        }

    }

}
