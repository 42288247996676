.primary-footer__grid-list {
    width: 31%;
    margin-bottom: 30px;
    border-bottom: 1px solid $color-white;

    @media screen and (max-width: $media-md-max) {
        width: 48%;
    }

    @media screen and (max-width: $media-sm-max) {
        width: 100%;
    }

    h3 {
        color: $color-white;
        font-size: 15px;
        font-family: $font-primary;
        font-weight: bold;
        letter-spacing: .5px;;
    }

    ul {
        margin: 0 0 30px;
        padding: 0;

        li {
            line-height: 20px;
            margin-bottom: 10px;
        }
    }
}