table {
	width:100%;
	border-collapse: collapse;
	margin-bottom: 40px;
	caption {
		padding-top: 5px;
		margin-top: 20px;
		margin-bottom: 10px;
		font-size: 20px;
		line-height: 25px;
		font-family:$font-primary-bold;
		font-weight: normal;
		text-align:left;
		border-top: $color-primary 1px solid;
	}
	th,td {
		text-align:left;
		vertical-align:top;
		padding: 6px 6px 12px 6px;
		&:first-child
		{
			padding-left:0px;
		}
		&.number {
			text-align: right;
		}
		&.phonefax {
			white-space: nowrap;
			text-align: right;
		}
	}
	th {
		font-family:$font-primary-bold;
		font-weight: normal;
	}

	&.horizontal-border-light,
	.horizontal-border-light & {
		tr {
			border-top:$color-02 1px solid;
		}
	}

	&.horizontal-border-dark,
	.horizontal-border-dark & {

		tr {
			border-top:$color-04 1px solid;
		}
	}

	&.vertical-border,
	.vertical-border & {

		thead th, td {
			border-right:1px solid $color-04;
			text-align:right;
			&.no-border {
				border-right: none;
			}
		}
		thead th:first-child {
			border: none;
			text-align:left;
		}
	}

	&.highlight-row,
	.highlight-row & {

		tbody tr {
			&:hover {
				background: #efefef;
				cursor:pointer;
			}
		}
	}

	&.fundshub,
	.fundshub & {

		thead tr {
			border-top: none;
		}
		td {
			padding-bottom: 10px;
			a {
				display: block;
				color: $color-primary;
				margin-bottom: 6px;
			}
			&:first-child {
				a {
					font-family: $font-primary-bold;
				}
			}
			&:last-child {
				a {
					text-decoration: none;
				}
				span:before {
					float: right;
					display: block;
					width: 40px;
					height: 40px;
					border-radius: 20px;
					color: #0014D2;
					font-size: 25px;
					line-height: 40px;
					text-align: center;
					border: 1px solid #f1f1f1;
					background-color: transparent;
				}
			}
		}
	}

	&.keyfacts,
	.keyfacts & {

		background-color: $color-01;
		tr:first-child {
			border-top: $color-primary 2px solid;
		}
		th,td {
			padding-left: 6px;
		}

		caption {
			border-top: none;
		}
	}

	&.compare
	.compare & {
		th,td {
			padding-left: 6px;
			padding-bottom: 20px;
		}
		thead {
			th {
				text-align: center;
			}
		}
		tbody {
			tr {
				border-top: $color-primary 1px solid;
			}
			th {
				background-color: $color-02;
				width: 60%;
			}
			td {
				text-align: center;
			}
		}
	}
}

.compare {
	th:first-child, table td:first-child {
		padding-left: 10px;
	}
	thead {
		tr {
			border-top: 0px;
			font-size: 20px;
		}
	}
	tbody {
		tr {
			border-top: 1px solid #000;
			height: 60px;
			
		}
		td, th {
			padding: 10px;
			vertical-align: middle;

		}
		th {
			background: $color-05;
		}
	}
}
