.audience-splash-page {
	#wrapper-main {
	  background: none;
	}

	.audience {
		height: auto;
	}

	.icon-wrap {
		background-color: $color-primary;
		border-radius: 50%;
		position: relative;
		width: 27px;
		height: 27px;
		display: inline-block;
		vertical-align: top;
		float: left;

		.icon-warning {
			color: $color-white;
			font-size: 32px;
			position: absolute;
			left: -2px;
			top: -6px;
		}
	}

	.disclaimer {
		display: inline-block;
		color: $color-03;
		margin-left: 20px;
		float: left;
		max-width: 80%;
	}

	.row-audience {
		margin-top: $row-spacing*2;
		margin-bottom: $row-spacing;
		header {
			padding-top: 10px;
			margin-top: 0px;
		}
	}
	.logo-desktop {
		background: transparent url("../images/invesco_singleline_cyan.png") no-repeat center;
		background-size: 200px auto;
		height: 75px;
		width: 200px;
		display:block;
		margin-left: -10px;
	}

	.non-resident {
		margin-top: 30px;
		a,
		i {
			display: inline-block;
		}
		i {
			font-size: 36px;
			vertical-align: middle;
		}
	}
}

@media only screen and (min-width: $media-min-width-desktop) {
	.audience-splash-page {

		#wrapper-main {
		  background: none;
		}

		.audience {
			&-content{
				h2 {
					max-width: none;
				}
			}
			&-terms {
				margin-left: 30px;
			}
		}

		.row-audience {
			margin-top: $row-spacing*4;
			margin-bottom: $row-spacing*2;
			header {
				padding-top: 10px;
				margin-top: 0px;
			}
		}

		.logo-desktop {
			background: transparent url("../images/invesco_singleline_cyan.png") no-repeat center;
			background-size: 200px auto;
			height: 75px;
			width: 200px;
			display:block;
		}
	}
}
