.nav-button {
	text-align: center;
	width:60px;
	display:inline-block;
	margin-left: 10px;
	vertical-align:top;
	line-height: 1.2;

	html:lang(at) &,
	html:lang(fr) &,
	html:lang(de) & {
		width: 80px;
		span{
			display: block;
			margin-top: 4px;
		}
	}

	&-black
	{
		&:before, span
		{
			color:black !important;
		}
	}
	&:before {
		display: inline-block;
		width: 60px;
		height: 60px;
		color: white;
		border-radius: 50%;
		font-size: 32px;
		line-height: 60px;
	}
	
	&.nav-button-icon-large {
		&:before {
			font-size: 40px;
		}
	}

	span
	{
		color: white;
		width: 100%;
		text-align: center;
		font-size: 13px;
		word-wrap:break-word;
		font-family: $font-primary;
	}
	&:hover
	{
		text-decoration: none;
		cursor: pointer;
	}
	&-secondary
	{
		&:before
		{
			border: 1px solid $color-01;
			color: $color-link;
			background-color: transparent;
		}
		span
		{
			color: $color-03;
		}
		&:hover, &-active
		{
			&:before {
				border-color: $color-link;
				background-color: $color-link;
				color: white;
			}
		}
	}
}
