.accordion {
    border-top:2px solid black;
    margin-bottom:20px;
    &-content .accordion {
        margin-right:80px;
    }

&-heading {
    position: relative;
    z-index: 10;
    padding-top: 3px;
    cursor:pointer;
    padding-right:100px;

    &:after {
        @extend .accordion-icon;
        @include icon-down-before;
        color: $color-link;
    }
}

.accordion-heading-disabled {
    cursor: auto;
    min-height: auto;
}

>h2 {
    @extend .accordion-heading;
    min-height:75px;
}
>h3 {
    @extend .accordion-heading;
    min-height: 45px;
    font-size: $font-size;
    letter-spacing:0;
    line-height:20px;
}
>h4 {
    @extend .accordion-heading;
    min-height: 55px;
    font-size: $font-size;
    letter-spacing:0;
    line-height:55px;
    font-size: 20px;
    background: #eeeeee;
    padding: 0px;
    margin: 10px 0px;
    padding-left: 10px
}
>h5 {
    @extend .accordion-heading;
    min-height: 45px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
}
>h6 {
    @extend .accordion-heading;
    min-height: 45px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
}

&-content {
    display:none;
    padding-bottom:20px;
    height:auto;
    width:auto;
    overflow:auto;
}

&-icon {
    font-family: $font-icons;
    width: 2em;
    height: 2em;
    line-height: 2em;
    font-size: 1em;
    font-style: normal;
    margin-right: 4px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 10px;
    right: 0px;
    border: 1px solid $color-02;
    text-align: center;
    border-radius: 32px;
}

&-summary {
    margin-top: -30px;
}

&-expanded {
    >h2:after, >h3:after, >h5:after{
        @extend .accordion-icon;
        @include icon-up-before;
        color:$color-04;
    }
    >.accordion-content {
        overflow-x: hidden;
        display:block;
    }
}

&-expander {
    display: block;
    height: 40px;
    text-align: right;
}
}

.careers-accordion {
border-top: none;
}
.accordion > h4:after {
line-height: 25px;
color: #000;
border: none;
}
.accordion-expanded > h4:after {
line-height:25px;
border:none;
color:#000;
}


/* Tablet and Desktop */
@media only screen and (min-width: $media-min-width-desktop) {
.accordion > h4 {
    background: #fff;
    cursor: initial;
    line-height:25px;
    min-height: 25px;
    padding-left: 0px;
}
.accordion > h4:after {
    display: none;
}
.accordion-expanded > h4:after {
    display:none;
}
}

/* Mobile */
@media only screen and (max-width: $media-max-width-mobile) {
.accordion-expanded {
    >.accordion-content {
        overflow-x: auto;
        .row.row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
}


/* Scrollbar */
.custom-scroll::-webkit-scrollbar {
width: 5px;
height: 5px;
}
.custom-scroll::-webkit-scrollbar-track {
background: #333;
}
.custom-scroll::-webkit-scrollbar-thumb {
background: #0014D2;
}
.custom-scroll::-webkit-scrollbar-thumb:window-inactive {
background: #eeeeee;
}
