/* Defaults */
.container {
	width: 320px;
	margin:0 auto;
	.row {
		clear: both;
		&:before {
			content: "";
			display: table;
			line-height: 0;
		}
		&:after {
			content: "";
			display: table;
			line-height: 0;
		}
		.columns {
            min-height: 1px;
			box-sizing: border-box;
			margin-left: 10px;
			margin-right: 10px;
		}
	    &-space-s{
	        margin-bottom: $row-spacing;
	    }
	    &-space-m{
	        margin-bottom: $row-spacing*2;
	    }
	    &-space-l{
	        margin-bottom: $row-spacing*3;
	    }
	    &-space-xl{
	        margin-bottom: $row-spacing*4;
	    }

        .row {
            margin-left: -10px;
            margin-right: -10px;
        }
	}
}

/* Mobile portrait */
@media only screen and (max-width: $media-min-width-mobile) {

	.container {
		.row {
			.mp0
			{
				width:0px;
			}
			@for $i from 1 through 4 {
				.mp#{$i} {
					width: ($column-width * $i) - 20px;
					float: left;
				}
			}
			.row {
				margin-left: -10px;
				margin-right: -10px;
			}
		}
	}
}

/* Mobile landscape */
@media only screen and (min-width: $media-min-width-mobile) and (max-width: $media-max-width-mobile) {

	.container {
		width: 480px;
		.row {
			.columns:not(.m6) {
				float: left;
			}
			.m0
			{
				width:0px;
			}
			@for $i from 1 through 6 {
				.m#{$i} {
					width: ($column-width * $i) - 20px;
				}
				.m#{$i}-offset {
					margin-left: $column-width * $i + 10px;
				}
			}
		}
	}
}

/* Tablet */
@media only screen and (min-width: $media-min-width-tablet) and (max-width: $media-max-width-tablet) {

	.container {
		width: 640px;
		.row {
			&:after
			{
				clear: both;
			}
			.columns:not(.t8) {
				float: left;
			}
			.t0
			{
				width:0px;
			}
			@for $i from 1 through 8 {
				.t#{$i} {
					width: ($column-width * $i) - 20px;
				}
				.t#{$i}-offset {
					margin-left: $column-width * $i + 10px;
				}
			}
		}
	}
}

/* Desktop */
@media only screen and (min-width: $media-min-width-desktop) {

	.container {
		width: 960px;
		.row {
			&:after
			{
				clear: both;
			}
			.columns:not(.d12) {
				float: left;
			}
			.d0
			{
				width:0px;
			}
			@for $i from 1 through 12 {
				.d#{$i} {
					width: ($column-width * $i) - 20px;
				}
				.d#{$i}-offset {
					margin-left: $column-width * $i + 10px;
				}
			}
		
			&-space-dm {
				margin-bottom: $row-spacing*2;
			}
		}
	}
}
