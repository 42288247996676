.interactive-gallery
{
	width:100%;
	&-purple
	{
		background-color:#AD29A3;
	}
	&-dark-blue
	{
		background-color:#2454CF;
	}
	&-light-blue
	{
		background-color:#279CF7;
	}
	&-green
	{
		background-color:#25C1B4;
	}
	&-text
	{
		width:100%;
		float:left;
		text-align:left;
		padding : 50px 0px;
		position:relative;
		*
		{
			color:white;
		}
		*:not(.icon-close)
		{
			width:280px;
			margin: 20px auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
		a
		{
			text-decoration:underline;
		}
		.icon-close
		{
		    position: absolute;
		    right: 20px;
		    top: 16px;
		    font-size: 32px;
			&:hover
			{
				cursor:pointer;
			}			
		}
	}
	&-image
	{
		float:left;
		position:relative;
		&-active
		{

		}

		&-3, &-4
		{
			width:50%;
		}
		img
		{
			width:100%;
			display:block;
		}
		&:hover
		{
			&:after
			{
			    cursor: pointer;
    			opacity: .25;
			}
		}
		&:after
		{
			content: "";
		    display: block;
		    position: absolute;
		    left: 0;
		    top: 0;
		    width: 100%;
		    height: 100%;
		    background-color: #000;
		    opacity: 0;
		    transition: opacity .2s linear;		
		}
		&-arrow
		{
		    width: 0;
		    height: 0;
		    border-left: 20px solid transparent;
		    border-right: 20px solid transparent;
		    display: block;
		    position: absolute;
		    left:50%;
		    margin-left:-20px;
		    z-index:50;
		    &-up
		    {
			    border-bottom: 20px solid;
			    bottom:0px;
		    }
		    &-down
		    {
			    border-top: 20px solid;
			    top: 0px;
		    }
			&-purple
			{
				border-bottom-color:#AD29A3;
				border-top-color:#AD29A3;
			}
			&-dark-blue
			{
				border-bottom-color:#2454CF;
				border-top-color:#2454CF;
			}
			&-light-blue
			{
				border-bottom-color:#279CF7;
				border-top-color:#279CF7;
			}
			&-green
			{
				border-bottom-color:#25C1B4;
				border-top-color:#25C1B4;
			}		    
		}
	}
	&:after
	{
		content:"";
		display:table;
		clear:both;
	}
}

@media only screen and (min-width: $media-min-width-desktop)
{
	.interactive-gallery
	{
		&-text
		{
			*:not(.icon-close)
			{
				width:400px;
			}
		}
		&-image
		{
			&-3
			{
				width:33.33%;
			}
			&-4
			{
				width:25%;	
			}
		}
	}
}