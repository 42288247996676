body.blurme:not(.hide-cookie-dialog)
{
	#wrapper-navtop, #wrapper-main, #wrapper-footer
	{
		-webkit-filter: blur(5px);
		-moz-filter: blur(5px);
		-ms-filter: blur(5px);
		filter: blur(5px);
	}
}
.hide-cookie-dialog .cookie-popup
{
    display: none;
}
.cookie-identifier
{
    &.slidedown{
        padding: 6px 0;
        width: 100%;
        height: auto;
        padding-top:20px;
        padding-bottom:20px;
        display: none;
        position: relative;
        z-index: 100;
        overflow:hidden;
        background-color: #000;
        color: #fff;

        button {
          float: right;
        }

        h4 {
            color:white;
        }
        a {
            color:white;
            text-decoration:underline;
            &:hover {
            	cursor: pointer;
            }
        }
        &-blue{
            background-color: $color-link;
            
            button {
                background-color:white;
                color:$color-link;
                @include backgroundcolor;
                &:hover
                {
                    @include backgroundcolor;
                    background-color:#1450d2;
                    color:white;
                }
            }
        }        
        &-grey {
            background-color: #eee;
			h4,p,a {
				color:$color-primary;
			}
        }
    }
}
.cookie-identifier.lightbox 
{
	.cookie-popup 
	{
	    width: 100%;
	    z-index: 100;
		&:before 
		{
			content: "";
			width: 100%;
			height: 100%;
		    background-color: rgba(0,0,0,.8);
		    overflow: hidden;
			position: fixed;
			left:0;
			top:0;
			z-index: 99;
		}
		&-dialog 
		{
			position: absolute;
		    left:0;
		    right:0;
		    margin-left: auto;
		    margin-right: auto;
			margin-top: 30%;
			display: block;
			background: #fff;
			border: 0;
			padding: 20px;
			z-index: 100;
			.button-primary 
			{
				width: 100%;
				margin-top: 10px;
				&:hover
				{
					cursor:pointer;
				}
			}
			.button-secondary 
			{
				width: 100%;
				margin-top: 10px;
				&:hover
				{
					cursor:pointer;
				}
			}
			a
			{
				&:hover
				{
					cursor:pointer;
				}
			}
		}
	}
}

/* Tablet */
@media only screen and (min-width: $media-min-width-tablet) {
	
	.cookie-identifier.lightbox 
	{
		.cookie-popup 
		{
			&-dialog 
			{
				width: 500px;
				height: auto;
				margin-top: 15%;
				.button-bar-left
				 {
				    float: left;
				}
				.button-bar-right 
				{
				    float: right;
				}	
				.button-bar-text 
				{
					margin-top: 10px;
				}
				.button-primary 
				{
					width: auto;
					margin-top: 0px;
					display:inline-block;
				}
				.button-secondary
				{
					width: auto;
					margin-top: 0px;
				    float: left;
					margin-right: 10px;
					display:inline-block;
				}
			}
		}
	}
}
