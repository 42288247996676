.forms
{
    &-error
    {
        color:#D51F16;
        span {
            margin-left: 10px;
            margin-top: 10px;
            display: block;
        }

        &-global
        {
            color:#D51F16;
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }

    &.space-m {
        div {
                margin-bottom: 20px;
        }
    }
    &.space-l {
        div {
                margin-bottom: 30px;
        }
    }
    &-textarea
    {
        margin-bottom:10px;
        label
        {
            color:$color-03;
            font: 400 15px/20px InvescoInterstateLight,Verdana,Arial;
            display:block;
            margin-left:10px;
            margin-bottom:5px;

        }
        textarea {
            resize: none;
            width: calc(100% - 22px);
            padding: 5px 10px 0px;
            font: 400 15px/20px InvescoInterstateLight,Verdana,Arial;
            border:1px solid $color-02;
            @include smoothbordercolorchange;
            &:hover, &:focus
            {
                border:1px solid $color-link;
            }
            &.activated
            {
                border:1px solid $color-04;
                color:$color-04;
            }
        }
    }
    &-textbox
    {
        margin-bottom:10px;
        label
        {
            color:$color-03;
            font: 400 15px/20px InvescoInterstateLight,Verdana,Arial;
            display:block;
            margin-left:10px;
            margin-bottom:5px;
        }
        input
        {
            @extend .textboxanddropdown;
            width:100%;
            padding: 0px 10px 0px;
            outline:none;
        }
        input:focus:invalid, textarea:focus:invalid
        {
             box-shadow: none;
             border: 1px solid #d81e05!important;

        }
        &-password
        {
            border:1px solid $color-02;

            &-primary, &-secondary, &-confirm
            {
                position:relative;
                width:90%;
                background-color:$color-02;
                height:10px;
                display:inline-block;
                margin-top:10px;
                margin-left:15px;

                &:after
                {
                    font: 400 15px/20px InvescoInterstateLight,Verdana,Arial;
                    padding-top:15px;
                    display:inline-block;
                }
                &:before
                {
                    content:"";
                    height:10px;
                    position:absolute;
                    top:0px;
                    left:0px;
                    width:0%;
                    @include expandwidth;
                }
            }
            &-confirm
            {
                &:before
                {
                    opacity:1;
                    background-color:#00b760;
                }
            }
            &-primary, &-secondary
            {
                &:before
                {
                    opacity:0.1;
                }
            }
            &-confirmed
            {
                &:after
                {
                    content:"Password Match";
                }
                &:before
                {
                    width:100%;
                    background-color:#00b760;
                    opacity:1;
                    @include expandwidth;
                }
            }
            &-wrong
            {
                &:after
                {
                    content:"Password Incorrect";
                }
                &:before
                {
                    width:100%;
                    background-color:red;
                    opacity:1;
                    @include expandwidth;
                }
            }

            &-primary
            {
                &:before
                {
                    background-color:$color-link;

                }
            }
            &-secondary
            {
                &:before
                {
                    background-color:$color-04;
                }
            }

            &-veryStrong
            {
                &:after
                {
                    content:"Very Strong";
                }
                &:before
                {
                    width:100%;
                    opacity:1;
                }
            }
            &-strong
            {
                &:after
                {
                    content:"Strong";
                }
                &:before
                {
                    width:80%;
                    opacity:0.8;
                }
            }
            &-medium
            {
                &:after
                {
                    content:"Acceptable";
                }
                &:before
                {
                    width:60%;
                    opacity:0.6;
                }
            }
            &-weak
            {
                &:after
                {
                    content:"Weak";
                }
                &:before
                {
                    width:40%;
                    opacity:0.4;
                }
            }
            &-veryWeak
            {
                &:after
                {
                    content:"Very Weak";
                }
                &:before
                {
                    width:20%;
                    opacity:0.2;
                }
            }
        }
    }

    &-dropdown
    {
        margin-bottom:10px;
        label
        {
            color:$color-03;
            font: 400 15px/20px InvescoInterstateLight,Verdana,Arial;
            display:block;
            margin-left:10px;
            margin-bottom:5px;
        }
        select {
            @extend .textboxanddropdown;
            width:100%;
            color: $color-03;
            border-right:none !important;
            padding-left:10px;
            padding-right:37px;
            background:url('../images/dropdown_cyan.png') no-repeat right;
            &::-ms-expand
            {
                display:none;
            }
            &:-moz-focusring
            {
                color: transparent;
                text-shadow: 0 0 0 #000;
            }
            -webkit-appearance: none;
            -moz-appearance: none;
            outline:none;
            background-color:white;
            cursor:pointer;
            &.activated
            {
                border:1px solid $color-04;
                color:$color-04;
            }
            &.inline
            {
                width: 20%;
            }
            option
            {
                height:35px;
                font: 400 15px/20px InvescoInterstateLight,Verdana,Arial;
                color:$color-04;
                border:none;
                padding-top:15px;
                padding-left:10px;
                color:$color-03;
                &:hover
                {
                    background-color:white;
                }
            }
        }
    }

    &-required
    {
        label
        {
            &:after
            {
                content: ' *';
                color:$color-link;
            }
        }
    }

    &-checkbox-on, &-checkbox-off
    {
        margin-bottom:10px;
        position:relative;
        cursor:pointer;
        input
        {
            visibility:hidden;
            margin-left:-25px;
        }
        label
        {
            width:60%;
            padding-left:20px;
            vertical-align:top;
            line-height:15px;
            display:inline-block;
            color:$color-03;
            font: 400 15px/20px InvescoInterstateLight,Verdana,Arial;
            margin-left:10px;
            margin-bottom:20px;
            margin-top:5px;
        }
        &:before
        {
            width:100px;
            height:25px;
            width:25px;
            border-radius:0px;
            border: 1px solid $color-02;
            display:inline-block;
            content:"";
        }
    }
    &-checkbox-on::after {
        font-family:$font-icons;
		@include icon-tick-before;
        position:absolute;
        top:6px;
        left:7px;
        color:black;
    }

    &-toggle-on
    {
        &:after
        {
            left:50px;
            background-color:white;
            border: 1px solid $color-link;
            @include transitional;
        }
    }
    &-toggle-off
    {
        &:after
        {
            background-color:white;
            border: 1px solid $color-02;
            left:0px;
            @include transitional;
        }
    }

    &-toggle-on, &-toggle-off
    {
        @extend .radioandtoggle;
        margin-bottom: 10px;
        display:inline-block;
        &:before
        {
            width:100px;
            height:50px;
            border-radius:30px;
            border: 1px solid $color-02;
            display:inline-block;
            content:"";
            vertical-align: top;
        }
    }
    &-toggle-on
    {
        @extend .radioandtoggle;
        &:before
        {
            background-color:$color-link;
            border-color:$color-link;
        }
    }
    &-toggle-off
    {
        @extend .radioandtoggle;
        &:before
        {
            background-color:$color-01;
        }
    }

    &-radio-on, &-radio-off
    {
        @extend .radioandtoggle;
        margin-bottom: 10px;
        label
        {
            margin-left:50px;
        }
    }

    &-required
    {
        label
        {
            &:after
            {
                content: ' *';
                color:$color-link;
            }

            p {
                &:last-child{
                    display: inline;
                }
            }
        }
    }

    &-checkbox-on, &-checkbox-off
    {
        margin-bottom:10px;
        position:relative;
        cursor:pointer;
        input
        {
            visibility:hidden;
            margin-left:-25px;
        }
        label
        {
            width:60%;
            padding-left:20px;
            vertical-align:top;
            line-height:15px;
            display:inline-block;
            color:$color-03;
            font: 400 15px/20px InvescoInterstateLight,Verdana,Arial;
            margin-left:10px;
            margin-bottom:20px;
            margin-top:5px;
        }
        &:before
        {
            width:100px;
            height:25px;
            width:25px;
            border-radius:0px;
            border: 1px solid $color-02;
            display:inline-block;
            content:"";
        }
    }
    &-checkbox-on::after {
        font-family:$font-icons;
		@include icon-tick-before;
        position:absolute;
        top:6px;
        left:7px;
        color:black;
    }

    &-toggle-on
    {
        &:after
        {
            left:50px;
            background-color:white;
            border: 1px solid $color-link;
            @include transitional;
        }
    }
    &-toggle-off
    {
        &:after
        {
            background-color:white;
            border: 1px solid $color-02;
            left:0px;
            @include transitional;
        }
    }

    &-toggle-on, &-toggle-off
    {
        @extend .radioandtoggle;
        margin-bottom: 10px;
        display:inline-block;
        &:before
        {
            width:100px;
            height:50px;
            border-radius:30px;
            border: 1px solid $color-02;
            display:inline-block;
            content:"";
        }
    }
    &-toggle-on
    {
        @extend .radioandtoggle;
        &:before
        {
            background-color:$color-link;
            border-color:$color-link;
        }
    }
    &-toggle-off
    {
        @extend .radioandtoggle;
        &:before
        {
            background-color:$color-01;
        }
    }

    &-radio-on, &-radio-off
    {
        @extend .radioandtoggle;
        margin-bottom: 10px;
        label
        {
            margin-left:50px;
        }
    }

    .textboxanddropdown
    {
        height:40px;
        box-shadow:none;
        box-sizing:border-box;
        border-radius:0px;
        border:1px solid $color-02;
        font: 400 15px/20px InvescoInterstateLight,Verdana,Arial;
        @include smoothbordercolorchange;
        &:hover, &:focus
        {
            border:1px solid $color-link;
        }
        &.activated
        {
            border:1px solid $color-04;
            color:$color-04;
        }
    }

    &-radio-off
    {
        &:after
        {
            @include transitional;
            background-color:white;
            border:1px solid $color-02;
        }
    }
    &-radio-on
    {
        &:after
        {
            @include transitional;
            background-color: $color-link;
            border:1px solid $color-02;
        }
    }

    .textboxanddropdown
    {
        height:40px;
        box-shadow:none;
        box-sizing:border-box;
        border-radius:0px;
        border:1px solid $color-02;
        font: 400 15px/20px InvescoInterstateLight,Verdana,Arial;
        @include smoothbordercolorchange;
        &:hover, &:focus
        {
            border:1px solid $color-link;
        }
        &.activated
        {
            border:1px solid $color-04;
            color:$color-04;
        }
    }

    .radioandtoggle
    {
        color:$color-03;
        line-height:50px;
        position:relative;
        input
        {
            visibility:hidden;
            margin-left:-25px;
        }
        label
        {
            width:60%;
            padding-left:25px;
            vertical-align:middle;
            line-height:20px;
            display:inline-block;
        }
        &:after
        {
            content:"";
            position:absolute;
            display:block;
            width:48px;
            height:47px;
            top:1px;
            border-radius:25px;
            cursor:pointer;
        }
    }
    p.description {
        &-link {
            text-align: right;
            margin-top: 6px;
        }
    }
}

.searchbox
{
    position:relative;
    min-width:150px;
    max-width: 300px;
    input
    {
        border: 1px solid #ccc;
        border-radius:20px;
        color:$color-primary;
        text-indent: 10px;
        font: 400 15px/20px InvescoInterstateLight,Verdana,Arial;
        width:100%;
        line-height: 2.5;
        outline: none;
        min-height: 40px;
        min-width: 150px;
        &::-webkit-input-placeholder,
        &:-moz-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder {
           color: lighten($color-primary, 50%);
        }
        &::-ms-clear,
        &::-ms-reveal{
            display: none;
            width: 0;
            height: 0;
        }
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration
        {
            display: none;
            width: 0;
            height: 0;
        }
    }
    .icon-search{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 32px;
        z-index: 2;
        color: lighten($color-primary, 50%);
    }

    .searchbox-formFocus {
        position: relative;
    }

}

.clSECTIONHEADING {
    display:inline-block;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 20px;
    font-family: InvescoInterstateBold;
    margin-top: 10px;
}


.clFieldLabel {
    display:inline-block;
    font-size: 15px;
    line-height: 20px;
    font-family: InvescoInterstateBold, Helvetica, sans-serif;
    font-weight: normal;
    color: #000000;
    margin-bottom: 10px;
}

@media only screen and (min-width: $media-min-width-tablet)
{
    .forms
    {
        .radioandtoggle
        {
            line-height:27px;
            &:after
            {
                width:25px;
                height:25px;
            }
            label
            {
                padding-left:20px;
            }
        }
        &-toggle-on, &-toggle-off
        {
            &:before
            {
                width:53px;
                height:27px;
            }
        }
        &-toggle-on
        {
            &:after
            {
                left:28px;
            }
        }
        &-radio-on, &-radio-off
        {
            label
            {
                margin-left:35px;
            }
        }
    }
}
