footer
{
    background-color: $color-01;
    margin-top:50px;    
    clear:both;
    overflow:hidden;
    padding: 30px 0;
    .row-footer
    {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .navsub h3
    {
        padding-top:0px;
        padding-bottom:0px;
        border: none;
    }
	h2 {
		font-size:20px;
		line-height:25px;
	}
}
