.award-promo {
	width: 100%;
	a {
		text-align: center;
		border: 1px solid $color-01;
		display: block;
		height: 170px;
		line-height: 11;
		img	{
		    height: auto;
			vertical-align: middle;
		}
	}
	p {
		margin-top:10px;
		border-top: 1px dotted $color-02;
		&.heading {
			font-family: $font-primary-bold;
			border-top: none;
		}
		&.date {
			padding-top: 5px;
			color: $color-03;
		}
	}
}

@media only screen and (min-width: $media-min-width-tablet) {
	
	.award-promo {
		width: 240px;
	}
}
