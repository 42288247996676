.navmain-mobile-scroll.home-background
{
	background: white;
}

.home, .home-page
{
	.navmain-mobile-scroll .logo-mobile
	{
		background: transparent url("../images/invesco_singleline_cyan.png") no-repeat;
    	background-size: 200px auto;
		background-position-y: 20px;
	}
}

.navmain-mobile-scroll
{
	background-color:$color-link;
	&.home-background
	{
		&.active
		{
			background-color:white;
		}
	}

	.nav-button
	{
		margin-top:10px;
		float:right;
		z-index:101;
		&:first-child
		{
			margin-right:10px;
		}
	}
	.logo-mobile
	{
		display: block;
		width:200px;
		height:75px;
		background: transparent url("../images/invesco_singleline_white.png") no-repeat;
		background-size: 200px auto;
		background-position-y: 20px;
		margin-left:10px;	
	}

	width:100%;
	height:100px;
	position:fixed;
	z-index:98;
	top:0px;
}

.row-navmain
{
	position:fixed;
	z-index:101;
	top:0px;
}

@media only screen and (min-width: $media-min-width-desktop) {
	.navmain-mobile-scroll
	{
		display:none;
	}
	.row-navmain
	{
		position:static;
	}
}
