.photo-quote-panel
{
	.profile
	{
		display:inline-block;
		margin-right:80px;
		text-align:center;
		min-height:auto;
		&-last
		{
			margin-right:0px;
		}
		&-image
		{
			border-right:0px;
			max-width:100%;
			padding-right:6px;
			height:auto;
			&:hover
			{
				cursor:pointer;
			}			
		}
	}
	&-panel
	{
		position:relative;
		background-color:$color-01;
		padding: 30px 20px 15px;
		margin-top:25px;
		display:none;
		p
		{
			margin-top:5px;
		}
		&-close
		{
			position:absolute;
			top:15px;
			right:15px;
			@extend .icon-close;
			font-family:$font-icons;
			font-size:35px;
			&:hover
			{
				cursor:pointer;
			}
		}
		&-arrow
		{
			width:0px;
			height:0px;
			border-left: 25px solid transparent;
			border-right: 25px solid transparent;
			border-bottom: 25px solid $color-01;
			position:absolute;
			top:-20px;
		}
		&-quote, &-credit
		{
			display:inline-block;
			vertical-align:middle;
		}
		&-quote
		{	
			width:70%;
		}
		&-credit
		{
			width:29%;
			img.photo-quote-panel-panel-credit-quotation-mark
			{
				display:block;
				max-width:35px;
				height:auto;
				margin-bottom:10px;
			}
			*
			{
				padding-left:20px;
			}
		}
	}
}

@media only screen and (max-width: $media-min-width-desktop) {
	.photo-quote-panel
	{
		.profile
		{
			width:24%;
			margin-right:3px;
		}
		&-panel
		{
			&-credit
			{
				margin-top:20px;
			}
		}
	}
}

@media only screen and (max-width: $media-min-width-tablet) {
	.photo-quote-panel
	{
		.profile
		{
			margin-right:0px;
		}
		&-panel
		{
			&-quote, &-credit
			{
				display:block;
				width:100%;
			}
			&-quote
			{
				margin-top:25px;
			}
			&-credit
			{
				*
				{
					padding-left:0px;
				}
			}
			&-arrow
			{
				display:none;
			}	
		}
	}
}