.ie-upgrade-message {
	border-bottom: 1px solid #333;
	background-color: #f1f1f1;
	padding-top: 12px;
	padding-bottom: 12px;
	margin-bottom: 12px;
}

.ie-upgrade-message h2 {
	font-size: 24px;
}

.ie-upgrade-message h2,
.ie-upgrade-message p {
	width: 640px;
	margin: 0 auto 6px auto;
}
