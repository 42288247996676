/* 16:9 aspect ratio */
.flexmedia-16-9 {

	float: none;
    clear: both;
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom:50px;

	iframe {
		overflow: hidden;
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}
	
	&.vimeo {
		margin-bottom:40px;
	}
}

/* 4:3 aspect ratio */
.flexmedia-4-3 {

	float: none;
    clear: both;
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 75%;
    padding-top: 20px;

	iframe {
		overflow: hidden;
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}
}

