.button {
    font-family: $font-primary-bold;
    border: 1px solid transparent;
    display:inline-block;
    background-color:$color-link;
    border-radius:10px;
    color:white;
    font-size: $font-size;
    text-align: center;
    cursor:pointer;
    padding: 15px 20px;
    line-height: 20px;
    box-sizing: border-box;
    min-width: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    &:hover
    {
        text-decoration: none;
    }

    &.space {
        margin: 0 10px;
        @media only screen and (max-width: $media-min-width-mobile) {
            margin: 0 auto 10px;
        }
        &-right {
            margin-right: 10px;
            @media only screen and (max-width: $media-min-width-mobile) {
                margin: 0 auto 10px;
            }
        }
    }
}

.button-primary {
    @extend .button;
    background-color:$color-link;
    position: relative;
    &:disabled
    {
        background-color:$color-02;
    }
    &-inactive  {
        @extend .button;
        background-color:$color-01;
        border-color: $color-02;
        color: $color-02;
    }

    .icon,
    [class^="icon-"],
    [class*=" icon-"] {
			&:before{
				position: absolute;
        top: 50%;
        transform: translateY(-50%);
			}
    }
}

.button-secondary {
    @extend .button;
    background-color:#ffffff;
    border-color: $color-link;
    color: $color-link;
        &-inactive  {
        @extend .button;
        background-color:white;
        border-color: $color-02;
        color: $color-02;
    }

		.icon,
    [class^="icon-"],
    [class*=" icon-"] {
			&:before{
				position: absolute;
        top: 50%;
        transform: translateY(-50%);
			}
    }
}

.button-black {
    @extend .button;
    background-color:#000;
    position: relative;
    &:disabled
    {
        background-color:#000;
    }
    &-inactive  {
        @extend .button;
        background-color:$color-01;
        border-color: $color-02;
        color: $color-02;
    }

    .icon,
    [class^="icon-"],
    [class*=" icon-"] {
			&:before{
				position: absolute;
        top: 50%;
        transform: translateY(-50%);
			}
    }
}

.button-icon {
  @extend .button;
  min-width: auto;
  background-color: transparent;
  padding: 5px;
  margin: 0;
}

.back-button-shadow
{
    -webkit-box-shadow: -170px 0px 0px 0px rgba(241,241,241,1);
    -moz-box-shadow: -170px 0px 0px 0px rgba(241,241,241,1);
    box-shadow: -170px 0px 0px 0px rgba(241,241,241,1);
}

@media only screen and (max-width: $media-min-width-mobile) {
    .button {
        width: 100%;
        &:after {
            display:none;
        }
    }
}

@media only screen and (min-width: $media-min-width-desktop) {
    .mobile-only {
        display: none !important;
    }
}
