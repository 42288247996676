.navigation-utility__mobile {
    display: none;
    order: 3;
    width: calc(83.33333% - 44px);
    @include font-graphik-regular;
    box-sizing: border-box;

    @media screen and (max-width: $media-aem-tablet-max) {
        display: block;
    }

    @media screen and (max-width: $media-sm-max) {
        width: calc(100% - 76px);
    }

    div {
        box-sizing: border-box;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            box-sizing: border-box;

            a {
                color: $color-primary;
                @include font-graphik-medium(16px);
            }
        }
    }

    .navigation-utility__mobileLinks {
        padding: 21px 0 26px;
        border-top: 1px solid $gray-90;
        border-bottom: 1px solid $gray-90;
        margin-bottom: 100px;

        @media screen and (max-width: $media-max-width-mobile) {
            padding: 17px 0 26px;
        }

        li,
        li.navigation-utility__item {
            padding: 19px 0;
            animation: listAnim ease-in-out 0.3s;
            display: none;
            margin-bottom: 0;

            @media screen and (max-width: $media-max-width-mobile) {
                padding: 16px 0;
            }

            &.list-active {
                display: block;
            }

            a,
            span {
                @include font-graphik-medium(16px);
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            .navigation-utility__submenu {
                position: fixed;
                background: #fff;
                top: 75px;
                left: 0;
                height: calc(100% - 75px);
                width: 100%;
                padding: 20px 86px;
                z-index: 11;
                display: none;

                @media screen and (max-width: $media-max-width-mobile) {
                    padding: 0 25px;
                }
            }

            .navigation-utility__submenuList {
                padding: 20px;

                @media screen and (max-width: $media-max-width-mobile) {
                    padding: 20px 25px;
                }
            }

            .navigation-utility__submenuHeader {
                @include font-graphik-medium(24px);
                padding: 21px 0 20px 20px;
                position: relative;
                cursor: pointer;
                margin: 10px 0 45px;

                @media screen and (max-width: $media-max-width-mobile) {
                    font-size: 20px;
                    padding-left: 17px;
                    margin: 0;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 55%;
                    left: 3px;
                    transform: translateY(-50%) rotate(225deg);
                    border-top: 2px solid $color-primary;
                    border-right: 2px solid $color-primary;
                    border-left: none;
                    height: 10px;
                    width: 10px;

                    @media screen and (max-width: $media-max-width-mobile) {
                        top: 50%;
                        left: 0;
                        transform: scale(0.85) translateY(-50%) rotate(225deg);
                    }
                }
            }

            .navigation-utility__submenuItem {
                border-bottom: 1px solid $gray-90;
                padding-bottom: 15px;
                display: block;
                margin-bottom: 40px;

                @media screen and (max-width: $media-max-width-mobile) {
                    margin-bottom: 10px;
                }

                a {
                    @include font-graphik-regular(24px);
                    position: relative;

                    @media screen and (max-width: $media-max-width-mobile) {
                        font-size: 18px;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: initial;
                        margin-left: 10px;
                        transform: scale(0.85) translateY(-50%) rotate(45deg);
                        border-top: 2px solid $color-primary;
                        border-right: 2px solid $color-primary;
                        border-left: none;
                        height: 10px;
                        width: 10px;
                        transition: all ease-in-out 0.3s;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                .navigation-utility__dropdownLinkHover {
                    display: none;
                }
            }

            &.navigation-utility__item--dropdown {
                position: relative;

                .navigation-utility__userRole {
                    display: none;
                }
            }

            .navigation-utility__itemText {
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 10px;
                    height: 10px;
                    transform: translateY(-50%) rotate(45deg) scale(0.8);
                    border-top: 2px solid #000;
                    border-right: 2px solid #000;
                }
            }
        }

        .navigation-utility__dropdownLinkHoverTitle {
            @include font-graphik-medium;
        }
    }

    .navigation-utility__mobileBottom {
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: 1px solid $gray-90;
        background: $color-white;
        height: auto;
        animation: showNavBottom ease-in-out 0.3s;

        @keyframes showNavBottom {
            from {
                bottom: -62px;
            }
            to {
                bottom: 0;
            }
        }

        .navigation-utility__list {
            display: flex;
        }

        .navigation-utility__item {
            position: relative; 
            padding: 28px 48px;
            margin: 0;
            width: 50%;
            min-height: 62px;
            cursor: pointer;

            @media screen and (max-width: $media-max-width-mobile) {
                padding: 20px 34px 18px 20px;
            }

            .navigation-utility__itemText {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;          
                @include font-graphik-medium(16px);

                @media screen and (max-width: $media-max-width-mobile) {
                    font-size: 14px;
                }
            }

            span {
                width: 100%; 
                font-size: 14px;             

                &::before {
                    content: "";
                    position: absolute;
                    top: 56%;
                    right: 50px;
                    transform: scale(0.85) translateY(-50%) rotate(-45deg);
                    border-top: 2px solid $color-primary;
                    border-right: 2px solid $color-primary;
                    height: 10px;
                    width: 10px;
                    transition: all ease-in-out 0.3s;

                    @media screen and (max-width: $media-max-width-mobile) {
                        top: 50%;
                        right: 22px;
                    }
                }
            }

            &.navigation-utility__item--active {
                background: $light-cyan;

                span {    
                    &::before {
                        top: 50%;
                        transform: scale(0.9) translateY(-50%) rotate(135deg);

                        @media screen and (max-width: $media-max-width-mobile) {
                            top: 42%;
                            transform: scale(0.85) translateY(-50%) rotate(135deg);
                        }
                    }
                }
            }
        }

        .navigation-utility__submenu,
        .navigation-utility__userRole {
            position: fixed;
            top: 75px;
            right: 0;
            height: calc(100% - 157px);
            width: 100%;
            background: $color-white;
            padding: 20px 106px;
            display: none;
            cursor: default;

            @media screen and (max-width: $media-max-width-mobile) {
                height: calc(100% - 138px);
                padding: 20px 30px 20px 38px;
            }
        }
        
        .navigation-utility__userRole {
            display: block;
            right: -100%;
        }

        .navigation-utility__submenuItem {
            padding-top: 10px;
            border-bottom: 1px solid $gray-90;
            margin-bottom: 35px;
            
            @media screen and (max-width: $media-max-width-mobile) {
                margin-bottom: 5px;
            }

            a {
                &.navigation-utility__submenuAnchor {
                    @include font-graphik-regular(24px);
                    position: relative;
                    padding: 10px 0 15px;
                    display: inline-block;
                    line-height: 28px;
            
                    @media screen and (max-width: $media-max-width-mobile) {
                        font-size: 18px;
                        padding: 10px 0;
                    }
    
                    &:hover {
                        text-decoration: none;
                    }
    
                    &::after {
                        content: "";
                        position: absolute;
                        top: 45%;
                        left: initial;
                        margin-left: 5px;
                        transform: scale(0.85) translateY(-50%) rotate(45deg);
                        border-top: 2px solid $color-primary;
                        border-right: 2px solid $color-primary;
                        border-left: none;
                        height: 10px;
                        width: 10px;
                        transition: all ease-in-out 0.3s;
            
                        @media screen and (max-width: $media-max-width-mobile) {
                            top: 50%;
                        }
                    }
                }
            }
        }

        .navigation-utility__userRoleMobileBack {
            @include font-graphik-medium(20px);
            padding: 25px 20px;
            position: relative;
            cursor: pointer;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: scale(0.85) translateY(-50%) rotate(225deg);
                border-top: 2px solid $color-primary;
                border-right: 2px solid $color-primary;
                border-left: none;
                height: 10px;
                width: 10px;
            }
        }

        .navigation-utility__userRoleTitle {
            @include font-graphik-medium(24px);
            margin-bottom: 15px;
            margin-left: 20px;
        }

        .navigation-utility__userRoleSubtitle {
            margin-bottom: 10px;
            margin-left: 20px;
        }

        .navigation-utility__submenuHeader {
            @include font-graphik-medium(24px);
            margin: 19px 0 70px;

            @media screen and (max-width: $media-max-width-mobile) {
                font-size: 20px;
                margin: 19px 0 41px;
            }
        }

        .navigation-utility__userRoleCheckConfirm {
            @include font-graphik-regular(14px);
            width: calc(100% - 15px);
            margin-left: 10px;

            p, a {
                @include font-graphik-regular(14px);  
            }

            a {
                color: $color-link;
            }

            ul {
                padding-left: 40px;
                
                li {
                    list-style: disc;
                }
            }
        }

        .navigation-utility__userRoleConfirm {
            margin-left: 20px; 
        }

        .navigation-utility__userRoleConfirmBtn {
            padding-top: 10px;
        }

        .navigation-utility__userRoleCheck {
            display: flex;
                        
            span {
                border: 1px solid $gray-90;
                height: 15px;
                width: 15px;
                float: left;
                background: transparent;
                position: relative;
                margin-top: 5px;

                &::before {
                    display: none;
                }
            }
    
            input[type="checkbox"] {
                position: absolute;
                opacity: 0;
                height: 0;
                width: 0;
    
                &:checked {
                    &~ span {
                        border-color: $color-link;
                        background: $color-link;
    
                        &::before {
                            display: block;
                            content: "";
                            position: absolute;
                            top: 40%;
                            left: 50%;
                            transform: translate(-50%, -50%) rotate(45deg);
                            height: 8px;
                            width: 4px;
                            border-right: 1px solid $color-white;
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }        

        .navigation-utility__userRoleBtn {            
            @include font-graphik-regular(16px);
            border-radius: 0;
            padding: 16px 32px;
            background: $color-link;
            color: $color-white;
            cursor: pointer;

            &:disabled {
                opacity: 0.3;
            }
        }
    }

    &.is-edit {
        display: block;

        li,
        .navigation-utility__mobileBottom,
        .navigation-utility__submenu {
            display: block !important;
            position: relative !important;
            height: auto !important;
        }
    }
}