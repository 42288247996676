.twitter-promo
{
    display: block;
    position: relative;
    padding-top: 40px;
    a
    {
        display: block;
        padding-left: 35px;
        &:before
        {
            display: block;
            position: absolute;
            top: 45px;
            left: -5px;
            font-size: 32px;
            color: #55acee;
        }
    }
    .twitter-follow-us, .twitter-handle
    {
        font-family: $font-primary;
        display: block;
    }
    .twitter-follow-us
    {
        font-size: 15px;
        line-height: 20px;
    }
    .twitter-handle
    {
        font-size: 13px;
        line-height: 15px;
    }
}
.social-media-linkedin {
    display:inline-block;
    background: #1A79B4;
    height: 60px;
    width: 60px;
    border-radius: 60px;
    color:#fff;
    text-align: center;
    line-height: 60px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    &:hover{
        text-decoration: none;
    }
    span {
        font-size:32px;
    }
}
.social-media-twitter {
    display:inline-block;
    background: #59AEED;
    height: 60px;
    width: 60px;
    border-radius: 60px;
    color:#fff;
    text-align: center;
    line-height: 60px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    &:hover{
        text-decoration: none;
    }
    span {
        font-size:32px;
    }
}
