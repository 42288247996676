body {
	font-family: $font-primary;
	color: $color-primary;
	font-size: 15px;
	background-color: white;
	margin: 0;
}

h1 {
	font-size: 30px;
	line-height:35px;
	margin-left: -3px;
	font-family: $font-primary;
	color: #000000;
	font-weight:normal;
}
h2 {
	font-size:20px;
	line-height:25px;
	font-family: $font-primary-bold;
	font-weight:normal;
	color: #000000;
	margin-top: 40px;
	margin-bottom: 10px;
}
h3 {
	font-size:20px;
	line-height:25px;
	font-family: $font-primary-bold;
	color: #000000;
	font-weight:normal;
	margin-top: 30px;
	margin-bottom: 10px;
}
h4 {
	font-size:20px;
	line-height:25px;
	font-family: $font-primary-bold;
	font-weight:normal;
	color: #000000;
	margin-top: 30px;
	margin-bottom: 10px;
}
h5 {
	font-size: $font-size;
	line-height:20px;
	font-family: $font-primary-bold;
	font-weight:normal;
	color: #000000;
	margin-top: 20px;
	margin-bottom: 5px;
}
h1,h2,h3,h4,h5 {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	letter-spacing:-1px;
	a:link, a:visited {
	   color: $color-primary;
	}
	&:first-child {
		margin-top:0;
	}
}
p {
	font-size: $font-size;
	line-height:20px;
	font-family: $font-primary;
	margin-bottom: $font-size;
}
strong, b {
	font-family: $font-primary-bold;
	font-weight:normal;
}

blockquote {
	font-family: $font-secondary;
	color:#7c7c7c;
	padding: 10px;
	margin-left: -10px;
	font-size:25px;
	line-height:30px;

	&:before {
		position: absolute;
		display: inline-block;
		content: '“';
		margin-left: -15px;
	}
	&:after {
		position: absolute;
		display: inline-block;
		content: '”';
		margin-left: 0;
	}
	&.quote-small {
		font-size:20px;
		line-height:35px;
	}
}
.source {
	font-family: $font-primary;
	font-size: 13px;
	line-height:20px;
	color: $color-03;
}

.reference {
	font-family: $font-primary;
	font-size: 13px;
	line-height: 1.2;
	color: $color-03;
}

.brandname {
	font-family: $font-primary;
}

ul {
	margin: 20px 0px;
	padding: 0px 40px;
		&.no-space{
			padding: 0;
		}
		li {
			list-style-image: url("../images/list-item.png");
			line-height: 20px;
			margin-bottom: 5px;
			}
		&.no-bullets {
			li {
				list-style: none;
				&:before {
					content: "";
				}
			}

		}
		&.no-space{
		   li{
			   margin-left: 0;
		   }
		}
}

ol {
	margin:20px 0px;
	padding: 0px 40px;
		li {
			list-style: none;
			line-height: 20px;
			margin-bottom: 5px;
			list-style-type: decimal;
		}
}

a {
	color: $color-link;
	text-decoration: none;

	&.plain{
		color: $color-primary;
	}

	&.action{
		display:block;
		float:right
	}

	&:hover {
		text-decoration: underline;
		&:before
		{
			text-decoration:none;
			display:inline-block;
		}
	}

	&:before {
		text-decoration: none;
	}
	&.icon-external, &.icon-pdf, &.icon-pdf-document, &.icon-blank, &.icon-spreadsheet, &.icon-doc, &.icon-new-window, &.icon-link, &.icon-xl, &.icon-xl-document, &.icon-word, &.icon-word-document
	{
		&:before{
			color:$color-03;
			padding-right:5px;
		}
	}
}

sup {
  line-height: 0;
}

dl {
	dt, dd {
		line-height: 1.3;
		margin-bottom: 15px;

		:lang(de) & {
			word-wrap: break-word;
			overflow-wrap: break-word;
			-webkit-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
		}
	}

	//dt and dd side by side
	&.inline {
		@extend .clearfix;

		dt, dd {
			width: 48%;
			display: inline-block;
			vertical-align: top;
		}
	}
}

.color {
	&-font {
		&-01 {
			color: $color-01;
		}
		&-02 {
			color: $color-02;
		}
		&-03 {
			color: $color-03;
		}
		&-04 {
			color: $color-04;
		}
		&-021 {
			color: $color-021;
		}
		&-108 {
			color: $color-108;
		}
		&-000 {
            color: $color-primary;
        }
		&-226 {
			color: $color-226;
		}
		&-253 {
			color: $color-253;
		}
		&-266 {
			color: $color-266;
		}
		&-299 {
			color: $color-299;
		}
		&-300 {
			color: $color-300;
		}
		&-327 {
			color: $color-327;
		}
		&-354 {
			color: $color-354;
		}
		&-376 {
			color: $color-376;
		}
		&-485 {
			color: $color-485;
		}
		&-871 {
			color: $color-871;
		}
		&-2617 {
			color: $color-2617;
		}
		&-2748 {
			color: $color-2748;
		}
		&-3262 {
			color: $color-3262;
		}
	}
	&-background {
		&-01 {
			background-color: $color-01;
		}
		&-02 {
			background-color: $color-02;
		}
		&-03 {
			background-color: $color-03;
		}
		&-04 {
			background-color: $color-04;
		}
		&-021 {
			background-color: $color-021;
		}
		&-108 {
			background-color: $color-108;
		}
		&-226 {
			background-color: $color-226;
		}
		&-253 {
			background-color: $color-253;
		}
		&-266 {
			background-color: $color-266;
		}
		&-299 {
			background-color: $color-299;
		}
		&-300 {
			background-color: $color-300;
		}
		&-327 {
			background-color: $color-327;
		}
		&-354 {
			background-color: $color-354;
		}
		&-376 {
			background-color: $color-376;
		}
		&-485 {
			background-color: $color-485;
		}
		&-871 {
			background-color: $color-871;
		}
		&-2617 {
			background-color: $color-2617;
		}
		&-2748 {
			background-color: $color-2748;
		}
		&-3262 {
			background-color: $color-3262;
		}
	}
	&-border {
		&-01 {
			border-color: $color-01;
		}
		&-02 {
			border-color: $color-02;
		}
		&-03 {
			border-color: $color-03;
		}
		&-04 {
			border-color: $color-04;
		}
		&-021 {
			border-color: $color-021;
		}
		&-108 {
			border-color: $color-108;
		}
		&-226 {
			border-color: $color-226;
		}
		&-253 {
			border-color: $color-253;
		}
		&-266 {
			border-color: $color-266;
		}
		&-299 {
			border-color: $color-299;
		}
		&-300 {
			border-color: $color-300;
		}
		&-327 {
			border-color: $color-327;
		}
		&-354 {
			border-color: $color-354;
		}
		&-376 {
			border-color: $color-376;
		}
		&-485 {
			border-color: $color-485;
		}
		&-871 {
			border-color: $color-871;
		}
		&-2617 {
			border-color: $color-2617;
		}
		&-2748 {
			border-color: $color-2748;
		}
		&-3262 {
			border-color: $color-3262;
		}
	}
}

/* Tablet and Desktop */
@media only screen and (min-width: $media-min-width-tablet) {
	h1 {
		font-size:45px;
		line-height:50px;
	}
	h2 {
		font-size:30px;
		line-height:35px;
		margin-bottom: 20px;
	}
	h3 {
		font-size:25px;
		line-height:30px;
	}
	blockquote {
		font-size:30px;
		line-height:35px;
		&.quote-small {
			font-size:25px;
			line-height:30px;
		}
	}
}
