.fund-detail-page {

	#enhancedIndexFundTextHighlight p{
		font-size: 25px;
		line-height: 25px;
	}
	.accordion-content {
		#fundslibrary-root {
			header
			{
				padding:0px;
				min-height:initial;
			}
			ul li {
				list-style: none;
			}
		
			input[type=text], label {
				font-size: 15px;
				font-family: InvescoInterstateLight, Helvetica, sans-serif;
				font-weight: normal;
			}
		}
	}
	#tick-list
	{
		ul
		{
			margin:0px;
			padding:0px;
		 	li 
		 	{
		 		@extend .icon-tick;
		 		padding-bottom:15px;
		 		&:before
		 		{
			 		border: 1px solid $color-primary;
			 		border-radius:10px;
			 		font-size:15px;
			 		padding:2px;
			 		font-family:$font-icons;
			 		margin-right:10px;
		 		}
		 		list-style-type:none;
		 		list-style-image:none;
		 	}
		}
	}
}