.team
{
	border-top: 2px solid $color-04;
	padding-top: 10px;
	clear: both;
	margin-bottom: 40px;
	&-img-container
	{
		width:100%;
		height:auto;
		img
		{
			max-width:100%;
			height:auto;
			max-height:68px;
			width:auto;
			display:block;
			border-radius:34px;
			& + a
			{
				display:block;
				padding-top:5px;
				padding-bottom:10px;
				padding-right:30px;
				color:$color-04;
				&:hover
				{
					cursor:pointer;
				}
			}
		}
	}
	&-summary
	{
		margin-top:20px;
		color:$color-04;
	}
		&-clear
		{
		 clear:both;
	}
}
.team-members-group
{
	&-title
	{
		font-size:20px;
		margin-bottom:30px;
		span
		{
			font-size:45px;
			margin-right:5px;
		}
	}
	&-member
	{
		width:17%;
		display:inline-block;
		margin-left:20px;
		margin-bottom: 15px;
		line-height: 20px;
		vertical-align: top;

		/* Desktop */
		@media only screen and (max-width: $media-min-width-desktop) {
			width:180px;
		}

		&-purple:before
		{
			background-color:#AD29A3;
		}
		&-dark-blue:before
		{
			background-color:#2454CF;
		}
		&-light-blue:before
		{
			background-color:#279CF7;
		}
		&-green:before
		{
			background-color:#25C1B4;
		}
		&:before
		{
			content:"";
			display:inline-block;
			border-radius:6px;
			width:12px;
			height:12px;
			margin-left:-20px;
			padding-right:2px;
		}
		&-title {
			min-height: 20px;
		}
	}
}

/* Tablet */
@media only screen and (min-width: $media-min-width-tablet) and (max-width: $media-max-width-tablet) {

	.team
	{
		&-img-container
		{
			width:300px;
			float:left;
		}
		 &-summary
		 {
			 width:320px;
			 float:right;
			 margin-top:0px;
		}
	}
}

/* Desktop */
@media only screen and (min-width: $media-min-width-desktop) {

	.team
	{
		&-img-container
		{
			width:300px;
			float:left;
		}
		 &-summary
		 {
			 width:400px;
			 float:right;
			 margin-top:0px;
		}
	}
}
