.figure {
	position: relative;
	overflow: auto;
	margin-bottom:10px;
	background: #ebebeb;
    border-top: 2px solid #000000;
    padding: 10px;
    margin-bottom: 40px;
    h2, h3, h4, h5 {
		font-size: 15px;
		line-height: 20px;
	margin-top: 0px;
	}
	.mobile-image
	{
		display:block;
	}
	.desktop-image
	{
		display:none;
	}
	.source {
		margin-top:10px;
		margin-bottom: 0px;
	}
	.figure-s {
		width:300px;
	}
	.figure-m {
		width:560px;
	}
	.figure-l {
		width:640px;
	}
	.figure-xl {
		width:740px;
	}
	@media only screen and (min-width: $media-min-width-tablet) {
		overflow: inherit;
		margin-bottom:0px;
		.mobile-image
		{
			display:none;
		}
		.desktop-image
		{
			display:block;
		}
	}
	.full-width {
		img { // full-width checked
			max-width: 780px;
		}
		&.scroll-icon:before
		{
		    content: "";
		    display: block;
		    position: absolute;
		    background: rgba(0, 0, 0, 0.2) url("../images/swipe@2x.png") no-repeat 52% 11%;
		    min-height: 160px;
		    height: 100%;
		    width: 100%;
		    background-size: 60%;
		    top: 0px;

			@media only screen and (min-width: $media-min-width-tablet) {
				display: none;
			}
		}
	}
	img { // default
		max-width: 100%;
	    height: auto;
	}
}
