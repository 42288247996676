@import '//repo.invesco.com/assets/etech/fonts/graphik/graphik-fonts.css';
@import '//repo.invesco.com/assets/etech/fonts/font-awesome.min.css';

$ext-fonts-path: '//repo.invesco.com/assets/etech/fonts/';
$graphik-web-path: "../../app/fonts/graphik-web/";

@font-face {
    font-family: 'InvescoEditor';
    src: url($ext-fonts-path + 'InvescoEditor-Regular.eot');
    src: url($ext-fonts-path + 'InvescoEditor-Regular.eot?#iefix') format('embedded-opentype'),
         url($ext-fonts-path + 'InvescoEditor-Regular.woff') format('woff');
}

@font-face {
    font-family: "Graphik";
    src: url($graphik-web-path + "Graphik-Regular-Web.woff2") format("woff2"),
         url($graphik-web-path + "Graphik-Regular-Web.woff") format("woff");
    font-style: normal;
    font-weight: 400;
    font-display: swap
}

@font-face {
    font-family: "Graphik";
    src: url($graphik-web-path + "Graphik-RegularItalic-Web.woff2") format("woff2"),
         url($graphik-web-path + "Graphik-RegularItalic-Web.woff") format("woff");
    font-style: italic;
    font-weight: 400;
    font-display: swap
}

@font-face {
    font-family: "Graphik";
    src: url($graphik-web-path + "Graphik-Medium-Web.woff2") format("woff2"),
         url($graphik-web-path + "Graphik-Medium-Web.woff") format("woff");
    font-style: normal;
    font-weight: 500;
    font-display: swap
}

@font-face {
    font-family: "Graphik";
    src: url($graphik-web-path + "Graphik-MediumItalic-Web.woff2") format("woff2"),
         url($graphik-web-path + "Graphik-MediumItalic-Web.woff") format("woff");
    font-style: italic;
    font-weight: 500;
    font-display: swap
}

@font-face {
    font-family: "Graphik";
    src: url($graphik-web-path + "Graphik-Semibold-Web.woff2") format("woff2"),
         url($graphik-web-path + "Graphik-Semibold-Web.woff") format("woff");
    font-style: normal;
    font-weight: 600;
    font-display: swap
}

@font-face {
    font-family: "Graphik";
    src: url($graphik-web-path + "Graphik-SemiboldItalic-Web.woff2") format("woff2"),
         url($graphik-web-path + "Graphik-SemiboldItalic-Web.woff") format("woff");
    font-style: italic;
    font-weight: 600;
    font-display: swap
}