.dialog a {
	width:  100%;
}

a {
	&.link-no-underline {
		&:hover {
			text-decoration: none;
		}
	}
}