@mixin font-graphik-regular($size: 14px, $weight: 400) {
    font-family: 'graphik-regular', Arial, sans-serif;
    font-size: $size;
    line-height: 22px;
    font-weight: $weight;
}

@mixin font-graphik-medium($size: 14px, $weight: 500) {
    font-family: 'graphik-medium', Arial, sans-serif;
    font-size: $size;
    line-height: 22px;
    font-weight: $weight;
}

@mixin font-graphik-h3 {
    font-family: 'graphik-medium', Arial, sans-serif;
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;

    @media screen and (max-width: $media-aem-tablet-max) {
        font-size: 24px;
        line-height: 32px;
    }
}

@mixin font-graphik-h4 {
    font-family: 'graphik-medium', Arial, sans-serif;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;

    @media screen and (max-width: $media-aem-tablet-max) {
        font-size: 20px;
        line-height: 28px;
    }
}

@mixin font-graphik-h5 {
    font-family: 'graphik-medium', Arial, sans-serif;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
}

@mixin font-graphik-h6 {
    font-family: 'graphik-medium', Arial, sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

@mixin editor-h1 {
    font-family: 'InvescoEditor', Arial, sans-serif;
    font-size: 48px;
    line-height: 56px;

    @media screen and (max-width: $media-aem-tablet-max) {
        font-size: 40px;
        line-height: 48px;
    }
}

@mixin editor-h2 {
    font-family: 'InvescoEditor', Arial, sans-serif;
    font-size: 40px;
    line-height: 48px;

    @media screen and (max-width: $media-aem-tablet-max) {
        font-size: 32px;
        line-height: 40px;
    }
}
