.targetMarketBox {
	padding: 10px;
    margin-bottom: 20px; 
    display:flex;
    
    @media only screen and (max-width: $media-max-width-mobile) {
    	display: block;
    }

	.targetMarketBoxSide {
		width: 50%;
		@media only screen and (max-width: $media-max-width-mobile) {
    		width: 100%;
	    }
	}

	ul {
	    margin: 0;
		padding: 0 24px 0 38px;
		font-size: .85rem;
		
		li {
			font-size: 15px;
		}
	}
	
	.compatibleFor,
	.notCompatibleFor {
		position: relative;
		margin-left: 36px;
		font-size: 16px;

		&:before {
			position: absolute;
			height: 24px;
			width: 24px;
			left: -28px;
    		top: -4px;
		}
	}

	.compatibleFor {
		&:before {
			content: url("../images/targetMarketBox/greencheck.png");
		}
	}

	.notCompatibleFor {
		&:before {
			content: url("../images/targetMarketBox/redcross.png");
		}
	}

}