.checklist {
	ul {
	width:100%;
	margin:0px;
	padding:0px;
	position: relative;
	}
	li {
		margin:0px;
		padding:0px;
		min-height: 80px;
		list-style-image: none;
  		list-style-type: none;
  		margin-left:50px;
  		margin-bottom: 20px;
  		span {
  			border: 1px solid black;
  			height: 40px;
  			width: 40px;
  			font-size: 32px;
  			position: absolute;
  			left: 0px;
  			line-height: 40px;
  			text-align: center;
  			border-radius: 40px;
  			margin-right: 20px;
  			vertical-align: top;
  		}
		p {
			padding-top: 10px;
		}
	}
}
