.factbox {
	width: 240px;
	margin-top: 25px;
	margin-right: 20px;
	display: inline-block;
	vertical-align: top;
	.subheading {
		border-top: 1px solid #000;
		color: $color-03;
		font-size: 15px;
		padding-top: 5px;
		min-height: 65px;
	}
	h2 {
		padding-top: 0px;
		margin: 0px;
		font-family: $font-primary;
		font-size: 60px;
		line-height: 65px;
		color: #1a54cf;
	}
	h3 {
		margin-top: 0;
		min-height: 60px;
	}
	img {
	    width: 100%;
		margin-bottom: 12px;
	}
	&-primary {
		h2 {
			color: #021f79;
		}
	}
	&-last {
		margin-right: 0px;
		h2 {
			color: #189cf6;
		}
	}
}
