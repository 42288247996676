.dialog-wrap{
    position: relative;
}
.dialog {
    display: none;
    position: absolute;
    z-index: 200;
    background-color:#000000;
    color:#ffffff;
    padding: 20px 20px 16px 20px;
    width: $dialog-width;
    margin-left: calc(($dialog-width / 2 + 10px) * -1);
    left: 45%;
    
    @media only screen and (min-width: $media-min-width-tablet) and (max-width: $media-max-width-tablet) {
        left: 50%;
    }
    @media only screen and (min-width: $media-min-width-desktop) {
        left: 50%;
    }
    top: 100%;

    &-grey.dialog
    {
        background-color: #f1f1f1;
        &:after
        {
            border-bottom: 10px solid #f1f1f1;
        }
    }

    &:after{
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        left: 47%;
        top: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #000;
    }

    button{
       width: 100%;
       &:after {
           display: none;
       }
    }
}