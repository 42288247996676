#navmain-search{
    &:focus{
        background-color: rgba(255,255,255,0.15);
    }
}
.autocomplete-results-list{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 45px;
    display: none;
    z-index: 40;
    background-color: #222;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
    
    
    .autocomplete-results-container{
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .autocomplete-results-category{
        &:not(:first-child)
        {
            border-top: 1px solid #fff;
        }     
    }
    
    .autocomplete-viewall-results{
        background: rgba(250,250,250,0.1);
        text-align:center;
    }
    
    h5{
        font-size: 16px;
        line-height: 1.2;
        font-weight: 400;
        margin: 10px 0px 10px 10px;
        color: #fff
    }
    ul,
    li{
        display: block;
        border-width: 0;
    }
    li{
        margin: 0;
        padding: 0;
        line-height: 1;
        &:before
        {
            content:"";
        }
        a:hover
        {
            cursor:pointer;
        }
    }
}