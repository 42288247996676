.contact-piece {
	table {
		margin-bottom: 10px;
	}

	&:nth-of-type(1) {
		margin-bottom: 40px;
	}

	&:nth-of-type(2) {
		margin-bottom: 25px;
	}
}
