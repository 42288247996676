.person {
    display:block;
    position:relative;
    min-height:85px;
    border-top: 1px solid #ccc;
    padding: 10px;
    white-space: nowrap;


    a {
        &:hover {
            text-decoration: none;
        }
    }

    &.no-link {
        span.icon-right {
            display: none;
        }
        &:hover {
            background-color: transparent;
        }
    }

    &:hover {
        background-color:#f7f7f7;
        text-decoration:none;
    }
    p {
        color:black;
    }
    img{
          height: 68px;
          width: auto;
          border-radius: 34px;
    }
    h2{
        font-size:15px;
        line-height: 30px;
        margin: 0;
    }
    &-group {
        &:first-of-type {
            margin-top: 0;
        }
    }
    &-image {
        display: block;
        width: 100%;
        white-space: normal;
        vertical-align: top;
    }
    &-name {
        width: 100%;
        padding-top: 6px;
        padding-left: 0;
        vertical-align: top;
        white-space: normal;
        vertical-align: top;
        display: block;

        p {
            padding-right: 0;
        }
    }
    &-meta-data {
        margin-top:10px;
        width: 100%;
        white-space: normal;
        vertical-align: top;
        p {
            margin: 4px 0;
        }
    }
    span.icon-right {
        border:1px solid #cccccc;
        border-radius: 30px;
        height:30px;
        width:30px;
        line-height:30px;
        position:absolute;
        top:10px;
        right:10px;

        &:before {
            margin-left: 7px;
            color:#0014D2;
        }
    }
}

@media only screen and (min-width: $media-min-width-tablet) {
    .person {
        padding: 10px 0;
        &-image {
            width: 10%;
            display: inline-block;
            margin-left: 10px;
        }
        &-name {
            padding-left: 20px;
            width: 30%;
            display: inline-block;
            p {
                padding-right: 20px;
            }
        }
        &-meta-data {
            display: inline-block;
            width: 60%;
        }
        span.icon-right {
            display:block;
        }
    }
}
