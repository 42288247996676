.navtop
{
	display: none;
	.icon,
	[class^="icon-"],
	[class*=" icon-"] {
		&:not(.status-indicator):not(.editorIcon) {
			&:before {
				line-height: 0.6;
			}
		}
	}
}

@media only screen and (min-width: $media-min-width-desktop) {
	#wrapper-navtop {
		background-color: #333;
	}
	.navtop
	{
		display: block;
		height: 50px;
		max-width:1280px;
		margin:auto;

		a
		{
			color:#fff;
		}
		&-actions
		{
			display: inline-block;
			float: left;
			line-height: 50px;
			.user-link
			{
				display: inline-block;
				.icon-left
				{
					margin-right: 10px;
					color: $color-white;
					border: 1px solid $color-white;
					border-radius: 50%;
					padding: 8px 8px 7px;
				}
			}
			a
			{
				position: relative;
				box-sizing: border-box;
				display: inline-block;
				text-decoration: none;
				width:auto;
				padding-right:10px;
				padding-left:10px;
				&:hover
				{
					background-color: #000;
				}

				&.inline-icon 
				{
					padding-right: 22px;
					padding-left: 22px;
				
					.inline-icon-left,
					.inline-icon-right 
					{
						&:before 
						{
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
						}
					}
					.inline-icon-left 
					{
						&:before 
						{
							left: 4px;
						}
					}
					.inline-icon-right 
					{
						&:before 
						{
							right: 4px;
						}
					}
				}
			}
		}
		&-audience
		{
			float: right;
			.dialog-wrap
			{
				display: inline-block;
				float: left;
				line-height: 50px;
			}
			.dialog-wrap > a
			{
				display: block;
				box-sizing: border-box;
				padding-left: 0px;
				font-size: 15px;
				text-align: center;
				line-height: 50px;
				text-decoration: none;
				padding: 0 10px;
				min-width: 160px;

				&.active, &:hover
				{
					background-color: #000;
				}
			}
		}
	}
}
