.primary-footer__links {
    overflow: hidden;
    padding: 0;

    .primary-footer__link {
        float: left;
        color: $color-white;

        &:not(:last-of-type):after {
            content: '|';
            padding: 0 1rem;
        }

        a {
            color: $color-white;
        }
    }

    #ot-sdk-btn.ot-sdk-show-settings {
        color: $color-white;
        font-size: inherit;
        text-decoration: none;
        border: none;
        padding: 0;
        line-height: inherit;
        -moz-transition: none;
        -o-transition: none;
        -webkit-transition: none;
        transition: none;
        
        &:hover {
            color: $color-white;
            text-decoration: underline;
            background: none;
        }
    }
}