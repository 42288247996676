@media only screen and (max-width: $media-min-width-desktop) {	
	.stickynav
	{
		padding-left:10px;
		overflow-x:auto;
		position:fixed;
		background-color:$color-04;
		width:100%;
		left:0px;
		white-space:nowrap;
		z-index:1;
		top:71px !important;
		display:inline;
		-ms-overflow-style: none;
		&::-webkit-scrollbar 
		{
			display:none;
		}
		&-active-bar
		{
			border-top: 3px solid #0014D2;
	    	position: absolute;
	    	top:37px;
	    	@include slideinslideoutleft;
		}
		&-scrolling
		{
			top:0px;
		}
		ul
		{
			margin:0px;
			padding:0px;
			> li
			{
				list-style-image:none;
				list-style-type:none;
				display:inline-block;
				margin-right:10px;
				margin-left:10px;
				margin-bottom:0px;
		    	a
		    	{
				    color: white;
				    height:30px;
				    padding-top:10px;
				    display:inline-block;
			    	&:hover
			    	{
			    		text-decoration:none;
			    		cursor:pointer;
			    	}		       	
		    	}
				span
				{
					display:none;
				}
		    	&:last-child a
		    	{
		    		padding-right:10px;
		    	}
			}
		}
	}
}

@media only screen and (min-width: $media-min-width-desktop){
	.stickynav
	{
		display:block;
		width:140px;
		&-overflow
		{
			display:none;
		}
		&-fixed
		{
			position:fixed;
			top:143px;
			z-index:1000;
			margin-top:0px !important;
		}
		&-active-bar
		{
			display:none;
		}
		ul
		{
			list-style-type:none;
			padding:0px;
			margin:0px;
			li
			{
				padding-top:14px;
				padding-bottom:10px;
				margin:0px;
				list-style-image:none;
				position:relative;
				cursor:pointer;
				&:last-child
				{
					border-bottom: 1px solid $color-04;
				}
				border-top: 1px solid $color-04;
				a
				{
				    color: black;
				    display: inline-block;
				    width: 88%;
				    overflow:hidden;
					&:hover
					{
						text-decoration:none;
						cursor:pointer;
					}
				}
				a.active
				{
					font-family:InvescoInterstateBold,Verdana,Arial,sans-serif;
				}
				span
				{
					float:right;
				}
			}
		}
		&-hover.stickynav-gradient
		{
	    	&:after
	    	{
			    width: 0px;
	    	}
		}
		&-hover
    	{
    		//width:220px;
    		z-index:1000;
    		position:relative;
    		background-color:white;
    		margin-bottom:-1px !important;
    		border-bottom:1px solid black;
    	}
		&-gradient
		{
	    	&:after
	    	{
				content: "";
			    position: absolute;
			    width: 70px;
			    height: 100%;
			    top: 0px;
			    right: 13px;
			    background-image: -webkit-linear-gradient(right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
			    background-image: -moz-linear-gradient(right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
			    background-image: -ms-linear-gradient(right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
			    background-image: -o-linear-gradient(right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
			    background-image: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
	    	}
		}
	}
}