.primary-footer {
    background: $color-navy-blue;
    padding: 40px 0 80px;
    position: relative;
    z-index: 12;

    @media only screen and (min-width: $media-md-min) {
        padding: 60px 0 120px;
    }

    .primary-footer__inner {
        width: 100%;
        max-width: 1120px;
        margin: 0 auto;
        padding-bottom:  2rem;
        box-sizing: border-box;

        @media only screen and (max-width: $media-aem-tablet-max) {
            padding: 0 48px;
        }

        @media only screen and (max-width: $media-sm-max) {
            padding: 0 20px 2rem;
        }

        h3,
        a,
        p {
            color: $color-white;
            font-family: 'graphik-regular', Arial, sans-serif;
        }

        ul {
            list-style: none;
            padding: 0;
    
            li {
                list-style-image: none;
            }
        }
    }

    .primary-footer__main {
        @media only screen and (min-width: $media-lg-max) {
            display: flex;
            justify-content: space-between;
        }
    }

    .primary-footer__content {
        flex: 1 0 0;
    }

    .primary-footer__social {
        margin-top: 50px;

        @media only screen and (min-width: $media-lg-max) {
            width: 25%;
            margin-left: 100px;
            margin-top: 0;
        }
    }
}