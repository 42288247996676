.award {
	border-top: 2px solid $color-04;
	padding-top: 2px;
	clear: both;
	margin-bottom: 40px;
		h3:after {
			@include icon-right-before;
			font-family: $font-icons;
			color: #0014D2;
			float: right;
			cursor: pointer;
			font-size: 15px;
		}
		h3 a {
			color:$color-primary;
		}
		&-img-container {
			max-width:300px;
			width:300px;
			height:180px;
			border:1px solid $color-02;
			text-align:center;
			box-sizing: border-box;
			margin-bottom: 10px;
			margin-top: 40px;
				img {
					max-width:300px;
					max-height:180px;
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					}
				}
		&-summary {
 			width: 100%;
 			display: block;
			float: none;
			clear: both;
			min-height: 130px;
				&-date {
					color: $color-03;
				}
		}
 		&-clear {
			 clear:both;
		}
}

/* Tablet */
@media only screen and (min-width: $media-min-width-tablet) and (max-width: $media-max-width-tablet) {

.award {
	padding-top:10px;
		&-img-container {
			float:left;
			margin-top: 0px;
			img {
				margin-bottom:10px;
				max-width:240px;
			}
		}
		h3:after {
			display:none;
		}
		h3 {
			float:right;
			width:300px;
			text-align:left;
		}
	 	&-summary {
			width: 300px;
			float: right;
			min-height: 130px;
			clear:none;
		}
	}
}

/* Desktop */
@media only screen and (min-width: $media-min-width-desktop) {

.award {
	padding-top:10px;
		h3:after {
			display:none;
		}
		&-img-container {
			float:left;
			margin-top: 0px;
		}
		h3 {
			float:right;
			width:400px;
			text-align:left;
		}
		&-summary {
			width: 400px;
			float: right;
			min-height: 130px;
			clear:none;
		}
	}
}
