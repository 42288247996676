
.panel-icon {
	display: inline-block;
	width: 240px;
	margin-top: 20px;
	margin-right: 16px;
	vertical-align: top;
	a {
		color: $color-primary;
		text-decoration: none;
		&:hover {
			h4, span {
				color: $color-link;
				border-color: $color-link;
			}
		}
	}
	h4 {
		margin-top: 20px;
		padding-top: 5px;
		border-top: 1px solid $color-primary;
	}
	&-graphic {
		display: block;
		width: 140px;
		font-size: 140px;
		line-height: 140px;
		margin-left: auto;
		margin-right: auto;
	}
}