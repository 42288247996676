.navigation-mega {
    display: none;
    position: absolute;
    top: 99px;
    left: 0;
    width: 100%;
    background-color: $color-light-gray;
    z-index: 2;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.10);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.10);

    @media screen and (max-width: $media-aem-tablet-max) {
        top: 0;
        height: 100%;
        background-color: $color-white;
    }

    &.is-edit {
        position: initial;
        display: block;
    }

    .container-extend {
        @media screen and (max-width: $media-aem-tablet-max) {
            padding-bottom: 75px;
            overflow: auto;
        }
    }

    .navigation-mega-grid {
        padding: 47px 0;

        @media screen and (max-width: $media-aem-tablet-max) {
            padding: 44px 0 15px;
            background-color: $color-white;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @media screen and (max-width: $media-sm-max) {
            padding: 34px 12px 26px;
        }

        .navigation-mega-title {
            display: none;
            font-family: 'graphik-regular', Arial, sans-serif;
            margin-bottom: 24px;
            font-size: 24px;
            font-weight: bold;

            @media screen and (max-width: $media-aem-tablet-max) {
                display: block;
                position: relative;
                width: calc(100% - 212px);
                letter-spacing: normal;
                margin-bottom: 58px;
            }

            @media screen and (max-width: $media-sm-max) {
                width: calc(83.33333% - 14.66667px);
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                margin-bottom: 15px;
                line-height: 28px
            }

            i {
                @media screen and (max-width: $media-aem-tablet-max) {
                    display: block;
                    position: absolute;
                    left: -19px;
                    margin-left: auto;
                    font-size: 28px;
                    line-height: 22px;
                    top: 5px;
                }

                @media screen and (max-width: $media-sm-max) {
                    top: 2px;
                }
            }
        }

        .navigation-mega-item {
            overflow: hidden;

            @media screen and (max-width: $media-aem-tablet-max) {
                width: calc(100% - 212px);
            }

            @media screen and (max-width: $media-sm-max) {
                width: calc(83.33333% - 14.66667px);
                margin-top: 20px;
            }
        }

        .navsub {
            width: calc(25% - 24px);
            float: left;
            margin-right: 32px;

            @media screen and (max-width: $media-aem-tablet-max) {
                width: calc(50% - 16px);
                margin-right: 16px;

                &:nth-child(2n) {
                    margin-right: auto;
                    margin-left: 16px;
                }
            }

            @media screen and (max-width: $media-sm-max) {
                width: 100%;
                margin-right: auto;
                margin-left: auto;

                &:nth-child(2n) {
                    margin-left: auto;
                }
            }
    
            h3 {
                padding: 0 0 14px 0;
                border: none;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: normal;
                line-height: 22px;

                @media screen and (min-width: $media-aem-desktop-min) {
                    min-height: 36px;
                }

                @media screen and (max-width: $media-aem-tablet-max) {
                    padding: 0 0 24px 0;
                }

                @media screen and (max-width: $media-sm-max) {
                    padding-bottom: 20px;
                }
            }
    
            ul {
                margin: 0 0 48px;

                @media screen and (max-width: $media-aem-tablet-max) {
                    margin-bottom: 80px;
                }

                @media screen and (max-width: $media-sm-max) {
                    margin-bottom: 48px;
                }
            
                > li {
                    margin-bottom: 25px;

                    @media screen and (max-width: $media-aem-tablet-max) {
                        opacity: 0;
                        transition: all ease-in-out 0.3s;
                        margin-bottom: 24px;
        
                        &.list-active {
                            opacity: 1;
                        }
                    }

                    @media screen and (max-width: $media-sm-max) {
                        margin-bottom: 16px;
                    }
    
                    a {
                        color: $color-primary;
                        padding: 0;
                        font-weight: normal;
                        line-height: 24px;

                        @media screen and (max-width: $media-aem-tablet-max) {
                            display: inline;
                            font-size: 18px;
                            line-height: 28px;
                        }

                        @media screen and (max-width: $media-sm-max) {
                            font-size: 16px;
                            line-height: 24px;
                        }

                        &:hover {
                            content: '';
                            color: $color-link-blue;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .megaArticle {
            width: 25%;
            float: right;
            box-sizing: border-box;

            @media screen and (max-width: $media-aem-tablet-max) {
                width: 100%;
            }

            a {
                display: block;
                color: $color-primary;

                &:hover {
                    text-decoration: none;
                }

                @media screen and (max-width: $media-aem-tablet-max) and (min-width: $media-sm-min) {
                    display: flex;
                    justify-content: space-between;
                }
            }

            .megaArticle-featured {
                display: none;

                @media screen and (max-width: $media-aem-tablet-max) {
                    display: block;
                    margin-bottom: 20px;
                    font-family: 'graphik-medium', Arial, sans-serif;
                    font-size: 14px;
                }
            }

            .megaArticle-image {
                @media screen and (max-width: $media-aem-tablet-max) {
                    margin-bottom: 20px;
                }

                @media screen and (max-width: $media-aem-tablet-max) and (min-width: $media-sm-min) {
                    width: calc(50% - 16px);
                }

                img {
                    width: 100%;
                }
            }

            .megaArticle-content {
                @media screen and (max-width: $media-aem-tablet-max) and (min-width: $media-sm-min) {
                    width: calc(50% - 16px);
                }

                h3 {
                    font-size: 18px;
                    font-family: 'graphik-medium', Arial, sans-serif;
                }
    
                p {
                    font-size: 16px;
                    line-height: 24px;
                    color: $color-primary;
                }
            }
        }
    }
}