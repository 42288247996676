.navigation-header {
    @media screen and (min-width: $media-aem-desktop-min) {
        align-self: flex-start;
        padding-top: 21px;
    }

    @media screen and (max-width: $media-aem-tablet-max) {
        width: calc(83.33333% - 44px);
        order: 1;
    }

    @media screen and (max-width: $media-sm-max) {
        width: calc(100% - 76px);
    }

    form {
        position: relative;
    }

    .navigation-header__searchBtn {
        cursor: pointer;     
        position: absolute;
        top: 45%;
        right: 8px;
        transform: translateY(-50%);
        background: url("../../joint-state/images/search--light.svg") no-repeat;
        width: 20px;
        height: 20px;

        @media screen and (max-width: $media-aem-tablet-max) {
            background-image: url("../../joint-state/images/search--black.svg");
            top: 55%;
            right: 14px;
        }
    }

    .navigation-header__search{
        border-bottom: 1px solid $color-white;
        background: transparent;
        font-family: 'graphik-regular', Arial, sans-serif;
        padding: 10px 35px 9px 0;
        font-size: 1rem;
        color: $color-white;
        border-radius: 0;
        width: 160px;
        transition: all ease-in-out 0.3s;
        top: 25px;
        right: 0;
        line-height: 24px;

        &:focus,
        &:active {
            width: 250px;
        }

        &.navigation-header__search--static {
            position: static;
        }

        @media screen and (max-width: $media-aem-tablet-max) {
            width: 100%;
            padding: 12px 30px 10px 16px;
            background-position: right 13px top 15px;
            border: 1px solid $color-primary;
            color: $color-primary;
            font-weight: 300;
            font-size: 15px;

            &:focus,
            &:active {
                width: 100%;
            }
        }
    }
}

.is-active,
.is-active-desktop,
.navigation-white {
    .navigation-header {
        .navigation-header__searchBtn {
            background-image: url("../../joint-state/images/search--black.svg") !important;
        }

        .navigation-header__search {
            border-bottom: 1px solid $color-primary;
            color: $color-primary;
        }

        input:-webkit-autofill {
            -webkit-text-fill-color: $color-primary !important;
            box-shadow: 0 0 0 1000px $color-white inset !important;
        }
    }
}

@media screen and (min-width: $media-aem-desktop-min) {
    .menu-style-1 {
        .navigation-header {
            #searchForm {
                width: 1px;
                height: 48px;
                .navigation-header__searchBtn {
                    top: 50%;
                    left: -115px;
                    transition: all ease-in-out 0.3s;
                }
                .navigation-header__search {
                    position: absolute;
                    top: 0;
                    padding: 12px 10px 12px 40px;
                    border: 1px solid transparent;
                    width: 130px;
                    &:focus,
                    &:active { 
                        width: 400px;
                        background-color: #fff;
                        border: 1px solid rgba(0,0,0,0.2) !important;
                        box-shadow: 0 24px 40px -10px rgba(0,0,0,0.2);
                        color: #000;
                    }
                    &:focus + .navigation-header__searchBtn,
                    &:active + .navigation-header__searchBtn {
                        background-image: url("../../joint-state/images/search--black.svg") !important;
                        left: -386px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $media-aem-tablet-max) {
    .menu-style-1 {
        .navigation-header {
            #searchForm {
                display: none;
            }
        }
    }
}


