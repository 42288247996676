.slider {
  position: relative;
  overflow: hidden;
  margin: 0px auto 0 auto;
  width:100%;
}

.slider ul {
  position: relative;
  margin: 0;
  padding: 0;
  height: 200px;
  list-style: none;
}

.slider ul li {
  position: relative;
  display: block;
  float: left;
  margin: 0;
  padding: 0;
  width: 460px;
  height: 220px;
  background: #ccc;
  text-align: center;
  line-height: 220px;
}

.slider ul li img {
  width: 100%;
}

a.control_prev, a.control_next {
  position: absolute;
  top: 40%;
  z-index: 999;
  display: block;
  padding: 0px;
  width: 40px;
  height: 50px;
  line-height: 50px;
  background: #fff;
  color: #000;
  text-decoration: none;
  text-align: center;
  font-size: 25px;
  opacity: 0.8;
  cursor: pointer;
}

a.control_prev:hover, a.control_next:hover {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
}

a.control_prev {
}

a.control_next {
  right: 0;
}

.slider_option {
  position: relative;
  margin: 10px auto;
  width: 160px;
  font-size: 18px;
}

.careers-location-image {
	display: none;
}

@media only screen and (max-width: $media-min-width-mobile) {
	.slider ul li {
	  position: relative;
	  display: block;
	  float: left;
	  margin: 0;
	  padding: 0;
	  width: 300px;
	  height: 140px;
	  background: #ccc;
	  text-align: center;
	  line-height: 220px;
	}

	a.control_prev, a.control_next {
		top: 35%;
	}
}

@media only screen and (min-width: $media-min-width-tablet) and (max-width: $media-min-width-desktop) {
	.slider ul li {
	  position: relative;
	  display: block;
	  float: left;
	  margin: 0;
	  padding: 0;
	  width: 620px;
	  height: 296px;
	  background: #ccc;
	  text-align: center;
	  line-height: 220px;
	}
}

@media only screen and (min-width: $media-min-width-desktop) {
	.careers-location-image {
		display: block;
	}
}
